import React from 'react';
import styled from 'styled-components';
import { REGULAR, COLORS } from '~/shared/utils/styled';
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-1pycplb" }) `
    margin-bottom: 60px;
`;
const Title = styled.h3.withConfig({ displayName: "Title", componentId: "sc-1hpfsa9" }) `
    color: ${COLORS.primary};
    font-size: 24px;
    font-weight: ${REGULAR};
    letter-spacing: 0;
    line-height: 150%;
    margin-bottom: 16px;
`;
const TitleWrapper = styled.div.withConfig({ displayName: "TitleWrapper", componentId: "sc-rvlrch" }) `
    display: inline-flex;
    align-items: center;
    width: 100%;
`;
const TitleText = styled.span.withConfig({ displayName: "TitleText", componentId: "sc-180h2s" }) `
    margin-right: 16px;
`;
export default function Section({ title, status, children, ...props }) {
    return (React.createElement(Root, { ...props },
        React.createElement(Title, null,
            React.createElement(TitleWrapper, null,
                React.createElement(TitleText, null, title),
                !!status && React.createElement("span", null, status))),
        children));
}
