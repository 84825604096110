import { StreamPermission } from '@streamr/sdk';
import React from 'react';
import styled from 'styled-components';
import Label from '~/shared/components/Ui/Label';
import TextInput from '~/shared/components/Ui/Text/StyledInput';
import { useCurrentStreamAbility } from '~/shared/stores/streamAbilities';
import { StreamDraft, getEmptyStreamEntity } from '~/stores/streamDraft';
import { Route as R } from '~/utils/routes';
import Section from '../Section';
import StorageNodeList from './StorageNodeList';
const Desc = styled.div.withConfig({ displayName: "Desc", componentId: "sc-1bimo02" }) `
    margin-bottom: 3.125rem;

    > p {
        margin: 0;
    }

    > p + p {
        margin-top: 0.5em;
    }
`;
export function HistorySection({ disabled: disabledProp = false }) {
    const { id: streamId, storage, metadata, } = StreamDraft.useEntity({ hot: true }) || getEmptyStreamEntity();
    const { storageDays } = metadata;
    const canEdit = useCurrentStreamAbility(streamId, StreamPermission.EDIT);
    const disabled = disabledProp || !canEdit;
    const noStorageEnabled = !Object.values(storage).some(Boolean);
    const update = StreamDraft.useUpdateEntity();
    return (React.createElement(Section, { title: "Data storage" },
        React.createElement(Desc, null,
            React.createElement("p", null, "Enable data storage on your streams to retain historical messages and access them later via a resend. Choose your storage provider and set the duration for storing data before it is marked for deletion."),
            React.createElement("p", null,
                "For more details, see the",
                ' ',
                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: R.docs('/usage/streams/store-and-retrieve') }, "docs"),
                ".")),
        React.createElement(StorageNodeList, { disabled: disabled }),
        React.createElement(Label, { htmlFor: "storageAmount" }, "Store historical data for (days)"),
        React.createElement(TextFieldWrap, null,
            React.createElement(TextInput, { disabled: disabled || noStorageEnabled, type: "number", step: 1, min: 1, value: storageDays, onChange: (e) => {
                    update((hot) => {
                        hot.metadata.storageDays = e.target.value;
                    });
                } }))));
}
const TextFieldWrap = styled.div.withConfig({ displayName: "TextFieldWrap", componentId: "sc-nn7s2f" }) `
    width: 11rem;
`;
