import styled from 'styled-components';
const Cell = styled.span.withConfig({ displayName: "Cell", componentId: "sc-kvo0f3" }) `
    display: block;
    max-width: 100%;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
export default Cell;
