import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '~/components/Button';
import { ChartPeriodTabs } from '~/components/ChartPeriodTabs';
import { SponsorshipDecimals } from '~/components/Decimals';
import { NetworkHelmet } from '~/components/Helmet';
import Layout from '~/components/Layout';
import { LoadMoreButton } from '~/components/LoadMore';
import NetworkChartDisplay from '~/components/NetworkChartDisplay';
import NetworkPageSegment, { Pad, SegmentGrid, TitleBar, } from '~/components/NetworkPageSegment';
import { QueriedSponsorshipsTable } from '~/components/QueriedSponsorshipsTable';
import { Separator } from '~/components/Separator';
import { StatCell, StatGrid } from '~/components/StatGrid';
import { OperatorIdCell } from '~/components/Table';
import WalletPass from '~/components/WalletPass';
import { GetStreamsDocument as GetIndexerStreamsDocument, StreamOrderBy as IndexerOrderBy, OrderDirection as IndexerOrderDirection, } from '~/generated/gql/indexer';
import { getNetworkStats, getOperatorDailyBuckets, getTimestampForChartPeriod, } from '~/getters';
import { getDelegationStats } from '~/getters/getDelegationStats';
import { getIndexerClient } from '~/getters/getGraphClient';
import { getSponsorshipTokenInfo } from '~/getters/getSponsorshipTokenInfo';
import { useDelegationsForWalletQuery, useDelegationsStats, useOperatorForWalletQuery, useOperatorStatsForWallet, } from '~/hooks/operators';
import { useSponsorshipTokenInfo, useSponsorshipsForCreatorQuery, } from '~/hooks/sponsorships';
import { NoData } from '~/shared/components/NoData';
import { ScrollTableCore } from '~/shared/components/ScrollTable/ScrollTable';
import Tabs, { Tab } from '~/shared/components/Tabs';
import { NetworkChart } from '~/shared/components/TimeSeriesGraph';
import { formatLongDate, formatShortDate, } from '~/shared/components/TimeSeriesGraph/chartUtils';
import { useWalletAccount } from '~/shared/stores/wallet';
import { ChartPeriod } from '~/types';
import { abbr } from '~/utils';
import { toBigInt, toFloat } from '~/utils/bn';
import { useCurrentChainId, useCurrentChainSymbolicName } from '~/utils/chains';
import { Route as R, routeOptions } from '~/utils/routes';
import { errorToast } from '~/utils/toast';
export function NetworkOverviewPage() {
    return (React.createElement(Layout, { columnize: true },
        React.createElement(NetworkHelmet, { title: "Network Overview" }),
        React.createElement(SegmentGrid, null,
            React.createElement(NetworkStats, null),
            React.createElement(MyOperatorSummary, null),
            React.createElement(MyDelegationsSummary, null),
            React.createElement(MyDelegations, null),
            React.createElement(MySponsorships, null))));
}
function NetworkStats() {
    const currentChainId = useCurrentChainId();
    const statsQuery = useQuery({
        queryKey: ['networkStats', currentChainId],
        async queryFn() {
            return getNetworkStats(currentChainId);
        },
    });
    const operatorCountQuery = useActiveOperatorCountQuery();
    return (React.createElement(NetworkPageSegment, { title: "Network stats" },
        React.createElement(Pad, null,
            React.createElement(StatGrid, null,
                React.createElement(StatCell, { label: "Total stake" }, statsQuery.isLoading ? (React.createElement(React.Fragment, null, "\u200C")) : (React.createElement(React.Fragment, null, statsQuery.data ? (React.createElement(SponsorshipDecimals, { abbr: true, amount: statsQuery.data.totalStake, tooltip: true })) : (React.createElement(React.Fragment, null, "N/A"))))),
                React.createElement(StatCell, { label: "Sponsorships" }, statsQuery.isLoading ? (React.createElement(React.Fragment, null, "\u200C")) : (statsQuery.data?.sponsorshipsCount ?? React.createElement(React.Fragment, null, "N/A"))),
                React.createElement(StatCell, { label: "Active Operators" }, operatorCountQuery.isLoading ? (React.createElement(React.Fragment, null, "\u200C")) : (operatorCountQuery.data ?? React.createElement(React.Fragment, null, "N/A")))))));
}
function useActiveOperatorCountQuery() {
    const chainId = useCurrentChainId();
    return useQuery({
        queryKey: ['useActiveOperatorCountQuery', chainId],
        queryFn: async () => {
            let cursor = '0';
            const uniquenessGate = {};
            let count = 0;
            const client = getIndexerClient(chainId);
            if (!client) {
                return 0;
            }
            for (;;) {
                const { data: { streams }, } = await client.query({
                    fetchPolicy: 'network-only',
                    query: GetIndexerStreamsDocument,
                    variables: {
                        first: 500,
                        orderBy: IndexerOrderBy.MessagesPerSecond,
                        orderDirection: IndexerOrderDirection.Desc,
                        cursor,
                    },
                });
                const breakEarly = (() => {
                    for (const item of streams.items) {
                        if (!item.messagesPerSecond) {
                            /**
                             * Make the whole stream fetching skip streams that have no
                             * data flowing through them.
                             */
                            return true;
                        }
                        if (!uniquenessGate[item.id]) {
                            if (/^[^/]+\/operator\/coordination$/.test(item.id)) {
                                count += 1;
                            }
                        }
                        uniquenessGate[item.id] = true;
                    }
                    return false;
                })();
                if (!streams.cursor || breakEarly) {
                    break;
                }
                cursor = streams.cursor;
            }
            return count;
        },
    });
}
function MyOperatorSummary() {
    const wallet = useWalletAccount();
    const { symbol: tokenSymbol = 'DATA' } = useSponsorshipTokenInfo() || {};
    const stats = useOperatorStatsForWallet(wallet);
    const { value = 0n, numOfDelegators = 0, numOfSponsorships = 0 } = stats || {};
    const [chartPeriod, setChartPeriod] = useState(ChartPeriod.ThreeMonths);
    const [chartId, setChartId] = useState('earnings');
    const { data: operator = null } = useOperatorForWalletQuery(wallet);
    const currentChainId = useCurrentChainId();
    const { data: chartData = [] } = useQuery({
        queryKey: [
            'operatorSummaryChartQuery',
            currentChainId,
            chartId,
            chartPeriod,
            operator?.id,
        ],
        async queryFn() {
            if (!operator) {
                return [];
            }
            try {
                const end = moment().utc().subtract(1, 'day').endOf('day');
                const buckets = await getOperatorDailyBuckets(currentChainId, operator.id, {
                    dateGreaterEqualThan: getTimestampForChartPeriod(chartPeriod, end).unix(),
                    dateLowerThan: end.unix(),
                    force: true,
                });
                const { decimals } = await getSponsorshipTokenInfo(currentChainId);
                const toValue = chartId === 'stake'
                    ? ({ valueWithoutEarnings }) => toBigInt(valueWithoutEarnings)
                    : ({ cumulativeEarningsWei }) => toBigInt(cumulativeEarningsWei);
                return buckets.map((bucket) => ({
                    x: Number(bucket.date) * 1000,
                    y: toFloat(toValue(bucket), decimals).toNumber(),
                }));
            }
            catch (e) {
                errorToast({ title: 'Could not load operator chart data' });
                console.warn('Could not load operator chart data', e);
            }
            return [];
        },
    });
    const chartLabel = chartId === 'stake' ? 'Total stake' : 'Cumulative earnings';
    const chainName = useCurrentChainSymbolicName();
    return (React.createElement(NetworkPageSegment, { title: React.createElement(TitleBar, { aux: operator && (React.createElement(Button, { kind: "secondary", as: Link, to: R.operator(operator.id, routeOptions(chainName)) }, "View Operator")) }, "My operator summary") },
        React.createElement(WalletPass, { resourceName: "operator summary", roundBorders: true }, !wallet || stats !== null ? (React.createElement(React.Fragment, null,
            React.createElement(Pad, null,
                React.createElement(StatGrid, null,
                    React.createElement(StatCell, { label: "Total stake" },
                        React.createElement(SponsorshipDecimals, { abbr: true, amount: value })),
                    React.createElement(StatCell, { label: "Delegators" }, numOfDelegators),
                    React.createElement(StatCell, { label: "Sponsorships" }, numOfSponsorships))),
            React.createElement(Separator, null),
            React.createElement(Pad, null,
                React.createElement(NetworkChartDisplay, { periodTabs: React.createElement(ChartPeriodTabs, { value: chartPeriod, onChange: setChartPeriod }), sourceTabs: React.createElement(Tabs, { selection: chartId, onSelectionChange: (newChartId) => {
                            if (newChartId !== 'stake' &&
                                newChartId !== 'earnings') {
                                return;
                            }
                            setChartId(newChartId);
                        } },
                        React.createElement(Tab, { id: "earnings" }, "Cumulative earnings"),
                        React.createElement(Tab, { id: "stake" }, "Total stake")) },
                    React.createElement(NetworkChart, { tooltipValuePrefix: chartLabel, graphData: chartData, xAxisDisplayFormatter: formatShortDate, yAxisAxisDisplayFormatter: (value) => abbr(value), tooltipLabelFormatter: formatLongDate, tooltipValueFormatter: (value) => `${abbr(value)} ${tokenSymbol}` }))))) : (React.createElement(React.Fragment, null,
            React.createElement(Pad, null,
                React.createElement(NoData, { firstLine: "You haven't initialized your operator.", secondLine: React.createElement(React.Fragment, null,
                        "You can become an operator on the",
                        ' ',
                        React.createElement(Link, { to: R.operators(routeOptions(chainName)) }, "Operators"),
                        ' ',
                        "page."), compact: true })))))));
}
function MyDelegationsSummary() {
    const wallet = useWalletAccount();
    const currentChainId = useCurrentChainId();
    const stats = useDelegationsStats(wallet);
    const { symbol: tokenSymbol = 'DATA' } = useSponsorshipTokenInfo() || {};
    const { value = 0n, numOfOperators = 0, minApy = 0, maxApy = 0 } = stats || {};
    const apy = minApy === maxApy ? [minApy] : [minApy, maxApy];
    const [chartPeriod, setChartPeriod] = useState(ChartPeriod.ThreeMonths);
    const [chartDataSource, setChartDataSource] = useState('cumulativeEarnings');
    const dailyDelegationChartQuery = useQuery({
        queryKey: [
            'dailyDelegationChartQuery',
            currentChainId,
            wallet,
            chartPeriod,
            chartDataSource,
        ],
        queryFn: async () => {
            try {
                if (!wallet) {
                    return [];
                }
                return await getDelegationStats(currentChainId, wallet, chartPeriod, chartDataSource, {
                    force: true,
                    ignoreToday: false,
                });
            }
            catch (_) {
                errorToast({ title: 'Could not load my delegations chart data' });
                return [];
            }
        },
    });
    const chartLabel = chartDataSource === 'currentValue' ? 'Current value' : 'Cumulative earnings';
    return (React.createElement(NetworkPageSegment, { title: "My delegations summary" },
        React.createElement(WalletPass, { resourceName: "delegations summary", roundBorders: true },
            React.createElement(Pad, null,
                React.createElement(StatGrid, null,
                    React.createElement(StatCell, { label: "Current value" },
                        React.createElement(SponsorshipDecimals, { abbr: true, amount: value })),
                    React.createElement(StatCell, { label: "Operators" }, numOfOperators),
                    React.createElement(StatCell, { label: "APY" },
                        apy.map((v) => (v * 100).toFixed(0)).join('-'),
                        "%"))),
            React.createElement(Separator, null),
            React.createElement(Pad, null,
                React.createElement(NetworkChartDisplay, { periodTabs: React.createElement(ChartPeriodTabs, { value: chartPeriod, onChange: setChartPeriod }), sourceTabs: React.createElement(Tabs, { selection: chartDataSource, onSelectionChange: (newChartId) => {
                            if (newChartId !== 'currentValue' &&
                                newChartId !== 'cumulativeEarnings') {
                                return;
                            }
                            setChartDataSource(newChartId);
                        } },
                        React.createElement(Tab, { id: "cumulativeEarnings" }, "Cumulative earnings"),
                        React.createElement(Tab, { id: "currentValue" }, "Current value")) },
                    React.createElement(NetworkChart, { tooltipValuePrefix: chartLabel, graphData: dailyDelegationChartQuery.data || [], xAxisDisplayFormatter: formatShortDate, yAxisAxisDisplayFormatter: (value) => abbr(value), isLoading: dailyDelegationChartQuery.isLoading, tooltipLabelFormatter: formatLongDate, tooltipValueFormatter: (value) => `${abbr(value)} ${tokenSymbol}` }))))));
}
function MyDelegations() {
    const wallet = useWalletAccount();
    const query = useDelegationsForWalletQuery({ address: wallet });
    const isLoading = query.isLoading || query.isFetching;
    // We want to hide delegations to broken operator contract version 1
    // as we cannot get rid of them otherwise
    const delegations = query.data?.pages
        .flatMap((page) => page.elements)
        .filter((d) => d.contractVersion !== 1) || [];
    const chainName = useCurrentChainSymbolicName();
    return (React.createElement(NetworkPageSegment, { title: "My delegations", foot: true },
        React.createElement(WalletPass, { resourceName: "delegations" }, !wallet || delegations.length || isLoading ? (React.createElement(React.Fragment, null,
            React.createElement(ScrollTableCore, { isLoading: isLoading, elements: delegations, columns: [
                    {
                        displayName: 'Operator',
                        valueMapper: ({ id, metadata: { imageUrl, name }, }) => (React.createElement(OperatorIdCell, { operatorId: id, imageUrl: imageUrl, operatorName: name })),
                        align: 'start',
                        isSticky: true,
                        key: 'operatorId',
                    },
                    {
                        displayName: 'My delegation',
                        valueMapper: (sponsorship) => (React.createElement(SponsorshipDecimals, { abbr: true, amount: wallet ? sponsorship.share(wallet) : 0n })),
                        align: 'start',
                        isSticky: false,
                        key: 'myShare',
                    },
                    {
                        displayName: 'Total stake',
                        valueMapper: ({ valueWithoutEarnings }) => (React.createElement(SponsorshipDecimals, { abbr: true, amount: valueWithoutEarnings })),
                        align: 'end',
                        isSticky: false,
                        key: 'totalStake',
                    },
                    {
                        displayName: "Owner's cut",
                        valueMapper: ({ operatorsCut }) => `${operatorsCut}%`,
                        align: 'end',
                        isSticky: false,
                        key: 'operatorsCut',
                    },
                    {
                        displayName: 'APY',
                        valueMapper: ({ apy }) => `${(apy * 100).toFixed(0)}%`,
                        align: 'end',
                        isSticky: false,
                        key: 'apy',
                    },
                    {
                        displayName: 'Sponsorships',
                        valueMapper: (element) => element.stakes.length,
                        align: 'end',
                        isSticky: false,
                        key: 'sponsorships',
                    },
                ], linkMapper: ({ id }) => R.operator(id, routeOptions(chainName)) }),
            query.hasNextPage ? (React.createElement(LoadMoreButton, { disabled: isLoading, onClick: () => void query.fetchNextPage(), kind: "primary2" }, "Load more")) : (React.createElement(React.Fragment, null)))) : (React.createElement(NoData, { firstLine: "You haven't delegated to anyone yet.", secondLine: React.createElement(React.Fragment, null,
                "You can browse",
                ' ',
                React.createElement(Link, { to: R.operators(routeOptions(chainName)) }, "operators"),
                ' ',
                "to start delegating."), compact: true })))));
}
function MySponsorships() {
    const query = useSponsorshipsForCreatorQuery(useWalletAccount());
    const chainName = useCurrentChainSymbolicName();
    return (React.createElement(NetworkPageSegment, { title: "My sponsorships", foot: true },
        React.createElement(WalletPass, { resourceName: "sponsorships" },
            React.createElement(QueriedSponsorshipsTable, { query: query, noDataFirstLine: "You don't have any sponsorships yet.", noDataSecondLine: React.createElement(React.Fragment, null,
                    "You can",
                    ' ',
                    React.createElement(Link, { to: R.sponsorships(routeOptions(chainName)) }, "start a sponsorship"),
                    ' ',
                    "here") }))));
}
