import React from 'react';
import styled from 'styled-components';
import { COLORS } from '~/shared/utils/styled';
import SvgIcon from '~/shared/components/SvgIcon';
import { Button } from '~/components/Button';
import { Route as R } from '~/utils/routes';
import ProjectModal, { Actions } from './ProjectModal';
const Box = styled.div.withConfig({ displayName: "Box", componentId: "sc-gqztf5" }) `
    background: #ffffff;
    border-radius: 8px;
    padding: 32px 24px 24px;
    font-size: 16px;
    line-height: 24px;

    p {
        margin: 0;
    }
`;
const Explainer = styled.div.withConfig({ displayName: "Explainer", componentId: "sc-1vuvt8n" }) `
    display: grid;
    grid-template-columns: 18px 1fr;
    background: ${COLORS.secondary};
    border-radius: 8px;
    padding: 20px 24px;
    line-height: 24px;
    gap: 12px;
    margin-top: 32px;
`;
const IconWrap = styled.div.withConfig({ displayName: "IconWrap", componentId: "sc-i2auw8" }) `
    align-items: center;
    display: flex;
    height: 24px;

    svg {
        display: block;
    }
`;
export default function AllowanceModal({ tokenSymbol = 'DATA', onReject }) {
    return (React.createElement(ProjectModal, { onReject: onReject, title: "Set Streamr Hub Allowance" },
        React.createElement(Box, null,
            React.createElement("p", null,
                "This step allows the Hub to transfer the required amount of",
                ' ',
                tokenSymbol,
                "."),
            React.createElement(Explainer, null,
                React.createElement(IconWrap, null,
                    React.createElement(SvgIcon, { name: "outlineQuestionMark2" })),
                React.createElement("p", null,
                    "Allowance is a requirement of ERC-20 token transfers, designed to increase security and efficiency. For more about allowances, see this",
                    ' ',
                    React.createElement("a", { href: R.allowanceInfo(), target: "_blank", rel: "noopener noreferrer" }, "page"),
                    "."))),
        React.createElement(Actions, null,
            React.createElement(Button, { waiting: true, type: "button" }, "Waiting"))));
}
