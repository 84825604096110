import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS, MEDIUM } from '~/shared/utils/styled';
import SvgIcon from '~/shared/components/SvgIcon';
import Spinner from '~/components/Spinner';
import AbstractToast from '~/shared/toasts/AbstractToast';
export default function TransactionListToast({ operations = [] }) {
    return (React.createElement(AbstractToast, null,
        React.createElement(Container, null,
            React.createElement(Title, null, "These transactions are needed to save your changes:"),
            React.createElement("ul", null, operations.map(({ id, state, label, action: Action }, index) => (React.createElement(Item, { key: id, "$complete": state === 'complete' || state === 'error', "$ongoing": state === 'ongoing' },
                React.createElement(Number, null, index + 1),
                React.createElement(Name, null, label),
                Action && (React.createElement(ActionWrap, null,
                    React.createElement(Action, { state: state }))),
                React.createElement(IconContainer, null,
                    state === 'ongoing' && React.createElement(Spinner, { color: "blue" }),
                    state === 'complete' && React.createElement(Icon, { name: "checkmark" }),
                    state === 'error' && React.createElement(Icon, { name: "errorBadge" })))))))));
}
export function notify(toast, operations) {
    setTimeout(async () => {
        try {
            if (!toast) {
                return;
            }
            await toast.pop({ operations: [...operations] });
        }
        catch (_) {
            // Do nothing
        }
    });
}
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-qltuev" }) `
    width: 464px;
    padding: 32px 24px;

    ul {
        list-style: none;
        margin: 24px 0 0;
        padding: 0;
    }

    li + li {
        margin-top: 8px;
    }
`;
const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-wh87ze" }) `
    font-weight: ${MEDIUM};
    font-size: 16px;
    line-height: 20px;
    width: 240px;
`;
const Item = styled.li.withConfig({ displayName: "Item", componentId: "sc-110z2sc" }) `
    padding: 20px 16px;
    border: 1px solid ${COLORS.inputBackground};
    border-radius: 4px;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    display: flex;

    > * + * {
        margin-left: 16px;
    }

    ${({ $complete = false }) => $complete &&
    css `
            background-color: ${COLORS.secondary};
        `}

    ${({ $ongoing = false }) => $ongoing &&
    css `
            border-color: #b3d4ff;
        `}
`;
const Number = styled.div.withConfig({ displayName: "Number", componentId: "sc-3lajjx" }) `
    font-size: 12px;
    line-height: normal;
    border-radius: 50%;
    background-color: #000000;
    color: #ffffff;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;
const Name = styled.div.withConfig({ displayName: "Name", componentId: "sc-1mk5loi" }) `
    font-size: 16px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 1;
`;
const IconContainer = styled.div.withConfig({ displayName: "IconContainer", componentId: "sc-dkxk9p" }) `
    align-items: center;
    display: flex;
    justify-content: center;
    height: 18px;
    width: 18px;
    flex-shrink: 0;
`;
const Icon = styled(SvgIcon).withConfig({ displayName: "Icon", componentId: "sc-1luxglz" }) `
    display: flex;
    width: 100%;
    height: 100%;
`;
const ActionWrap = styled.div.withConfig({ displayName: "ActionWrap", componentId: "sc-89zhjj" }) ``;
