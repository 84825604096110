import React from 'react';
import styled from 'styled-components';
import { connectModal } from '~/modals/ConnectModal';
import { Button } from '~/components/Button';
import { COLORS } from '~/shared/utils/styled';
export default function NoWalletOverlay({ resourceName }) {
    return (React.createElement(Root, null,
        React.createElement(Content, null,
            React.createElement("p", null,
                "Please connect wallet to view your ",
                resourceName),
            React.createElement(Button, { kind: "primary", size: "mini", outline: true, type: "button", onClick: async () => {
                    try {
                        await connectModal.pop();
                    }
                    catch (e) {
                        console.warn('Wallet connecting failed', e);
                    }
                } }, "Connect"))));
}
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-1pkuxei" }) `
    align-items: center;
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    p {
        font-size: 16px;
        color: ${COLORS.primaryLight};
        margin: 0 0 24px;
    }
`;
const Content = styled.div.withConfig({ displayName: "Content", componentId: "sc-qln0c7" }) `
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    text-align: center;
`;
