import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip, ReferenceLine, } from 'recharts';
import Spinner from '~/components/Spinner';
import { COLORS, MEDIUM, REGULAR, TABLET } from '~/shared/utils/styled';
import { NoData } from '~/shared/components/NoData';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1ugjy2q" }) `
    position: relative;
`;
const NoDataContainer = styled.div.withConfig({ displayName: "NoDataContainer", componentId: "sc-gj2gxu" }) `
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const SpinnerContainer = styled.div.withConfig({ displayName: "SpinnerContainer", componentId: "sc-um9vla" }) `
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const TimeSeriesGraph = ({ graphData, isLoading, xAxisDisplayFormatter, yAxisAxisDisplayFormatter, tooltipLabelFormatter, tooltipValueFormatter, tooltipValuePrefix, noDataText, ...props }) => {
    const chartData = useMemo(() => graphData.sort((a, b) => a.x - b.x), [graphData]);
    const [crossHairY, setCrossHairY] = useState(null);
    const [crossHairX, setCrossHairX] = useState(null);
    const updateCrossHairCoordinates = useCallback((xValue, yValue) => {
        setTimeout(() => {
            setCrossHairX(xValue);
            setCrossHairY(yValue);
        });
        // do not put anything in the dependencies array or it will cause endless rerendering cycle
    }, []);
    return (React.createElement(Container, { ...props },
        !!isLoading && (React.createElement(SpinnerContainer, null,
            React.createElement(Spinner, { size: "large", color: "blue" }))),
        !isLoading && (!chartData || !chartData.length) && (React.createElement(NoDataContainer, null,
            React.createElement(NoData, { width: 250, firstLine: noDataText || 'Not enough data' }))),
        !isLoading && chartData && chartData.length > 0 && (React.createElement(ResponsiveContainer, { width: "100%", height: 400 },
            React.createElement(LineChart, { data: chartData },
                React.createElement(Line, { type: "linear", dataKey: "y", stroke: COLORS.link, dot: { r: 4, stroke: COLORS.link }, activeDot: { stroke: 'white', fill: COLORS.link, r: 5 }, isAnimationActive: false }),
                React.createElement(CartesianGrid, { stroke: COLORS.dialogBorder, vertical: false }),
                React.createElement(XAxis
                // domain={xAxisDataDomain}
                , { 
                    // domain={xAxisDataDomain}
                    dataKey: "x", scale: "point", tickFormatter: xAxisDisplayFormatter || undefined, tickMargin: 10, tickLine: false, stroke: COLORS.dialogBorder, minTickGap: 10, tick: { stroke: COLORS.primaryLight, fontWeight: 200 }, interval: "preserveStartEnd" }),
                React.createElement(YAxis, { domain: ['dataMin', 'dataMax'], dataKey: "y", orientation: "right", tickMargin: 10, tickFormatter: yAxisAxisDisplayFormatter || undefined, stroke: COLORS.dialogBorder, tickLine: false, tick: { stroke: COLORS.primaryLight, fontWeight: 200 }, interval: "preserveStartEnd" }),
                React.createElement(Tooltip, { cursor: React.createElement(CustomCursor, { setSelectedPointValues: updateCrossHairCoordinates }), content: React.createElement(CustomTooltip, { valuePrefix: tooltipValuePrefix, labelFormatter: tooltipLabelFormatter, valueFormatter: tooltipValueFormatter }) }),
                crossHairY !== null && crossHairX !== null && (React.createElement(React.Fragment, null,
                    React.createElement(ReferenceLine, { y: crossHairY, stroke: COLORS.primaryLight, strokeDasharray: "4 1" }),
                    React.createElement(ReferenceLine, { x: crossHairX, stroke: COLORS.primaryLight, strokeDasharray: "4 1" }))))))));
};
const CustomCursor = (props) => {
    const { setSelectedPointValues } = props;
    if (props.payload) {
        setSelectedPointValues(props.payload[0].payload.x, props.payload[0].payload.y);
    }
    const setSelectedPointValuesRef = useRef(setSelectedPointValues);
    if (setSelectedPointValuesRef.current !== setSelectedPointValues) {
        setSelectedPointValuesRef.current = setSelectedPointValues;
    }
    useEffect(() => {
        const { current } = setSelectedPointValuesRef;
        return () => {
            current?.(null, null);
        };
    }, []);
    return React.createElement(React.Fragment, null);
};
const CustomTooltipContainer = styled.div.withConfig({ displayName: "CustomTooltipContainer", componentId: "sc-1lcij3p" }) `
    border-radius: 8px;
    padding: 12px;
    background-color: white;
    box-shadow: 0 8px 12px 0 #52525226;
    font-size: 14px;

    .label {
        color: ${COLORS.primary};
        margin-bottom: 5px;
        font-weight: ${REGULAR};
    }

    .value {
        font-weight: ${MEDIUM};
        color: ${COLORS.primary};
        margin-bottom: 0;
    }

    .value-prefix {
        color: ${COLORS.primaryDisabled};
        font-weight: ${REGULAR};
    }
`;
const CustomTooltip = (props) => {
    const { active, payload, label, valuePrefix, labelFormatter, valueFormatter } = props;
    return (active &&
        payload &&
        payload[0] && (React.createElement(CustomTooltipContainer, null,
        React.createElement("p", { className: "label" }, labelFormatter ? labelFormatter(label) : label),
        React.createElement("p", { className: "value" },
            React.createElement("span", { className: "value-prefix" },
                valuePrefix,
                ":"),
            ' ',
            valueFormatter ? valueFormatter(payload[0].value) : payload[0].value))));
};
export const NetworkChart = styled(TimeSeriesGraph).withConfig({ displayName: "NetworkChart", componentId: "sc-l9v4yv" }) `
    margin: 40px 0;

    @media ${TABLET} {
        margin: 64px 0 0;
    }
`;
