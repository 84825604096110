import React, { useRef, useSyncExternalStore } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
/**
 * Mounts given function component inside `#hub-anchors` (see `index.html`) and passes
 * wrapped element's coordinates to it.
 */
export function Anchor({ indicateOrigin = false, translate, component: Component, componentProps, children, display, ...props }) {
    const ref = useRef(null);
    const [x, y] = useBoundingClientRect(ref, (rect) => translate?.(rect) || [0, 0]);
    return (React.createElement(Root, { ...props, ref: ref, "$display": display },
        children,
        createPortal(React.createElement(Component, { x: x, y: y, ...componentProps }), document.getElementById('hub-anchors')),
        indicateOrigin &&
            createPortal(React.createElement(OriginIndicator, { x: x, y: y }), document.getElementById('hub-anchors'))));
}
function resizeSubscribe(cb) {
    window.addEventListener('resize', cb);
    window.addEventListener('scroll', cb);
    return () => {
        window.removeEventListener('resize', cb);
        window.removeEventListener('scroll', cb);
    };
}
function OriginIndicator({ x, y }) {
    return (React.createElement(Point, { style: {
            transform: `translate(-50%, -50%) translate(${x}px, ${y}px)`,
        } }));
}
const Point = styled.div.withConfig({ displayName: "Point", componentId: "sc-192pfex" }) `
    background: red;
    border-radius: 50%;
    height: 10px;
    left: 0;
    position: absolute;
    top: 0;
    width: 10px;
    z-index: 19999;
`;
export function useBoundingClientRect(ref, translate) {
    return JSON.parse(useSyncExternalStore(resizeSubscribe, () => JSON.stringify(translate(ref.current?.getBoundingClientRect()))));
}
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-1y9ehtf" }) `
    display: ${({ $display }) => $display};
`;
