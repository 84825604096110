import { useQuery } from '@tanstack/react-query';
import React from 'react';
import styled from 'styled-components';
import { getIndexerClient } from '~/getters/getGraphClient';
import { GetStreamsDocument, } from '../generated/gql/indexer';
function Stat({ label, value, unit, ...props }) {
    return (React.createElement(StatRoot, { type: "button", ...props },
        React.createElement(StatName, null, label),
        React.createElement(StatValue, null,
            value !== undefined && value,
            value === undefined && React.createElement(Dimm, null, "\u221E"),
            value !== undefined && unit)));
}
const StatName = styled.div.withConfig({ displayName: "StatName", componentId: "sc-1r7jdwi" }) `
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.05em;
    color: #adadad;
    text-transform: uppercase;
`;
const StatValue = styled.div.withConfig({ displayName: "StatValue", componentId: "sc-bwcph1" }) `
    font-size: 16px;
    line-height: normal;
    margin-top: 0.25em;
    color: #323232;

    svg {
        width: 13px;
        height: 13px;
        color: #adadad;
    }
`;
const Dimm = styled.span.withConfig({ displayName: "Dimm", componentId: "sc-1vylgji" }) `
    color: #adadad;
`;
export const StatRoot = styled.button.withConfig({ displayName: "StatRoot", componentId: "sc-1lapg84" }) `
    background: transparent;
    border: 0;
    appearance: none;
    text-align: center;
    user-select: none;
    position: relative;
    font-family: inherit;
    outline: none;

    &:focus {
        outline: none;
    }

    ${StatName} {
        transition: color 300ms ease-in-out;
    }
`;
const ButtonGrid = styled.div.withConfig({ displayName: "ButtonGrid", componentId: "sc-1a3ch8s" }) `
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 0.06);
    display: flex;
    flex-direction: row;
    padding: 16px 0;
    pointer-events: auto;

    > ${StatRoot} {
        flex-grow: 1;
        flex-basis: 0;
    }
`;
function useStreamStatsQuery(streamId) {
    return useQuery({
        queryKey: ['useStreamStatsQuery', streamId],
        queryFn: async () => {
            const client = getIndexerClient(137);
            if (!client) {
                return defaultStreamStats;
            }
            const { data: { streams }, } = await client.query({
                query: GetStreamsDocument,
                variables: {
                    streamIds: [streamId],
                    first: 1,
                },
            });
            const [stream = undefined] = streams.items;
            if (!stream) {
                return null;
            }
            const { messagesPerSecond, peerCount } = stream;
            return {
                latency: undefined,
                messagesPerSecond,
                peerCount,
            };
        },
    });
}
const defaultStreamStats = {
    latency: undefined,
    messagesPerSecond: undefined,
    peerCount: undefined,
};
export function StreamStats({ streamId }) {
    const { data: stats } = useStreamStatsQuery(streamId);
    const { messagesPerSecond, peerCount, latency } = stats || defaultStreamStats;
    return (React.createElement(StreamStatsRoot, null,
        React.createElement(ButtonGrid, null,
            React.createElement(Stat, { id: "streamMessagesPerSecond", label: "Msgs / sec", value: messagesPerSecond }),
            React.createElement(Stat, { id: "peerCount", label: "Peers", value: peerCount }),
            React.createElement(Stat, { id: "latency", label: "Latency ms", value: latency == null ? undefined : latency.toFixed(2) }))));
}
const StreamStatsRoot = styled.div.withConfig({ displayName: "StreamStatsRoot", componentId: "sc-1wl2di2" }) `
    max-width: 480px;
    padding: 24px;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
`;
