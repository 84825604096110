import React from 'react';
import styled, { css } from 'styled-components';
import { MarketplaceProductTile } from '~/shared/components/Tile';
import { DESKTOP, COLORS, TABLET, MAX_BODY_WIDTH, LAPTOP } from '~/shared/utils/styled';
import { LoadMoreButton } from '~/components/LoadMore';
import { DottedLoadingIndicator, DottedLoadingIndicatorRoot, } from '~/components/DottedLoadingIndicator';
import NoProductsView from './NoProductsView';
const MarketplaceProjectRow = styled.div.withConfig({ displayName: "MarketplaceProjectRow", componentId: "sc-264ptu" }) `
    display: grid;
    grid-gap: 36px;
    grid-template-columns: 1fr;
    margin: 0;
    min-height: 100px;

    @media ${TABLET} {
        grid-template-columns: 1fr 1fr;
    }

    @media ${LAPTOP} {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    ${({ $fetching = false }) => $fetching &&
    css `
            opacity: 0.5;
        `}
`;
const listProjects = (projects, currentUserAddress, isFetching) => {
    const isEditable = (projectId) => {
        if (!currentUserAddress) {
            return false;
        }
        const project = projects.find((p) => p.id === projectId);
        if (project != null) {
            const perm = project.permissions.find((permission) => permission.userAddress.toLowerCase() ===
                currentUserAddress.toLowerCase());
            if (perm != null) {
                return perm.canEdit;
            }
            return false;
        }
        return false;
    };
    return (React.createElement(MarketplaceProjectRow, { "$fetching": isFetching }, projects.map((project) => (React.createElement(MarketplaceProductTile, { key: project.id, product: project, showDataUnionBadge: !!project.isDataUnion, showEditButton: isEditable(project.id) })))));
};
export const ProjectsContainer = styled.div.withConfig({ displayName: "ProjectsContainer", componentId: "sc-i8qn2b" }) `
    padding: 40px 30px 3.5em 30px;
    background-color: ${COLORS.secondary};
    max-width: ${MAX_BODY_WIDTH}px;
    margin: 0 auto;

    @media ${DESKTOP} {
        padding: 72px 0 7em 0;
    }
`;
export function Projects({ projects, error, isFetching, loadProducts, hasMoreSearchResults, currentUserAddress, noOwnProjects = false, ...props }) {
    return (React.createElement(Root, { ...props },
        error ? React.createElement(Error, null, error.message) : null,
        isFetching || projects.length > 0 ? (listProjects(projects, currentUserAddress, isFetching)) : (React.createElement(NoProductsView, { noOwnProjects: noOwnProjects })),
        loadProducts && !isFetching && hasMoreSearchResults && (React.createElement(LoadMoreButton, { onClick: loadProducts, kind: "primary2" }, "Load more")),
        isFetching && React.createElement(DottedLoadingIndicator, null)));
}
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-1qmlimh" }) `
    ${DottedLoadingIndicatorRoot} {
        margin-top: 1.5rem;
    }
`;
const Error = styled.div.withConfig({ displayName: "Error", componentId: "sc-rjgtxr" }) `
    background: rgba(255, 25, 0, 0.4);
    border-radius: 2px;
    font-size: 0.9em;
    padding: 1em 2em;
`;
