import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { SponsorshipDecimals } from '~/components/Decimals';
import Spinner from '~/components/Spinner';
import { FundedUntilCell, StreamIdCell } from '~/components/Table';
import { Tooltip, TooltipIconWrap } from '~/components/Tooltip';
import { ScrollTable } from '~/shared/components/ScrollTable/ScrollTable';
import SvgIcon from '~/shared/components/SvgIcon';
import { Route as R, routeOptions } from '~/utils/routes';
import { useCanCollectEarningsCallback, useUncollectedEarnings, } from '~/shared/stores/uncollectedEarnings';
import { useCollectEarnings } from '~/hooks/operators';
import { useEditSponsorshipFunding } from '~/hooks/sponsorships';
import { Button } from '~/components/Button';
import { useCurrentChainId, useCurrentChainSymbolicName } from '~/utils/chains';
export function SponsorshipTable({ operator, isController, }) {
    const canCollect = useCanCollectEarningsCallback();
    const editSponsorshipFunding = useEditSponsorshipFunding();
    const collectEarnings = useCollectEarnings();
    const currentChainId = useCurrentChainId();
    const chainName = useCurrentChainSymbolicName();
    const allSponsorshipIds = operator.stakes.map(({ sponsorshipId }) => sponsorshipId);
    const canCollectAllEarnings = allSponsorshipIds.every((sponsorshipId) => canCollect(operator.id, sponsorshipId));
    return (React.createElement(ScrollTable, { elements: operator.stakes, columns: [
            {
                displayName: 'Stream ID',
                valueMapper: ({ streamId }) => React.createElement(StreamIdCell, { streamId: streamId }),
                align: 'start',
                isSticky: true,
                key: 'streamId',
            },
            {
                displayName: 'Staked',
                valueMapper: (element) => {
                    const minimumStakeReachTime = moment(element.joinedAt.getTime() +
                        element.minimumStakingPeriodSeconds * 1000);
                    return (React.createElement(React.Fragment, null,
                        React.createElement(SponsorshipDecimals, { abbr: true, amount: element.amountWei }),
                        minimumStakeReachTime.isAfter(Date.now()) && (React.createElement(Tooltip, { content: React.createElement(React.Fragment, null,
                                "Minimum stake period:",
                                ' ',
                                minimumStakeReachTime.fromNow(true),
                                " left") },
                            React.createElement(TooltipIconWrap, { className: "ml-1", "$color": "#ADADAD", "$svgSize": {
                                    width: '18px',
                                    height: '18px',
                                } },
                                React.createElement(SvgIcon, { name: "lockClosed" }))))));
                },
                align: 'start',
                isSticky: false,
                key: 'staked',
            },
            {
                displayName: 'APY',
                valueMapper: (element) => `${element.spotAPY.multipliedBy(100).toFixed(0)}%`,
                align: 'start',
                isSticky: false,
                key: 'apy',
            },
            {
                displayName: 'Funded until',
                valueMapper: (element) => (React.createElement(FundedUntilCell, { projectedInsolvencyAt: element.projectedInsolvencyAt, remainingBalance: element.remainingWei })),
                align: 'start',
                isSticky: false,
                key: 'fundedUntil',
            },
            {
                displayName: 'Uncollected earnings',
                valueMapper: (element) => (React.createElement(UncollectedEarnings, { operatorId: operator.id, sponsorshipId: element.sponsorshipId })),
                align: 'end',
                isSticky: false,
                key: 'earnings',
            },
        ], linkMapper: ({ sponsorshipId: id }) => R.sponsorship(id, routeOptions(chainName)), actions: [
            (element) => ({
                displayName: 'Edit',
                disabled: !isController,
                async callback() {
                    if (!operator) {
                        return;
                    }
                    editSponsorshipFunding({
                        chainId: currentChainId,
                        sponsorshipOrSponsorshipId: element.sponsorshipId,
                        operator,
                    });
                },
            }),
            (element) => ({
                displayName: 'Collect earnings',
                callback() {
                    if (!operator.id) {
                        return;
                    }
                    collectEarnings({
                        chainId: currentChainId,
                        operatorId: operator.id,
                        sponsorshipIds: [element.sponsorshipId],
                    });
                },
                disabled: !canCollect(operator.id, element.sponsorshipId),
            }),
        ], footerComponent: allSponsorshipIds.length > 0 &&
            canCollectAllEarnings && (React.createElement(Footer, null,
            React.createElement(Button, { kind: "secondary", onClick: async () => {
                    collectEarnings({
                        chainId: currentChainId,
                        operatorId: operator.id,
                        sponsorshipIds: allSponsorshipIds,
                    });
                } }, "Collect all"))) }));
}
const Footer = styled.div.withConfig({ displayName: "Footer", componentId: "sc-nke2vt" }) `
    display: flex;
    justify-content: right;
    padding: 32px;
    gap: 10px;
`;
function UncollectedEarnings({ operatorId, sponsorshipId, }) {
    const value = useUncollectedEarnings(operatorId, sponsorshipId);
    return typeof value !== 'undefined' ? (React.createElement(SponsorshipDecimals, { abbr: true, amount: value?.uncollectedEarnings || 0n })) : (React.createElement(Spinner, { color: "blue" }));
}
