import JiraFailedBuildStatusIcon from '@atlaskit/icon/glyph/jira/failed-build-status';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { toaster } from 'toasterhea';
import { Button } from '~/components/Button';
import { Separator } from '~/components/Separator';
import Spinner from '~/components/Spinner';
import { Tooltip, TooltipIconWrap } from '~/components/Tooltip';
import AddAddressModal from '~/modals/AddAddressModal';
import { addOperatorControllerAddress, removeOperatorControllerAddress, setOperatorNodeAddresses, } from '~/services/operators';
import { ScrollTable } from '~/shared/components/ScrollTable/ScrollTable';
import { COLORS } from '~/shared/utils/styled';
import { Layer } from '~/utils/Layer';
import { getBalance } from '~/utils/balance';
import { toBigInt, toFloat } from '~/utils/bn';
import { useCurrentChainId } from '~/utils/chains';
import { isRejectionReason, isTransactionRejection } from '~/utils/exceptions';
import { errorToast } from '~/utils/toast';
export var AddressType;
(function (AddressType) {
    AddressType[AddressType["Node"] = 0] = "Node";
    AddressType[AddressType["Automation"] = 1] = "Automation";
})(AddressType || (AddressType = {}));
const dialogTitleMap = {
    [AddressType.Node]: 'Add node address',
    [AddressType.Automation]: 'Authorise staking agent',
};
const dialogSubmitLabelMap = {
    [AddressType.Node]: 'Add node address',
    [AddressType.Automation]: 'Authorise staking agent',
};
export function AddressTable({ type, busy = false, disableEditing = false, onChange, onAddAddress, onRemoveAddress, value = [], }) {
    function toggle(element) {
        if (element.persisted) {
            return void onChange?.(value.map((node) => node !== element ? node : { ...node, enabled: !node.enabled }));
        }
        onChange?.(value.filter((node) => node !== element));
    }
    return (React.createElement(ScrollTable, { elements: value, columns: [
            {
                displayName: 'Address',
                valueMapper: (element) => (React.createElement(Address, { "$new": element.enabled && !element.persisted }, element.address)),
                align: 'start',
                isSticky: true,
                key: 'id',
            },
            {
                displayName: 'POL balance',
                valueMapper: (element) => React.createElement(PolBalance, { address: element.address }),
                align: 'start',
                isSticky: false,
                key: 'balance',
            },
            {
                displayName: '',
                valueMapper: (element) => (React.createElement(React.Fragment, null, element.persisted !== element.enabled ? (React.createElement(PendingIndicator, { disabled: busy },
                    "Pending ",
                    element.enabled ? 'addition' : 'deletion')) : (React.createElement(Button, { disabled: busy || disableEditing, kind: "secondary", onClick: () => {
                        toggle(element);
                        return void onRemoveAddress?.(element.address);
                    } }, "Delete")))),
                align: 'end',
                isSticky: false,
                key: 'actions',
            },
        ], footerComponent: React.createElement(Footer, null,
            React.createElement(Button, { kind: "secondary", disabled: busy || disableEditing, onClick: async () => {
                    try {
                        await addAddressModal.pop({
                            title: dialogTitleMap[type],
                            submitLabel: dialogSubmitLabelMap[type],
                            warning: type === AddressType.Automation ? (React.createElement(React.Fragment, null, "While this address cannot withdraw your tokens, it may trigger penalties for your Operator by engaging in unsuitable Sponsorships or prematurely exiting Sponsorships before the minimum stake period expires. It's important to exercise caution.")) : undefined,
                            async onSubmit(newAddress) {
                                const address = `0x${newAddress.replace(/^0x/i, '')}`.toLowerCase();
                                const exists = value.some((node) => node.address.toLowerCase() === address);
                                if (!exists) {
                                    onChange?.([
                                        ...value,
                                        {
                                            address,
                                            persisted: false,
                                            enabled: true,
                                        },
                                    ]);
                                    return void onAddAddress?.(address);
                                }
                                errorToast({
                                    title: 'Address already declared',
                                });
                            },
                        });
                    }
                    catch (e) {
                        if (isRejectionReason(e)) {
                            return;
                        }
                        console.warn('Failed to add address', e);
                    }
                } }, dialogTitleMap[type])) }));
}
const Address = styled.div.withConfig({ displayName: "Address", componentId: "sc-r6ikb5" }) `
    color: ${({ $new = false }) => ($new ? '#a3a3a3' : '#525252')};
`;
const addAddressModal = toaster(AddAddressModal, Layer.Modal);
const Footer = styled.div.withConfig({ displayName: "Footer", componentId: "sc-1w3iyj4" }) `
    display: flex;
    justify-content: right;
    padding: 32px;
    gap: 10px;
`;
const LowBalanceThreshold = toBigInt(0.1, 18n);
function PolBalance({ address }) {
    const [balance, setBalance] = useState();
    const currentChainId = useCurrentChainId();
    useEffect(() => {
        let mounted = true;
        void (async () => {
            try {
                const newBalance = await getBalance({
                    chainId: currentChainId,
                    tokenAddress: 'native',
                    walletAddress: address,
                });
                if (mounted) {
                    setBalance(newBalance);
                }
            }
            catch (e) {
                console.warn(`Failed to get balance for "${address}"`, e);
            }
        })();
        return () => {
            mounted = false;
        };
    }, [address, currentChainId]);
    const lowBalance = balance != null && balance < LowBalanceThreshold;
    return balance != null ? (React.createElement(PolBalanceRoot, null,
        React.createElement("div", null, toFloat(balance, 18n).toFixed(2)),
        lowBalance && (React.createElement(Tooltip, { content: "Low POL" },
            React.createElement(TooltipIconWrap, { className: "ml-1", "$color": "#ff5c00", "$svgSize": { width: '18px', height: '18px' } },
                React.createElement(JiraFailedBuildStatusIcon, { label: "Error" })))))) : (React.createElement(Spinner, { color: "blue" }));
}
const PolBalanceRoot = styled.div.withConfig({ displayName: "PolBalanceRoot", componentId: "sc-1lt1g56" }) `
    align-items: center;
    display: flex;
    gap: 8px;

    > * {
        flex-shrink: 0;
    }
`;
function PendingIndicator({ children, ...props }) {
    return (React.createElement(PendingIndicatorRoot, { ...props, type: "button" },
        React.createElement("div", null, children),
        React.createElement(Separator, null),
        React.createElement("div", null,
            React.createElement(Spinner, { color: "blue" }))));
}
const PendingIndicatorRoot = styled.button.withConfig({ displayName: "PendingIndicatorRoot", componentId: "sc-xy28i0" }) `
    align-items: center;
    appearance: none;
    background: #deebff;
    border-radius: 4px;
    border: 0;
    color: ${COLORS.primary};
    display: grid;
    font-size: 12px;
    font-weight: 500;
    grid-template-columns: auto 1px 24px;
    height: 32px;
    line-height: 20px;
    padding: 0;

    :disabled {
        opacity: 0.5;
    }

    ${Separator} {
        background: #d1dfff;
        height: 100%;
    }

    > div:first-child {
        padding: 0 8px;
    }

    svg {
        color: ${COLORS.close};
        display: block;
        height: 8px;
        margin: 0 auto;
        width: 8px;
    }

    ${Spinner} {
        height: 8px;
        width: 8px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-items: center;
    }
`;
export function useSubmitNodeAddressesCallback() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const cb = useCallback(async (chainId, operatorId, addresses, { onSuccess, onError, onReject } = {}) => {
        setIsSubmitting(true);
        try {
            await setOperatorNodeAddresses(chainId, operatorId, addresses, {
                onReceipt: ({ blockNumber }) => {
                    onSuccess?.(blockNumber);
                },
            });
        }
        catch (e) {
            if (isTransactionRejection(e) || isRejectionReason(e)) {
                /**
                 * User rejected the transaction. Let's move on like
                 * nothing happened.
                 */
                onReject?.();
                return;
            }
            console.warn('Failed to save the node addresses', e);
            onError?.(e);
        }
        finally {
            setIsSubmitting(false);
        }
    }, []);
    return [cb, isSubmitting];
}
export function useSubmitControllerAddressesCallback() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const cb = useCallback(async (chainId, operatorId, address, addNew, { onSuccess, onError, onReject } = {}) => {
        setIsSubmitting(true);
        try {
            if (addNew) {
                await addOperatorControllerAddress(chainId, operatorId, address, {
                    onReceipt: ({ blockNumber }) => {
                        onSuccess?.(blockNumber);
                    },
                });
            }
            else {
                await removeOperatorControllerAddress(chainId, operatorId, address, {
                    onReceipt: ({ blockNumber }) => {
                        onSuccess?.(blockNumber);
                    },
                });
            }
        }
        catch (e) {
            if (isTransactionRejection(e) || isRejectionReason(e)) {
                /**
                 * User rejected the transaction. Let's move on like
                 * nothing happened.
                 */
                onReject?.();
                return;
            }
            console.warn('Failed to save the controller addresses', e);
            onError?.(e);
        }
        finally {
            setIsSubmitting(false);
        }
    }, []);
    return [cb, isSubmitting];
}
