import React from 'react';
import styled, { css } from 'styled-components';
import ImageChecked from './checkbox-checked.svg';
import ImageUnchecked from './checkbox.svg';
export const Tick = styled.div.withConfig({ displayName: "Tick", componentId: "sc-1a6pboa" }) `
    background: url(${ImageUnchecked}) no-repeat;
    height: 16px;
    position: relative;
    width: 16px;

    ::after {
        background: url(${ImageChecked}) no-repeat;
        bottom: 0;
        content: ' ';
        display: block;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity 0.1s;
    }

    :disabled {
        filter: grayscale(1);
        cursor: not-allowed;
    }

    ${({ $checked = false }) => !!$checked &&
    css `
            ::after {
                opacity: 1;
            }
        `}
`;
const noop = () => { };
const UnstyledCheckbox = ({ value, onChange = noop, onClick = noop, ...props }) => (React.createElement(Tick, { ...props, as: "input", type: "checkbox", checked: !!value, "$checked": !!value, onChange: onChange, onClick: onClick }));
const Checkbox = styled(UnstyledCheckbox).withConfig({ displayName: "Checkbox", componentId: "sc-i2siht" }) `
    appearance: none;
    border: 0;
    cursor: pointer;
    flex-shrink: 0;
    margin-right: 0.5em;
    top: 0;

    :focus {
        outline: none;
    }
`;
export default Checkbox;
