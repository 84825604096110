import { StreamPermission } from '@streamr/sdk';
import React, { useEffect } from 'react';
import { Link, Navigate, Outlet, useLocation, useNavigate, useParams, } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '~/components/Button';
import ColoredBox from '~/components/ColoredBox';
import { CopyButton } from '~/components/CopyButton';
import { FloatingToolbar } from '~/components/FloatingToolbar';
import Helmet from '~/components/Helmet';
import Layout, { LayoutColumn } from '~/components/Layout';
import { Pad, SegmentGrid } from '~/components/NetworkPageSegment';
import { StreamSummary } from '~/components/StreamSummary';
import { useInViewport } from '~/hooks/useInViewport';
import { GenericErrorPageContent } from '~/pages/GenericErrorPage';
import { NotFoundPageContent } from '~/pages/NotFoundPage';
import { DetailsPageHeader } from '~/shared/components/DetailsPageHeader';
import LoadingIndicator from '~/shared/components/LoadingIndicator';
import { StreamConnect } from '~/shared/components/StreamConnect';
import { StreamPreview } from '~/shared/components/StreamPreview';
import Tabs, { Tab } from '~/shared/components/Tabs';
import StreamNotFoundError from '~/shared/errors/StreamNotFoundError';
import { useCurrentStreamAbility, useInvalidateStreamAbilities, } from '~/shared/stores/streamAbilities';
import { useWalletAccount } from '~/shared/stores/wallet';
import { DESKTOP, TABLET } from '~/shared/utils/styled';
import { truncateStreamName } from '~/shared/utils/text';
import { StreamDraft, usePersistStreamDraft, useStreamEntityQuery, } from '~/stores/streamDraft';
import { useCurrentChainSymbolicName } from '~/utils/chains';
import { Route as R, routeOptions } from '~/utils/routes';
import { AccessControlSection } from '../AbstractStreamEditPage/AccessControlSection';
import CreateProjectHint from '../AbstractStreamEditPage/CreateProjectHint';
import DeleteSection from '../AbstractStreamEditPage/DeleteSection';
import { HistorySection } from '../AbstractStreamEditPage/HistorySection';
import { InfoSection } from '../AbstractStreamEditPage/InfoSection';
import { PartitionsSection } from '../AbstractStreamEditPage/PartitionsSection';
import RelatedProjects from '../AbstractStreamEditPage/RelatedProjects';
import SponsorshipsTable from '../AbstractStreamEditPage/SponsorshipsTable';
export function StreamEditPage({ saveButtonRef, }) {
    const { fetching = false } = StreamDraft.useDraft() || {};
    const { id: streamId } = StreamDraft.useEntity() || {};
    const isNew = !streamId;
    const canEdit = useCurrentStreamAbility(streamId, StreamPermission.EDIT);
    const canDelete = useCurrentStreamAbility(streamId, StreamPermission.DELETE);
    const canGrant = useCurrentStreamAbility(streamId, StreamPermission.GRANT);
    const busy = StreamDraft.useIsDraftBusy();
    const canSubmit = useCanSubmit();
    const disabled = typeof canEdit === 'undefined' || busy;
    const isLoading = typeof canEdit === 'undefined' || busy || fetching;
    return (React.createElement(React.Fragment, null,
        streamId ? React.createElement(StreamSummary, { streamId: streamId }) : React.createElement(React.Fragment, null),
        React.createElement(LoadingIndicator, { loading: isLoading }),
        React.createElement(LayoutColumn, null,
            React.createElement(Footerless, null,
                React.createElement(SegmentGrid, null,
                    React.createElement(ColoredBox, null,
                        React.createElement(Pad, null,
                            React.createElement(Wings, null,
                                React.createElement("div", null,
                                    React.createElement(InfoSection, { disabled: disabled }),
                                    React.createElement(AccessControlSection, { disabled: disabled }),
                                    React.createElement(HistorySection, { disabled: disabled }),
                                    React.createElement(PartitionsSection, { disabled: disabled }),
                                    canDelete && React.createElement(DeleteSection, null)),
                                !isNew && (React.createElement(SaveButton, { kind: "primary", type: "submit", disabled: disabled || !canSubmit, ref: saveButtonRef }, "Save"))))),
                    streamId != null && React.createElement(SponsorshipsTable, { streamId: streamId })),
                streamId != null && (React.createElement(React.Fragment, null,
                    React.createElement(RelatedProjects, { streamId: streamId }),
                    canGrant && React.createElement(CreateProjectHint, { streamId: streamId })))))));
}
export function StreamLiveDataPage() {
    const { fetching = false } = StreamDraft.useDraft() || {};
    const { id: streamId = undefined } = StreamDraft.useEntity() || {};
    const canSubscribe = useCurrentStreamAbility(streamId, StreamPermission.SUBSCRIBE);
    const isLoading = fetching || canSubscribe == null;
    return (React.createElement(React.Fragment, null,
        React.createElement(LoadingIndicator, { loading: isLoading }),
        streamId != null && (React.createElement(StreamPreview, { streamsList: [streamId], previewDisabled: canSubscribe === false }))));
}
export function StreamConnectPage() {
    const { fetching = false } = StreamDraft.useDraft() || {};
    const { id: streamId = undefined } = StreamDraft.useEntity() || {};
    const canEdit = useCurrentStreamAbility(streamId, StreamPermission.EDIT);
    const isLoading = fetching || canEdit == null;
    return (React.createElement(React.Fragment, null,
        React.createElement(LoadingIndicator, { loading: isLoading }),
        React.createElement(LayoutColumn, null, streamId != null && (React.createElement(Footerless, null,
            React.createElement(SegmentGrid, null,
                React.createElement(ColoredBox, null,
                    React.createElement(Pad, null,
                        React.createElement(StreamConnect, { streams: [streamId] })))),
            React.createElement(RelatedProjects, { streamId: streamId }))))));
}
/**
 * @todo Taken care of in `app`, no? Double-check & remove.
 */
export function StreamIndexRedirect() {
    const { id } = useParams();
    if (!id) {
        throw new Error('Invalid context (missing stream id)');
    }
    return (React.createElement(Navigate, { to: {
            pathname: R.streamOverview(id),
            search: window.location.search,
        }, replace: true }));
}
export function StreamDraftPage() {
    const { id: streamId } = useParams();
    const draftId = StreamDraft.useInitDraft(streamId);
    return (React.createElement(StreamDraft.DraftContext.Provider, { value: draftId },
        React.createElement(Outlet, null)));
}
export function StreamTabbedPage(props) {
    const { stickySubmit = false, children = React.createElement(Outlet, null) } = props;
    const query = useStreamEntityQuery();
    const { data: stream = null } = query;
    const isLoading = !stream && (query.isLoading || query.isFetching);
    const { assign } = StreamDraft.useDraftStore();
    const draftId = StreamDraft.useDraftId();
    const initialized = StreamDraft.useDraft()?.initialized || false;
    useEffect(function assignEntity() {
        if (initialized) {
            assign(draftId, stream);
        }
    }, [assign, draftId, initialized, stream]);
    return (React.createElement(StreamEntityForm, { stickySubmit: stickySubmit }, isLoading ? (React.createElement(LoadingIndicator, { loading: true })) : query.error instanceof StreamNotFoundError ? (React.createElement(React.Fragment, null,
        React.createElement(LoadingIndicator, null),
        React.createElement(NotFoundPageContent, null))) : query.error ? (React.createElement(React.Fragment, null,
        React.createElement(LoadingIndicator, null),
        React.createElement(GenericErrorPageContent, null))) : (children)));
}
function StreamEntityForm(props) {
    const { children, stickySubmit = false } = props;
    const [attach, isSaveButtonVisible] = useInViewport();
    const canSubmit = useCanSubmit();
    const navigate = useNavigate();
    const account = useWalletAccount()?.toLowerCase();
    const invalidateAbilities = useInvalidateStreamAbilities();
    const persist = usePersistStreamDraft({
        onCreate(chainId, streamId, { abortSignal }) {
            if (abortSignal?.aborted) {
                /**
                 * Avoid redirecting to the new stream's edit page after the stream
                 * page has been unmounted.
                 */
                return;
            }
            navigate(R.streamOverview(streamId, routeOptions(chainId)));
        },
        onPermissionsChange(streamId, assignments) {
            if (!account) {
                return;
            }
            for (const assignment of assignments) {
                /**
                 * Detect if the new set of assignments affect the current user
                 * and, if so, invalidate associated stream abilities.
                 */
                if ('user' in assignment && assignment.user.toLowerCase() === account) {
                    invalidateAbilities(streamId, account);
                    break;
                }
            }
        },
    });
    const ready = !!StreamDraft.useEntity({ hot: true });
    return (React.createElement("form", { onSubmit: (e) => {
            e.preventDefault();
            if (stickySubmit) {
                persist();
            }
        } },
        React.createElement(Layout, { footer: null },
            React.createElement(Header, { saveButtonRef: attach }),
            typeof children === 'function' ? children(attach, ready) : children),
        React.createElement(FloatingToolbar, { "$active": !isSaveButtonVisible && stickySubmit && ready },
            React.createElement(Button, { type: "submit", disabled: !canSubmit || isSaveButtonVisible }, "Save"))));
}
function useCanSubmit() {
    const busy = StreamDraft.useIsDraftBusy();
    const clean = StreamDraft.useIsDraftClean();
    return !busy && !clean;
}
function Header({ saveButtonRef, }) {
    const entity = StreamDraft.useEntity({ hot: true });
    const { id: streamId, domain = '', pathname = '' } = entity || {};
    const isNew = !streamId;
    const transientStreamId = domain && pathname ? `${domain}/${pathname}` : undefined;
    const location = useLocation();
    const canSubmit = useCanSubmit();
    const clean = StreamDraft.useIsDraftClean();
    const ready = !!entity;
    const chainName = useCurrentChainSymbolicName();
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, { title: ready ? (streamId ? `Stream ${streamId}` : 'New stream') : undefined }),
        React.createElement(DetailsPageHeader, { backButtonLink: R.streams(routeOptions(chainName)), pageTitle: React.createElement(TitleContainer, null,
                React.createElement("span", { title: streamId }, ready ? (streamId ? (truncateStreamName(streamId, 50)) : (transientStreamId || 'New stream')) : (React.createElement(React.Fragment, null, "\u200C"))),
                streamId ? React.createElement(CopyButton, { value: streamId }) : ''), rightComponent: streamId ? (React.createElement(Tabs, null,
                React.createElement(Tab, { id: "overview", tag: Link, to: R.streamOverview(streamId, routeOptions(chainName)), selected: location.pathname.startsWith(R.streamOverview(streamId)) },
                    "Stream overview",
                    !clean && React.createElement(Asterisk, null)),
                React.createElement(Tab, { id: "connect", tag: Link, to: R.streamConnect(streamId, routeOptions(chainName)), selected: location.pathname.startsWith(R.streamConnect(streamId)) }, "Connect"),
                React.createElement(Tab, { id: "liveData", tag: Link, to: R.streamLiveData(streamId, routeOptions(chainName)), selected: location.pathname.startsWith(R.streamLiveData(streamId)) }, "Live data"))) : isNew && ready ? (React.createElement("div", null,
                React.createElement(Button, { disabled: !canSubmit, kind: "primary", type: "submit", ref: saveButtonRef }, "Save"))) : null })));
}
const TitleContainer = styled.div.withConfig({ displayName: "TitleContainer", componentId: "sc-1vf2qaf" }) `
    align-items: center;
    display: flex;
    gap: 8px;
`;
const Asterisk = styled.span.withConfig({ displayName: "Asterisk", componentId: "sc-1ogoqoh" }) `
    :after {
        content: '*';
        position: absolute;
    }
`;
const SaveButton = styled(Button).withConfig({ displayName: "SaveButton", componentId: "sc-12qosyl" }) `
    width: fit-content;
    justify-self: right;
`;
const Footerless = styled.div.withConfig({ displayName: "Footerless", componentId: "sc-1f17xuz" }) `
    padding-bottom: 80px;

    @media ${TABLET} {
        padding-bottom: 92px;
    }

    @media ${DESKTOP} {
        padding-bottom: 128px;
    }
`;
const Wings = styled.div.withConfig({ displayName: "Wings", componentId: "sc-1d15bfq" }) `
    display: grid;
    grid-template-columns: fit-content(680px) auto;

    div:first-child {
        min-width: 0;
    }
`;
