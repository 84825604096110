import React from 'react';
import styled from 'styled-components';
import { Footer as UnstyledLayoutFooter, FooterColumn, FooterColumns as UnstyledFooterColumns, MadeBy as UnstyledMadeBy, SocialChannels, } from '@streamr/streamr-layout';
import { COLORS } from '~/shared/utils/styled';
import { Route as R, routeOptions } from '~/utils/routes';
import { useCurrentChainSymbolicName } from '~/utils/chains';
const MadeBy = styled(UnstyledMadeBy).withConfig({ displayName: "MadeBy", componentId: "sc-1i53sas" }) `
    padding: 0 0 32px;
    text-align: center;

    @media (min-width: 1200px) {
        padding: 32px 0;
        text-align: left;
    }
`;
const LayoutFooter = styled(UnstyledLayoutFooter).withConfig({ displayName: "LayoutFooter", componentId: "sc-1vn5rs0" }) `
    background-color: ${COLORS.secondary};
`;
const FooterColumns = styled(UnstyledFooterColumns).withConfig({ displayName: "FooterColumns", componentId: "sc-16fbn9y" }) `
    border-top: ${({ separate = false }) => (separate ? '1px' : '0')} solid #d8d8d8;
`;
const Footer = ({ topBorder = false }) => {
    const chainName = useCurrentChainSymbolicName();
    return (React.createElement(LayoutFooter, null,
        React.createElement(FooterColumns, { separate: topBorder },
            React.createElement(FooterColumn, { title: "Discover" },
                React.createElement("a", { href: R.root() }, "Top"),
                React.createElement("a", { href: R.siteDataToken() }, "DATA Token"),
                React.createElement("a", { href: R.siteDataUnions() }, "Data Unions"),
                React.createElement("a", { href: R.siteMarketplace() }, "Marketplace"),
                React.createElement("a", { href: R.siteNetwork() }, "Network")),
            React.createElement(FooterColumn, { title: "Project" },
                React.createElement("a", { href: R.siteAbout() }, "About"),
                React.createElement("a", { href: R.siteRoadmap() }, "Roadmap"),
                React.createElement("a", { href: R.siteEcosystem() }, "Ecosystem"),
                React.createElement("a", { href: R.sitePapers() }, "Papers"),
                React.createElement("a", { href: R.blog() }, "Blog")),
            React.createElement(FooterColumn, { title: "Developers" },
                React.createElement("a", { href: R.docs() }, "Docs"),
                React.createElement("a", { href: R.siteFund() }, "Data Fund"),
                React.createElement("a", { href: R.siteDesign() }, "Design Assets")),
            React.createElement(FooterColumn, { title: "Apps" },
                React.createElement("a", { href: R.networkExplorer() }, "Network Explorer"),
                React.createElement("a", { href: R.hub(routeOptions(chainName)) }, "Hub")),
            React.createElement(FooterColumn, { title: "Contact" },
                React.createElement("a", { href: R.contactGeneral() }, "General"),
                React.createElement("a", { href: R.contactMedia() }, "Media"),
                React.createElement("a", { href: R.contactJobs() }, "Jobs"),
                React.createElement("a", { href: R.contactLabs() }, "Business")),
            React.createElement(FooterColumn, { title: "Legal" },
                React.createElement("a", { href: R.tos() }, "Terms & Conditions"),
                React.createElement("a", { href: R.privacyPolicy() }, "Privacy"))),
        React.createElement(SocialChannels, null),
        React.createElement(MadeBy, null)));
};
export default Footer;
