import { StreamPermission } from '@streamr/sdk';
import { CodeSnippet, Tabs } from '@streamr/streamr-layout';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '~/components/Button';
import { address0 } from '~/consts';
import { SelectField2 } from '~/marketplace/components/SelectField2';
import SvgIcon from '~/shared/components/SvgIcon';
import useCopy from '~/shared/hooks/useCopy';
import { useStreamAbility } from '~/shared/stores/streamAbilities';
import { COLORS, DESKTOP, TABLET } from '~/shared/utils/styled';
import { truncateStreamName } from '~/shared/utils/text';
import { Route as R } from '~/utils/routes';
function stripIndent(code) {
    let minIndent = Number.POSITIVE_INFINITY;
    (code.match(/^[ \t]*(?=\S)/gm) || ['']).forEach(({ length }) => {
        if (length < minIndent) {
            minIndent = length;
        }
    });
    return code
        .replace(new RegExp(`^[ \\t]{${minIndent}}`, 'gm'), '')
        .trim()
        .replace(/^\s+$/gm, '');
}
const Snippet = {
    createClient(hasPermission) {
        if (hasPermission) {
            return `const streamr = new StreamrClient()`;
        }
        return `const streamr = new StreamrClient({
                auth: {
                    privateKey: 'ethereum-private-key' 
                }
            })`;
    },
    lightNodeHeader(hasPermission) {
        return `
            // Run a Streamr node right inside your JS app
            const StreamrClient = require('@streamr/sdk')
            ${this.createClient(hasPermission)}
        `;
    },
    lightNodeSubscribe(streamId, hasPermission) {
        return `
            ${this.lightNodeHeader(hasPermission)}
            // Subscribe to a stream of messages
            streamr.subscribe('${streamId}', (msg) => {
                // Handle incoming messages
            })
        `;
    },
    lightNodePublish(streamId, hasPermission) {
        return `
            ${this.lightNodeHeader(hasPermission)}
            // Publish messages to a stream
            streamr.publish('${streamId}', {
                hello: 'world',
            })
        `;
    },
    websocketHeader(streamId) {
        return `
            // You'll want to URI-encode the stream id
            const streamId = encodeURIComponent('${streamId}')
        `;
    },
    websocketPublish(streamId) {
        return `
            ${this.websocketHeader(streamId)}
            // Connect to the Websocket plugin on your Streamr 
            // node and send JSON messages to publish them
            const pub = ws.connect(\`ws://my-streamr-node:7170/streams/\${streamId}/publish\`)
            pub.send({
                hello: 'world',
            })
        `;
    },
    websocketSubscribe(streamId) {
        return `
            ${this.websocketHeader(streamId)}
            // Connect to the Websocket plugin on your Streamr 
            // node and subscribe to a stream of messages
            const sub = ws.connect(\`ws://my-streamr-node:7170/streams/\${streamId}/subscribe\`)
            sub.onmessage = (msg) => {
                // Handle incoming messages
            }
        `;
    },
    httpSubscribe() {
        return `
            // The Subscribe over HTTP is not available, as in it's not a valid selection.
        `;
    },
    httpPublish(streamId) {
        return `
            // Use your favourite language and HTTP library!

            // You'll want to URI-encode the stream id
            const streamId = encodeURIComponent('${streamId}')

            // Publish messages to a stream by POSTing JSON 
            // to the HTTP plugin on your Streamr node
            http.post(\`http://my-streamr-node:7171/streams/\${streamId}\`, {
                hello: 'world'
            })
        `;
    },
    mqttHeader() {
        return `
            // Use your favourite language and MQTT library!

            // Connect to MQTT plugin on your Streamr node
            mqtt.connect('mqtt://my-streamr-node')
        `;
    },
    mqttSubscribe(streamId) {
        return `
            ${this.mqttHeader()}
            // Subscribe to a stream of messages
            mqtt.subscribe('${streamId}', (msg) => {
                // Handle incoming messages
            })
        `;
    },
    mqttPublish(streamId) {
        return `
            ${this.mqttHeader()}
            // Publish a message to a stream
            mqtt.publish('${streamId}', {
                hello: 'world',
            })
        `;
    },
};
export const StreamConnect = ({ streams }) => {
    const [streamId, setSelectedStream] = useState(streams[0]);
    const [action, setAction] = useState('subscribe');
    const [nodeType, setNodeType] = useState('lightNode');
    const [currentProtocol, setCurrentProtocol] = useState('websocket');
    const hasPublicPubPermission = useStreamAbility(streamId, address0, StreamPermission.PUBLISH);
    const hasPublicSubPermission = useStreamAbility(streamId, address0, StreamPermission.SUBSCRIBE);
    const lightNodeSnippet = stripIndent(action === 'publish'
        ? Snippet.lightNodePublish(streamId, !!hasPublicPubPermission)
        : Snippet.lightNodeSubscribe(streamId, !!hasPublicSubPermission));
    const websocketSnippet = stripIndent(action === 'publish'
        ? Snippet.websocketPublish(streamId)
        : Snippet.websocketSubscribe(streamId));
    const httpSnippet = stripIndent(action === 'subscribe' ? Snippet.httpSubscribe() : Snippet.httpPublish(streamId));
    const mqttSnippet = stripIndent(action === 'publish'
        ? Snippet.mqttPublish(streamId)
        : Snippet.mqttSubscribe(streamId));
    const { copy } = useCopy();
    const currentBrokerSnippet = (() => {
        switch (currentProtocol) {
            case 'websocket':
                return websocketSnippet;
            case 'http':
                return httpSnippet;
            case 'mqtt':
                return mqttSnippet;
        }
    })();
    return (React.createElement(Grid, null,
        React.createElement("div", null,
            React.createElement(StreamConnectHeader, null, "Connect"),
            React.createElement(StreamConnectText, null, "Here are the code snippets to connect to this stream with the Streamr SDK or through a separately running Streamr node:"),
            React.createElement(SnippetSelectorContainer, null,
                React.createElement(SelectContainer, null,
                    React.createElement(SelectField2, { placeholder: '', options: [
                            { label: 'Subscribe', value: 'subscribe' },
                            { label: 'Publish', value: 'publish' },
                        ], value: action, isClearable: false, onChange: (action) => {
                            if (action === 'subscribe' || action === 'publish') {
                                setAction(action);
                            }
                        }, noShrink: true, fullWidth: true })),
                React.createElement("span", null, "to"),
                React.createElement(SelectContainer, null,
                    React.createElement(SelectField2, { placeholder: '', options: streams.map((streamId) => ({
                            value: streamId,
                            label: truncateStreamName(streamId),
                        })), value: streamId, isClearable: false, onChange: (streamId) => {
                            setSelectedStream(streamId);
                        }, fullWidth: true })),
                React.createElement("span", null, "using"),
                React.createElement(SelectContainer, null,
                    React.createElement(SelectField2, { placeholder: '', options: [
                            { label: 'Streamr SDK', value: 'lightNode' },
                            { label: 'Streamr node', value: 'brokerNode' },
                        ], value: nodeType, isClearable: false, onChange: (nodeType) => {
                            if (nodeType === 'lightNode' ||
                                nodeType === 'brokerNode') {
                                setNodeType(nodeType);
                            }
                        }, noShrink: true, fullWidth: true }))),
            nodeType === 'lightNode' && (React.createElement(React.Fragment, null,
                React.createElement(StreamConnectLightNodeSnippetContainer, null,
                    React.createElement(CodeSnippet, { language: 'javascript' }, lightNodeSnippet)),
                React.createElement(StreamConnectSnippetCopyContainer, null,
                    React.createElement(Button, { kind: "secondary", onClick: () => void copy(lightNodeSnippet) }, "Copy")))),
            nodeType === 'brokerNode' && (React.createElement(BrokerNodeSnippetContainer, null,
                React.createElement(Tabs, { selected: currentProtocol, onSelect: (tab) => setCurrentProtocol(tab) },
                    React.createElement(Tabs.Item, { label: 'Websocket', value: 'websocket', key: 0 },
                        React.createElement(CodeSnippet, { language: 'javascript' }, websocketSnippet)),
                    React.createElement(Tabs.Item, { label: 'HTTP', value: 'http', key: 1 },
                        React.createElement(CodeSnippet, { language: 'javascript' }, httpSnippet)),
                    React.createElement(Tabs.Item, { label: 'MQTT', value: 'mqtt', key: 2 },
                        React.createElement(CodeSnippet, { language: 'javascript' }, mqttSnippet))),
                React.createElement(StreamConnectSnippetCopyContainer, null,
                    React.createElement(Button, { kind: "secondary", onClick: () => void copy(currentBrokerSnippet) }, "Copy"))))),
        React.createElement(RightColumn, null,
            React.createElement(StreamConnectLink, { href: R.docs('/guides/nodejs'), target: "_blank", rel: "noreferrer noopener" },
                React.createElement("span", null, "Pub/Sub in NodeJS"),
                React.createElement(SvgIcon, { name: "linkOut" })),
            React.createElement(StreamConnectLink, { href: R.docs('/guides/web-app-frameworks'), target: "_blank", rel: "noreferrer noopener" },
                React.createElement("span", null, "Use Streamr in your web app"),
                React.createElement(SvgIcon, { name: "linkOut" })),
            React.createElement(StreamConnectLink, { href: R.docs('/usage/cli-tool'), target: "_blank", rel: "noreferrer noopener" },
                React.createElement("span", null, "The Streamr CLI tool"),
                React.createElement(SvgIcon, { name: "linkOut" })),
            React.createElement(StreamConnectLink, { href: R.docs('/usage/connect-apps-and-iot/streamr-node-interface'), target: "_blank", rel: "noreferrer noopener" },
                React.createElement("span", null, "Interfacing with a Streamr node"),
                React.createElement(SvgIcon, { name: "linkOut" })))));
};
const Grid = styled.div.withConfig({ displayName: "Grid", componentId: "sc-1oaf2o7" }) `
    display: grid;
    grid-template-columns: initial;
    grid-auto-flow: row;
    gap: 32px;

    @media ${DESKTOP} {
        grid-template-columns: 65% auto;
        grid-auto-flow: column;
        gap: 88px;
    }
`;
const RightColumn = styled.div.withConfig({ displayName: "RightColumn", componentId: "sc-izi0z2" }) `
    padding-top: 0px;

    @media ${DESKTOP} {
        padding-top: 88px;
    }
`;
const StreamConnectHeader = styled.p.withConfig({ displayName: "StreamConnectHeader", componentId: "sc-ps0tf8" }) `
    font-size: 24px;
    color: ${COLORS.primary};
    line-height: 64px;
    margin-bottom: 24px;
`;
const StreamConnectText = styled.p.withConfig({ displayName: "StreamConnectText", componentId: "sc-y6nrjd" }) `
    font-size: 16px;
    color: ${COLORS.primary};
`;
const StreamConnectLightNodeSnippetContainer = styled.div.withConfig({ displayName: "StreamConnectLightNodeSnippetContainer", componentId: "sc-1759ut4" }) `
    border: 1px solid ${COLORS.Border};
    background-color: white;
    margin-top: 30px;
    max-width: calc(100vw - 100px);
    @media ${TABLET} {
        max-width: calc(100vw - 160px);
    }
`;
const StreamConnectSnippetCopyContainer = styled.div.withConfig({ displayName: "StreamConnectSnippetCopyContainer", componentId: "sc-1ozil41" }) `
    border: 1px solid ${COLORS.Border};
    border-top: none;
    background-color: white;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
const StreamConnectLink = styled.a.withConfig({ displayName: "StreamConnectLink", componentId: "sc-9e5sid" }) `
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${COLORS.secondaryLight};
    padding: 10px 16px;
    margin-bottom: 8px;
    border-radius: 4px;
    span {
        color: ${COLORS.primary};
    }
    svg {
        color: ${COLORS.primary};
    }
`;
const SnippetSelectorContainer = styled.div.withConfig({ displayName: "SnippetSelectorContainer", componentId: "sc-1ac352p" }) `
    display: flex;
    flex-wrap: nowrap;
    margin-top: 20px;
    align-items: left;
    flex-direction: column;

    @media ${TABLET} {
        flex-direction: row;
        align-items: center;

        > * {
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
`;
const BrokerNodeSnippetContainer = styled.div.withConfig({ displayName: "BrokerNodeSnippetContainer", componentId: "sc-1u2nq6z" }) `
    margin-top: 30px;
    max-width: calc(100vw - 100px);
    @media ${TABLET} {
        max-width: calc(100vw - 160px);
    }
`;
const SelectContainer = styled.div.withConfig({ displayName: "SelectContainer", componentId: "sc-1pckr2p" }) `
    height: 100%;
    min-width: 140px;
`;
