import React from 'react';
import styled, { css } from 'styled-components';
import { SimpleDropdown, SimpleListDropdownMenu } from '~/components/SimpleDropdown';
import SvgIcon from '~/shared/components/SvgIcon';
import { Toggle } from '~/shared/components/Toggle';
import { COLORS } from '~/shared/utils/styled';
export const defaultFilters = {
    inactive: true,
    noFunding: true,
    expired: true,
    my: false,
};
export const SponsorshipFilterButton = ({ filter = defaultFilters, onFilterChange, }) => {
    return (React.createElement(SimpleDropdown, { menu: React.createElement(Menu, { value: filter, onChange: onFilterChange }), align: "right" }, (toggle, isOpen) => (React.createElement(Button, { type: "button", onClick: () => void toggle((c) => !c), "$active": isOpen },
        React.createElement(SvgIcon, { name: "faders" }),
        "Display"))));
};
const ToggleOption = ({ id, label, value, onChange }) => {
    return (React.createElement(Option, null,
        React.createElement(ToggleLabel, { htmlFor: id }, label),
        React.createElement(Toggle, { id: id, value: value, onChange: onChange })));
};
const Menu = ({ value, onChange }) => {
    return (React.createElement(DropdownMenu, null,
        React.createElement(Title, null, "Showing"),
        React.createElement(ToggleContainer, null,
            React.createElement(ToggleOption, { id: 'inactive', label: "Inactive sponsorships", value: value.inactive, onChange: (val) => {
                    onChange?.({ ...value, inactive: val });
                } }),
            React.createElement(ToggleOption, { id: 'noFunding', label: "Without funding sponsorships", value: value.noFunding, onChange: (val) => {
                    onChange?.({ ...value, noFunding: val });
                } }),
            React.createElement(ToggleOption, { id: 'expired', label: "Expired funding sponsorships", value: value.expired, onChange: (val) => {
                    onChange?.({ ...value, expired: val });
                } }),
            React.createElement(ToggleOption, { id: 'my', label: "Sponsorships with my Operator", value: value.my, onChange: (val) => {
                    onChange?.({ ...value, my: val });
                } }))));
};
const DropdownMenu = styled(SimpleListDropdownMenu).withConfig({ displayName: "DropdownMenu", componentId: "sc-ry9m2u" }) `
    padding: 16px;
    color: ${COLORS.primary};
`;
const Button = styled.button.withConfig({ displayName: "Button", componentId: "sc-5ms2sw" }) `
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    gap: 6px;
    color: ${COLORS.primaryLight};
    background-color: ${COLORS.secondaryLight};
    border-radius: 8px;
    border: none;
    cursor: pointer;

    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;

    ${({ $active = false }) => $active &&
    css `
            color: ${COLORS.primaryContrast};
            background-color: ${COLORS.primary};
        `}
`;
const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-94zcw0" }) `
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 16px;
`;
const ToggleContainer = styled.div.withConfig({ displayName: "ToggleContainer", componentId: "sc-6da4qk" }) `
    display: grid;
    grid-template-columns: 1fr auto;
    min-width: 377px;
    gap: 16px;
`;
const Option = styled.div.withConfig({ displayName: "Option", componentId: "sc-13iygz" }) `
    display: grid;
    grid-template-columns: subgrid;
    grid-column: span 2;
`;
const ToggleLabel = styled.label.withConfig({ displayName: "ToggleLabel", componentId: "sc-161gwty" }) `
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
`;
