import React from 'react';
import styled from 'styled-components';
import { useImage } from 'react-image';
import { getTokenLogoUrl } from '~/shared/utils/tokenAssets';
import GenericTokenLogo from './GenericTokenLogo';
const Image = styled.img.withConfig({ displayName: "Image", componentId: "sc-q6829e" }) `
    width: 24px;
    height: 24px;
`;
const UnstyledTokenLogo = ({ contractAddress, symbol, chainId, ...props }) => {
    const logoUrl = contractAddress ? getTokenLogoUrl(contractAddress, chainId) : '';
    const { src: imgSrc } = useImage({
        useSuspense: false,
        srcList: [logoUrl],
    });
    return imgSrc ? (React.createElement(Image, { ...props, src: imgSrc, alt: "Token logo" })) : (React.createElement(GenericTokenLogo, { contractAddress: contractAddress, symbol: symbol }));
};
const TokenLogo = styled(UnstyledTokenLogo).withConfig({ displayName: "TokenLogo", componentId: "sc-li35s9" }) ``;
export default TokenLogo;
