import { gql } from '@apollo/client';
export var Aggregation_Interval;
(function (Aggregation_Interval) {
    Aggregation_Interval["Day"] = "day";
    Aggregation_Interval["Hour"] = "hour";
})(Aggregation_Interval || (Aggregation_Interval = {}));
export var Delegation_OrderBy;
(function (Delegation_OrderBy) {
    Delegation_OrderBy["ValueDataWei"] = "_valueDataWei";
    Delegation_OrderBy["Delegator"] = "delegator";
    Delegation_OrderBy["DelegatorCumulativeEarningsWei"] = "delegator__cumulativeEarningsWei";
    Delegation_OrderBy["DelegatorId"] = "delegator__id";
    Delegation_OrderBy["DelegatorNumberOfDelegations"] = "delegator__numberOfDelegations";
    Delegation_OrderBy["DelegatorTotalValueDataWei"] = "delegator__totalValueDataWei";
    Delegation_OrderBy["EarliestUndelegationTimestamp"] = "earliestUndelegationTimestamp";
    Delegation_OrderBy["Id"] = "id";
    Delegation_OrderBy["IsSelfDelegation"] = "isSelfDelegation";
    Delegation_OrderBy["LatestDelegationTimestamp"] = "latestDelegationTimestamp";
    Delegation_OrderBy["Operator"] = "operator";
    Delegation_OrderBy["OperatorTokenBalanceWei"] = "operatorTokenBalanceWei";
    Delegation_OrderBy["OperatorContractVersion"] = "operator__contractVersion";
    Delegation_OrderBy["OperatorCumulativeEarningsWei"] = "operator__cumulativeEarningsWei";
    Delegation_OrderBy["OperatorCumulativeOperatorsCutWei"] = "operator__cumulativeOperatorsCutWei";
    Delegation_OrderBy["OperatorCumulativeProfitsWei"] = "operator__cumulativeProfitsWei";
    Delegation_OrderBy["OperatorDataTokenBalanceWei"] = "operator__dataTokenBalanceWei";
    Delegation_OrderBy["OperatorDelegatorCount"] = "operator__delegatorCount";
    Delegation_OrderBy["OperatorExchangeRate"] = "operator__exchangeRate";
    Delegation_OrderBy["OperatorId"] = "operator__id";
    Delegation_OrderBy["OperatorIsEligibleToVote"] = "operator__isEligibleToVote";
    Delegation_OrderBy["OperatorLatestHeartbeatMetadata"] = "operator__latestHeartbeatMetadata";
    Delegation_OrderBy["OperatorLatestHeartbeatTimestamp"] = "operator__latestHeartbeatTimestamp";
    Delegation_OrderBy["OperatorMetadataJsonString"] = "operator__metadataJsonString";
    Delegation_OrderBy["OperatorOperatorTokenTotalSupplyWei"] = "operator__operatorTokenTotalSupplyWei";
    Delegation_OrderBy["OperatorOperatorsCutFraction"] = "operator__operatorsCutFraction";
    Delegation_OrderBy["OperatorOwner"] = "operator__owner";
    Delegation_OrderBy["OperatorProtectionEndTimestamp"] = "operator__protectionEndTimestamp";
    Delegation_OrderBy["OperatorSlashingsCount"] = "operator__slashingsCount";
    Delegation_OrderBy["OperatorTotalStakeInSponsorshipsWei"] = "operator__totalStakeInSponsorshipsWei";
    Delegation_OrderBy["OperatorValueUpdateBlockNumber"] = "operator__valueUpdateBlockNumber";
    Delegation_OrderBy["OperatorValueUpdateTimestamp"] = "operator__valueUpdateTimestamp";
    Delegation_OrderBy["OperatorValueWithoutEarnings"] = "operator__valueWithoutEarnings";
})(Delegation_OrderBy || (Delegation_OrderBy = {}));
export var DelegatorDailyBucket_OrderBy;
(function (DelegatorDailyBucket_OrderBy) {
    DelegatorDailyBucket_OrderBy["CumulativeEarningsWei"] = "cumulativeEarningsWei";
    DelegatorDailyBucket_OrderBy["Date"] = "date";
    DelegatorDailyBucket_OrderBy["Delegator"] = "delegator";
    DelegatorDailyBucket_OrderBy["DelegatorCumulativeEarningsWei"] = "delegator__cumulativeEarningsWei";
    DelegatorDailyBucket_OrderBy["DelegatorId"] = "delegator__id";
    DelegatorDailyBucket_OrderBy["DelegatorNumberOfDelegations"] = "delegator__numberOfDelegations";
    DelegatorDailyBucket_OrderBy["DelegatorTotalValueDataWei"] = "delegator__totalValueDataWei";
    DelegatorDailyBucket_OrderBy["Id"] = "id";
    DelegatorDailyBucket_OrderBy["OperatorCount"] = "operatorCount";
    DelegatorDailyBucket_OrderBy["TotalValueDataWei"] = "totalValueDataWei";
})(DelegatorDailyBucket_OrderBy || (DelegatorDailyBucket_OrderBy = {}));
export var Delegator_OrderBy;
(function (Delegator_OrderBy) {
    Delegator_OrderBy["CumulativeEarningsWei"] = "cumulativeEarningsWei";
    Delegator_OrderBy["Delegations"] = "delegations";
    Delegator_OrderBy["Id"] = "id";
    Delegator_OrderBy["NumberOfDelegations"] = "numberOfDelegations";
    Delegator_OrderBy["QueueEntries"] = "queueEntries";
    Delegator_OrderBy["TotalValueDataWei"] = "totalValueDataWei";
})(Delegator_OrderBy || (Delegator_OrderBy = {}));
export var Flag_OrderBy;
(function (Flag_OrderBy) {
    Flag_OrderBy["FlagResolutionTimestamp"] = "flagResolutionTimestamp";
    Flag_OrderBy["Flagger"] = "flagger";
    Flag_OrderBy["FlaggerContractVersion"] = "flagger__contractVersion";
    Flag_OrderBy["FlaggerCumulativeEarningsWei"] = "flagger__cumulativeEarningsWei";
    Flag_OrderBy["FlaggerCumulativeOperatorsCutWei"] = "flagger__cumulativeOperatorsCutWei";
    Flag_OrderBy["FlaggerCumulativeProfitsWei"] = "flagger__cumulativeProfitsWei";
    Flag_OrderBy["FlaggerDataTokenBalanceWei"] = "flagger__dataTokenBalanceWei";
    Flag_OrderBy["FlaggerDelegatorCount"] = "flagger__delegatorCount";
    Flag_OrderBy["FlaggerExchangeRate"] = "flagger__exchangeRate";
    Flag_OrderBy["FlaggerId"] = "flagger__id";
    Flag_OrderBy["FlaggerIsEligibleToVote"] = "flagger__isEligibleToVote";
    Flag_OrderBy["FlaggerLatestHeartbeatMetadata"] = "flagger__latestHeartbeatMetadata";
    Flag_OrderBy["FlaggerLatestHeartbeatTimestamp"] = "flagger__latestHeartbeatTimestamp";
    Flag_OrderBy["FlaggerMetadataJsonString"] = "flagger__metadataJsonString";
    Flag_OrderBy["FlaggerOperatorTokenTotalSupplyWei"] = "flagger__operatorTokenTotalSupplyWei";
    Flag_OrderBy["FlaggerOperatorsCutFraction"] = "flagger__operatorsCutFraction";
    Flag_OrderBy["FlaggerOwner"] = "flagger__owner";
    Flag_OrderBy["FlaggerProtectionEndTimestamp"] = "flagger__protectionEndTimestamp";
    Flag_OrderBy["FlaggerSlashingsCount"] = "flagger__slashingsCount";
    Flag_OrderBy["FlaggerTotalStakeInSponsorshipsWei"] = "flagger__totalStakeInSponsorshipsWei";
    Flag_OrderBy["FlaggerValueUpdateBlockNumber"] = "flagger__valueUpdateBlockNumber";
    Flag_OrderBy["FlaggerValueUpdateTimestamp"] = "flagger__valueUpdateTimestamp";
    Flag_OrderBy["FlaggerValueWithoutEarnings"] = "flagger__valueWithoutEarnings";
    Flag_OrderBy["FlaggingTimestamp"] = "flaggingTimestamp";
    Flag_OrderBy["Id"] = "id";
    Flag_OrderBy["LastFlagIndex"] = "lastFlagIndex";
    Flag_OrderBy["Metadata"] = "metadata";
    Flag_OrderBy["ProtectionEndTimestamp"] = "protectionEndTimestamp";
    Flag_OrderBy["Result"] = "result";
    Flag_OrderBy["ReviewerCount"] = "reviewerCount";
    Flag_OrderBy["Reviewers"] = "reviewers";
    Flag_OrderBy["Sponsorship"] = "sponsorship";
    Flag_OrderBy["SponsorshipCreator"] = "sponsorship__creator";
    Flag_OrderBy["SponsorshipCumulativeSponsoring"] = "sponsorship__cumulativeSponsoring";
    Flag_OrderBy["SponsorshipId"] = "sponsorship__id";
    Flag_OrderBy["SponsorshipIsRunning"] = "sponsorship__isRunning";
    Flag_OrderBy["SponsorshipMaxOperators"] = "sponsorship__maxOperators";
    Flag_OrderBy["SponsorshipMetadata"] = "sponsorship__metadata";
    Flag_OrderBy["SponsorshipMinOperators"] = "sponsorship__minOperators";
    Flag_OrderBy["SponsorshipMinimumStakingPeriodSeconds"] = "sponsorship__minimumStakingPeriodSeconds";
    Flag_OrderBy["SponsorshipOperatorCount"] = "sponsorship__operatorCount";
    Flag_OrderBy["SponsorshipProjectedInsolvency"] = "sponsorship__projectedInsolvency";
    Flag_OrderBy["SponsorshipRemainingWei"] = "sponsorship__remainingWei";
    Flag_OrderBy["SponsorshipRemainingWeiUpdateTimestamp"] = "sponsorship__remainingWeiUpdateTimestamp";
    Flag_OrderBy["SponsorshipSpotApy"] = "sponsorship__spotAPY";
    Flag_OrderBy["SponsorshipTotalPayoutWeiPerSec"] = "sponsorship__totalPayoutWeiPerSec";
    Flag_OrderBy["SponsorshipTotalStakedWei"] = "sponsorship__totalStakedWei";
    Flag_OrderBy["Target"] = "target";
    Flag_OrderBy["TargetStakeAtRiskWei"] = "targetStakeAtRiskWei";
    Flag_OrderBy["TargetContractVersion"] = "target__contractVersion";
    Flag_OrderBy["TargetCumulativeEarningsWei"] = "target__cumulativeEarningsWei";
    Flag_OrderBy["TargetCumulativeOperatorsCutWei"] = "target__cumulativeOperatorsCutWei";
    Flag_OrderBy["TargetCumulativeProfitsWei"] = "target__cumulativeProfitsWei";
    Flag_OrderBy["TargetDataTokenBalanceWei"] = "target__dataTokenBalanceWei";
    Flag_OrderBy["TargetDelegatorCount"] = "target__delegatorCount";
    Flag_OrderBy["TargetExchangeRate"] = "target__exchangeRate";
    Flag_OrderBy["TargetId"] = "target__id";
    Flag_OrderBy["TargetIsEligibleToVote"] = "target__isEligibleToVote";
    Flag_OrderBy["TargetLatestHeartbeatMetadata"] = "target__latestHeartbeatMetadata";
    Flag_OrderBy["TargetLatestHeartbeatTimestamp"] = "target__latestHeartbeatTimestamp";
    Flag_OrderBy["TargetMetadataJsonString"] = "target__metadataJsonString";
    Flag_OrderBy["TargetOperatorTokenTotalSupplyWei"] = "target__operatorTokenTotalSupplyWei";
    Flag_OrderBy["TargetOperatorsCutFraction"] = "target__operatorsCutFraction";
    Flag_OrderBy["TargetOwner"] = "target__owner";
    Flag_OrderBy["TargetProtectionEndTimestamp"] = "target__protectionEndTimestamp";
    Flag_OrderBy["TargetSlashingsCount"] = "target__slashingsCount";
    Flag_OrderBy["TargetTotalStakeInSponsorshipsWei"] = "target__totalStakeInSponsorshipsWei";
    Flag_OrderBy["TargetValueUpdateBlockNumber"] = "target__valueUpdateBlockNumber";
    Flag_OrderBy["TargetValueUpdateTimestamp"] = "target__valueUpdateTimestamp";
    Flag_OrderBy["TargetValueWithoutEarnings"] = "target__valueWithoutEarnings";
    Flag_OrderBy["VoteEndTimestamp"] = "voteEndTimestamp";
    Flag_OrderBy["VoteStartTimestamp"] = "voteStartTimestamp";
    Flag_OrderBy["Votes"] = "votes";
    Flag_OrderBy["VotesAgainstKick"] = "votesAgainstKick";
    Flag_OrderBy["VotesForKick"] = "votesForKick";
})(Flag_OrderBy || (Flag_OrderBy = {}));
export var Network_OrderBy;
(function (Network_OrderBy) {
    Network_OrderBy["EarlyLeaverPenaltyWei"] = "earlyLeaverPenaltyWei";
    Network_OrderBy["EligibleVotersCount"] = "eligibleVotersCount";
    Network_OrderBy["FishermanRewardFraction"] = "fishermanRewardFraction";
    Network_OrderBy["FlagProtectionSeconds"] = "flagProtectionSeconds";
    Network_OrderBy["FlagReviewerCount"] = "flagReviewerCount";
    Network_OrderBy["FlagReviewerRewardWei"] = "flagReviewerRewardWei";
    Network_OrderBy["FlagReviewerSelectionIterations"] = "flagReviewerSelectionIterations";
    Network_OrderBy["FlagStakeWei"] = "flagStakeWei";
    Network_OrderBy["FlaggerRewardWei"] = "flaggerRewardWei";
    Network_OrderBy["FundedSponsorshipsCount"] = "fundedSponsorshipsCount";
    Network_OrderBy["Id"] = "id";
    Network_OrderBy["MaxAllowedEarningsFraction"] = "maxAllowedEarningsFraction";
    Network_OrderBy["MaxPenaltyPeriodSeconds"] = "maxPenaltyPeriodSeconds";
    Network_OrderBy["MaxQueueSeconds"] = "maxQueueSeconds";
    Network_OrderBy["MinEligibleVoterAge"] = "minEligibleVoterAge";
    Network_OrderBy["MinEligibleVoterFractionOfAllStake"] = "minEligibleVoterFractionOfAllStake";
    Network_OrderBy["MinimumDelegationSeconds"] = "minimumDelegationSeconds";
    Network_OrderBy["MinimumDelegationWei"] = "minimumDelegationWei";
    Network_OrderBy["MinimumSelfDelegationFraction"] = "minimumSelfDelegationFraction";
    Network_OrderBy["MinimumStakeWei"] = "minimumStakeWei";
    Network_OrderBy["OperatorContractOnlyJoinPolicy"] = "operatorContractOnlyJoinPolicy";
    Network_OrderBy["OperatorFactory"] = "operatorFactory";
    Network_OrderBy["OperatorsCount"] = "operatorsCount";
    Network_OrderBy["ProtocolFeeBeneficiary"] = "protocolFeeBeneficiary";
    Network_OrderBy["ProtocolFeeFraction"] = "protocolFeeFraction";
    Network_OrderBy["RandomOracle"] = "randomOracle";
    Network_OrderBy["ReviewPeriodSeconds"] = "reviewPeriodSeconds";
    Network_OrderBy["SlashingFraction"] = "slashingFraction";
    Network_OrderBy["SponsorshipFactory"] = "sponsorshipFactory";
    Network_OrderBy["SponsorshipsCount"] = "sponsorshipsCount";
    Network_OrderBy["StreamRegistryAddress"] = "streamRegistryAddress";
    Network_OrderBy["TotalDelegated"] = "totalDelegated";
    Network_OrderBy["TotalStake"] = "totalStake";
    Network_OrderBy["TotalUndelegated"] = "totalUndelegated";
    Network_OrderBy["TrustedForwarder"] = "trustedForwarder";
    Network_OrderBy["VoterRegistry"] = "voterRegistry";
    Network_OrderBy["VotingPeriodSeconds"] = "votingPeriodSeconds";
})(Network_OrderBy || (Network_OrderBy = {}));
export var Node_OrderBy;
(function (Node_OrderBy) {
    Node_OrderBy["CreatedAt"] = "createdAt";
    Node_OrderBy["Id"] = "id";
    Node_OrderBy["LastSeen"] = "lastSeen";
    Node_OrderBy["Metadata"] = "metadata";
    Node_OrderBy["StoredStreams"] = "storedStreams";
})(Node_OrderBy || (Node_OrderBy = {}));
export var OperatorDailyBucket_OrderBy;
(function (OperatorDailyBucket_OrderBy) {
    OperatorDailyBucket_OrderBy["CumulativeEarningsWei"] = "cumulativeEarningsWei";
    OperatorDailyBucket_OrderBy["DataTokenBalanceWei"] = "dataTokenBalanceWei";
    OperatorDailyBucket_OrderBy["Date"] = "date";
    OperatorDailyBucket_OrderBy["DelegatorCountAtStart"] = "delegatorCountAtStart";
    OperatorDailyBucket_OrderBy["DelegatorCountChange"] = "delegatorCountChange";
    OperatorDailyBucket_OrderBy["Id"] = "id";
    OperatorDailyBucket_OrderBy["LossesWei"] = "lossesWei";
    OperatorDailyBucket_OrderBy["Operator"] = "operator";
    OperatorDailyBucket_OrderBy["OperatorContractVersion"] = "operator__contractVersion";
    OperatorDailyBucket_OrderBy["OperatorCumulativeEarningsWei"] = "operator__cumulativeEarningsWei";
    OperatorDailyBucket_OrderBy["OperatorCumulativeOperatorsCutWei"] = "operator__cumulativeOperatorsCutWei";
    OperatorDailyBucket_OrderBy["OperatorCumulativeProfitsWei"] = "operator__cumulativeProfitsWei";
    OperatorDailyBucket_OrderBy["OperatorDataTokenBalanceWei"] = "operator__dataTokenBalanceWei";
    OperatorDailyBucket_OrderBy["OperatorDelegatorCount"] = "operator__delegatorCount";
    OperatorDailyBucket_OrderBy["OperatorExchangeRate"] = "operator__exchangeRate";
    OperatorDailyBucket_OrderBy["OperatorId"] = "operator__id";
    OperatorDailyBucket_OrderBy["OperatorIsEligibleToVote"] = "operator__isEligibleToVote";
    OperatorDailyBucket_OrderBy["OperatorLatestHeartbeatMetadata"] = "operator__latestHeartbeatMetadata";
    OperatorDailyBucket_OrderBy["OperatorLatestHeartbeatTimestamp"] = "operator__latestHeartbeatTimestamp";
    OperatorDailyBucket_OrderBy["OperatorMetadataJsonString"] = "operator__metadataJsonString";
    OperatorDailyBucket_OrderBy["OperatorOperatorTokenTotalSupplyWei"] = "operator__operatorTokenTotalSupplyWei";
    OperatorDailyBucket_OrderBy["OperatorOperatorsCutFraction"] = "operator__operatorsCutFraction";
    OperatorDailyBucket_OrderBy["OperatorOwner"] = "operator__owner";
    OperatorDailyBucket_OrderBy["OperatorProtectionEndTimestamp"] = "operator__protectionEndTimestamp";
    OperatorDailyBucket_OrderBy["OperatorSlashingsCount"] = "operator__slashingsCount";
    OperatorDailyBucket_OrderBy["OperatorTotalStakeInSponsorshipsWei"] = "operator__totalStakeInSponsorshipsWei";
    OperatorDailyBucket_OrderBy["OperatorValueUpdateBlockNumber"] = "operator__valueUpdateBlockNumber";
    OperatorDailyBucket_OrderBy["OperatorValueUpdateTimestamp"] = "operator__valueUpdateTimestamp";
    OperatorDailyBucket_OrderBy["OperatorValueWithoutEarnings"] = "operator__valueWithoutEarnings";
    OperatorDailyBucket_OrderBy["OperatorsCutWei"] = "operatorsCutWei";
    OperatorDailyBucket_OrderBy["ProfitsWei"] = "profitsWei";
    OperatorDailyBucket_OrderBy["TotalDelegatedWei"] = "totalDelegatedWei";
    OperatorDailyBucket_OrderBy["TotalStakeInSponsorshipsWei"] = "totalStakeInSponsorshipsWei";
    OperatorDailyBucket_OrderBy["TotalUndelegatedWei"] = "totalUndelegatedWei";
    OperatorDailyBucket_OrderBy["ValueWithoutEarnings"] = "valueWithoutEarnings";
})(OperatorDailyBucket_OrderBy || (OperatorDailyBucket_OrderBy = {}));
export var Operator_OrderBy;
(function (Operator_OrderBy) {
    Operator_OrderBy["ContractVersion"] = "contractVersion";
    Operator_OrderBy["Controllers"] = "controllers";
    Operator_OrderBy["CumulativeEarningsWei"] = "cumulativeEarningsWei";
    Operator_OrderBy["CumulativeOperatorsCutWei"] = "cumulativeOperatorsCutWei";
    Operator_OrderBy["CumulativeProfitsWei"] = "cumulativeProfitsWei";
    Operator_OrderBy["DataTokenBalanceWei"] = "dataTokenBalanceWei";
    Operator_OrderBy["Delegations"] = "delegations";
    Operator_OrderBy["DelegatorCount"] = "delegatorCount";
    Operator_OrderBy["ExchangeRate"] = "exchangeRate";
    Operator_OrderBy["FlagsOpened"] = "flagsOpened";
    Operator_OrderBy["FlagsTargeted"] = "flagsTargeted";
    Operator_OrderBy["Id"] = "id";
    Operator_OrderBy["IsEligibleToVote"] = "isEligibleToVote";
    Operator_OrderBy["LatestHeartbeatMetadata"] = "latestHeartbeatMetadata";
    Operator_OrderBy["LatestHeartbeatTimestamp"] = "latestHeartbeatTimestamp";
    Operator_OrderBy["MetadataJsonString"] = "metadataJsonString";
    Operator_OrderBy["Nodes"] = "nodes";
    Operator_OrderBy["OperatorTokenTotalSupplyWei"] = "operatorTokenTotalSupplyWei";
    Operator_OrderBy["OperatorsCutFraction"] = "operatorsCutFraction";
    Operator_OrderBy["Owner"] = "owner";
    Operator_OrderBy["ProtectionEndTimestamp"] = "protectionEndTimestamp";
    Operator_OrderBy["QueueEntries"] = "queueEntries";
    Operator_OrderBy["RaisedFlags"] = "raisedFlags";
    Operator_OrderBy["ReceivedFlags"] = "receivedFlags";
    Operator_OrderBy["SlashingEvents"] = "slashingEvents";
    Operator_OrderBy["SlashingsCount"] = "slashingsCount";
    Operator_OrderBy["Stakes"] = "stakes";
    Operator_OrderBy["StakingEvents"] = "stakingEvents";
    Operator_OrderBy["TotalStakeInSponsorshipsWei"] = "totalStakeInSponsorshipsWei";
    Operator_OrderBy["ValueUpdateBlockNumber"] = "valueUpdateBlockNumber";
    Operator_OrderBy["ValueUpdateTimestamp"] = "valueUpdateTimestamp";
    Operator_OrderBy["ValueWithoutEarnings"] = "valueWithoutEarnings";
    Operator_OrderBy["VotesOnFlags"] = "votesOnFlags";
})(Operator_OrderBy || (Operator_OrderBy = {}));
/** Defines the order direction, either ascending or descending */
export var OrderDirection;
(function (OrderDirection) {
    OrderDirection["Asc"] = "asc";
    OrderDirection["Desc"] = "desc";
})(OrderDirection || (OrderDirection = {}));
export var ProjectPaymentDetails_OrderBy;
(function (ProjectPaymentDetails_OrderBy) {
    ProjectPaymentDetails_OrderBy["Beneficiary"] = "beneficiary";
    ProjectPaymentDetails_OrderBy["DomainId"] = "domainId";
    ProjectPaymentDetails_OrderBy["Id"] = "id";
    ProjectPaymentDetails_OrderBy["PricePerSecond"] = "pricePerSecond";
    ProjectPaymentDetails_OrderBy["PricingTokenAddress"] = "pricingTokenAddress";
    ProjectPaymentDetails_OrderBy["Project"] = "project";
    ProjectPaymentDetails_OrderBy["ProjectCounter"] = "project__counter";
    ProjectPaymentDetails_OrderBy["ProjectCreatedAt"] = "project__createdAt";
    ProjectPaymentDetails_OrderBy["ProjectId"] = "project__id";
    ProjectPaymentDetails_OrderBy["ProjectIsDataUnion"] = "project__isDataUnion";
    ProjectPaymentDetails_OrderBy["ProjectMetadata"] = "project__metadata";
    ProjectPaymentDetails_OrderBy["ProjectMinimumSubscriptionSeconds"] = "project__minimumSubscriptionSeconds";
    ProjectPaymentDetails_OrderBy["ProjectScore"] = "project__score";
    ProjectPaymentDetails_OrderBy["ProjectStakedWei"] = "project__stakedWei";
    ProjectPaymentDetails_OrderBy["ProjectUpdatedAt"] = "project__updatedAt";
})(ProjectPaymentDetails_OrderBy || (ProjectPaymentDetails_OrderBy = {}));
export var ProjectPermission_OrderBy;
(function (ProjectPermission_OrderBy) {
    ProjectPermission_OrderBy["CanBuy"] = "canBuy";
    ProjectPermission_OrderBy["CanDelete"] = "canDelete";
    ProjectPermission_OrderBy["CanEdit"] = "canEdit";
    ProjectPermission_OrderBy["CanGrant"] = "canGrant";
    ProjectPermission_OrderBy["Id"] = "id";
    ProjectPermission_OrderBy["Project"] = "project";
    ProjectPermission_OrderBy["ProjectCounter"] = "project__counter";
    ProjectPermission_OrderBy["ProjectCreatedAt"] = "project__createdAt";
    ProjectPermission_OrderBy["ProjectId"] = "project__id";
    ProjectPermission_OrderBy["ProjectIsDataUnion"] = "project__isDataUnion";
    ProjectPermission_OrderBy["ProjectMetadata"] = "project__metadata";
    ProjectPermission_OrderBy["ProjectMinimumSubscriptionSeconds"] = "project__minimumSubscriptionSeconds";
    ProjectPermission_OrderBy["ProjectScore"] = "project__score";
    ProjectPermission_OrderBy["ProjectStakedWei"] = "project__stakedWei";
    ProjectPermission_OrderBy["ProjectUpdatedAt"] = "project__updatedAt";
    ProjectPermission_OrderBy["UserAddress"] = "userAddress";
})(ProjectPermission_OrderBy || (ProjectPermission_OrderBy = {}));
export var ProjectPurchase_OrderBy;
(function (ProjectPurchase_OrderBy) {
    ProjectPurchase_OrderBy["Fee"] = "fee";
    ProjectPurchase_OrderBy["Id"] = "id";
    ProjectPurchase_OrderBy["Price"] = "price";
    ProjectPurchase_OrderBy["Project"] = "project";
    ProjectPurchase_OrderBy["ProjectCounter"] = "project__counter";
    ProjectPurchase_OrderBy["ProjectCreatedAt"] = "project__createdAt";
    ProjectPurchase_OrderBy["ProjectId"] = "project__id";
    ProjectPurchase_OrderBy["ProjectIsDataUnion"] = "project__isDataUnion";
    ProjectPurchase_OrderBy["ProjectMetadata"] = "project__metadata";
    ProjectPurchase_OrderBy["ProjectMinimumSubscriptionSeconds"] = "project__minimumSubscriptionSeconds";
    ProjectPurchase_OrderBy["ProjectScore"] = "project__score";
    ProjectPurchase_OrderBy["ProjectStakedWei"] = "project__stakedWei";
    ProjectPurchase_OrderBy["ProjectUpdatedAt"] = "project__updatedAt";
    ProjectPurchase_OrderBy["PurchasedAt"] = "purchasedAt";
    ProjectPurchase_OrderBy["Subscriber"] = "subscriber";
    ProjectPurchase_OrderBy["SubscriptionSeconds"] = "subscriptionSeconds";
})(ProjectPurchase_OrderBy || (ProjectPurchase_OrderBy = {}));
export var ProjectStakeByUser_OrderBy;
(function (ProjectStakeByUser_OrderBy) {
    ProjectStakeByUser_OrderBy["Id"] = "id";
    ProjectStakeByUser_OrderBy["Project"] = "project";
    ProjectStakeByUser_OrderBy["ProjectCounter"] = "project__counter";
    ProjectStakeByUser_OrderBy["ProjectCreatedAt"] = "project__createdAt";
    ProjectStakeByUser_OrderBy["ProjectId"] = "project__id";
    ProjectStakeByUser_OrderBy["ProjectIsDataUnion"] = "project__isDataUnion";
    ProjectStakeByUser_OrderBy["ProjectMetadata"] = "project__metadata";
    ProjectStakeByUser_OrderBy["ProjectMinimumSubscriptionSeconds"] = "project__minimumSubscriptionSeconds";
    ProjectStakeByUser_OrderBy["ProjectScore"] = "project__score";
    ProjectStakeByUser_OrderBy["ProjectStakedWei"] = "project__stakedWei";
    ProjectStakeByUser_OrderBy["ProjectUpdatedAt"] = "project__updatedAt";
    ProjectStakeByUser_OrderBy["User"] = "user";
    ProjectStakeByUser_OrderBy["UserStake"] = "userStake";
})(ProjectStakeByUser_OrderBy || (ProjectStakeByUser_OrderBy = {}));
export var ProjectStakingDayBucket_OrderBy;
(function (ProjectStakingDayBucket_OrderBy) {
    ProjectStakingDayBucket_OrderBy["Date"] = "date";
    ProjectStakingDayBucket_OrderBy["Id"] = "id";
    ProjectStakingDayBucket_OrderBy["Project"] = "project";
    ProjectStakingDayBucket_OrderBy["ProjectCounter"] = "project__counter";
    ProjectStakingDayBucket_OrderBy["ProjectCreatedAt"] = "project__createdAt";
    ProjectStakingDayBucket_OrderBy["ProjectId"] = "project__id";
    ProjectStakingDayBucket_OrderBy["ProjectIsDataUnion"] = "project__isDataUnion";
    ProjectStakingDayBucket_OrderBy["ProjectMetadata"] = "project__metadata";
    ProjectStakingDayBucket_OrderBy["ProjectMinimumSubscriptionSeconds"] = "project__minimumSubscriptionSeconds";
    ProjectStakingDayBucket_OrderBy["ProjectScore"] = "project__score";
    ProjectStakingDayBucket_OrderBy["ProjectStakedWei"] = "project__stakedWei";
    ProjectStakingDayBucket_OrderBy["ProjectUpdatedAt"] = "project__updatedAt";
    ProjectStakingDayBucket_OrderBy["StakeAtStart"] = "stakeAtStart";
    ProjectStakingDayBucket_OrderBy["StakeChange"] = "stakeChange";
    ProjectStakingDayBucket_OrderBy["StakingsWei"] = "stakingsWei";
    ProjectStakingDayBucket_OrderBy["UnstakingsWei"] = "unstakingsWei";
})(ProjectStakingDayBucket_OrderBy || (ProjectStakingDayBucket_OrderBy = {}));
export var ProjectSubscription_OrderBy;
(function (ProjectSubscription_OrderBy) {
    ProjectSubscription_OrderBy["EndTimestamp"] = "endTimestamp";
    ProjectSubscription_OrderBy["Id"] = "id";
    ProjectSubscription_OrderBy["Project"] = "project";
    ProjectSubscription_OrderBy["ProjectCounter"] = "project__counter";
    ProjectSubscription_OrderBy["ProjectCreatedAt"] = "project__createdAt";
    ProjectSubscription_OrderBy["ProjectId"] = "project__id";
    ProjectSubscription_OrderBy["ProjectIsDataUnion"] = "project__isDataUnion";
    ProjectSubscription_OrderBy["ProjectMetadata"] = "project__metadata";
    ProjectSubscription_OrderBy["ProjectMinimumSubscriptionSeconds"] = "project__minimumSubscriptionSeconds";
    ProjectSubscription_OrderBy["ProjectScore"] = "project__score";
    ProjectSubscription_OrderBy["ProjectStakedWei"] = "project__stakedWei";
    ProjectSubscription_OrderBy["ProjectUpdatedAt"] = "project__updatedAt";
    ProjectSubscription_OrderBy["UserAddress"] = "userAddress";
})(ProjectSubscription_OrderBy || (ProjectSubscription_OrderBy = {}));
export var Project_OrderBy;
(function (Project_OrderBy) {
    Project_OrderBy["Counter"] = "counter";
    Project_OrderBy["CreatedAt"] = "createdAt";
    Project_OrderBy["DomainIds"] = "domainIds";
    Project_OrderBy["Id"] = "id";
    Project_OrderBy["IsDataUnion"] = "isDataUnion";
    Project_OrderBy["Metadata"] = "metadata";
    Project_OrderBy["MinimumSubscriptionSeconds"] = "minimumSubscriptionSeconds";
    Project_OrderBy["PaymentDetails"] = "paymentDetails";
    Project_OrderBy["Permissions"] = "permissions";
    Project_OrderBy["Purchases"] = "purchases";
    Project_OrderBy["Score"] = "score";
    Project_OrderBy["StakedWei"] = "stakedWei";
    Project_OrderBy["Streams"] = "streams";
    Project_OrderBy["Subscriptions"] = "subscriptions";
    Project_OrderBy["UpdatedAt"] = "updatedAt";
})(Project_OrderBy || (Project_OrderBy = {}));
export var QueueEntry_OrderBy;
(function (QueueEntry_OrderBy) {
    QueueEntry_OrderBy["Amount"] = "amount";
    QueueEntry_OrderBy["Date"] = "date";
    QueueEntry_OrderBy["Delegator"] = "delegator";
    QueueEntry_OrderBy["DelegatorCumulativeEarningsWei"] = "delegator__cumulativeEarningsWei";
    QueueEntry_OrderBy["DelegatorId"] = "delegator__id";
    QueueEntry_OrderBy["DelegatorNumberOfDelegations"] = "delegator__numberOfDelegations";
    QueueEntry_OrderBy["DelegatorTotalValueDataWei"] = "delegator__totalValueDataWei";
    QueueEntry_OrderBy["Id"] = "id";
    QueueEntry_OrderBy["Operator"] = "operator";
    QueueEntry_OrderBy["OperatorContractVersion"] = "operator__contractVersion";
    QueueEntry_OrderBy["OperatorCumulativeEarningsWei"] = "operator__cumulativeEarningsWei";
    QueueEntry_OrderBy["OperatorCumulativeOperatorsCutWei"] = "operator__cumulativeOperatorsCutWei";
    QueueEntry_OrderBy["OperatorCumulativeProfitsWei"] = "operator__cumulativeProfitsWei";
    QueueEntry_OrderBy["OperatorDataTokenBalanceWei"] = "operator__dataTokenBalanceWei";
    QueueEntry_OrderBy["OperatorDelegatorCount"] = "operator__delegatorCount";
    QueueEntry_OrderBy["OperatorExchangeRate"] = "operator__exchangeRate";
    QueueEntry_OrderBy["OperatorId"] = "operator__id";
    QueueEntry_OrderBy["OperatorIsEligibleToVote"] = "operator__isEligibleToVote";
    QueueEntry_OrderBy["OperatorLatestHeartbeatMetadata"] = "operator__latestHeartbeatMetadata";
    QueueEntry_OrderBy["OperatorLatestHeartbeatTimestamp"] = "operator__latestHeartbeatTimestamp";
    QueueEntry_OrderBy["OperatorMetadataJsonString"] = "operator__metadataJsonString";
    QueueEntry_OrderBy["OperatorOperatorTokenTotalSupplyWei"] = "operator__operatorTokenTotalSupplyWei";
    QueueEntry_OrderBy["OperatorOperatorsCutFraction"] = "operator__operatorsCutFraction";
    QueueEntry_OrderBy["OperatorOwner"] = "operator__owner";
    QueueEntry_OrderBy["OperatorProtectionEndTimestamp"] = "operator__protectionEndTimestamp";
    QueueEntry_OrderBy["OperatorSlashingsCount"] = "operator__slashingsCount";
    QueueEntry_OrderBy["OperatorTotalStakeInSponsorshipsWei"] = "operator__totalStakeInSponsorshipsWei";
    QueueEntry_OrderBy["OperatorValueUpdateBlockNumber"] = "operator__valueUpdateBlockNumber";
    QueueEntry_OrderBy["OperatorValueUpdateTimestamp"] = "operator__valueUpdateTimestamp";
    QueueEntry_OrderBy["OperatorValueWithoutEarnings"] = "operator__valueWithoutEarnings";
})(QueueEntry_OrderBy || (QueueEntry_OrderBy = {}));
export var SlashingEvent_OrderBy;
(function (SlashingEvent_OrderBy) {
    SlashingEvent_OrderBy["Amount"] = "amount";
    SlashingEvent_OrderBy["Date"] = "date";
    SlashingEvent_OrderBy["Id"] = "id";
    SlashingEvent_OrderBy["Operator"] = "operator";
    SlashingEvent_OrderBy["OperatorContractVersion"] = "operator__contractVersion";
    SlashingEvent_OrderBy["OperatorCumulativeEarningsWei"] = "operator__cumulativeEarningsWei";
    SlashingEvent_OrderBy["OperatorCumulativeOperatorsCutWei"] = "operator__cumulativeOperatorsCutWei";
    SlashingEvent_OrderBy["OperatorCumulativeProfitsWei"] = "operator__cumulativeProfitsWei";
    SlashingEvent_OrderBy["OperatorDataTokenBalanceWei"] = "operator__dataTokenBalanceWei";
    SlashingEvent_OrderBy["OperatorDelegatorCount"] = "operator__delegatorCount";
    SlashingEvent_OrderBy["OperatorExchangeRate"] = "operator__exchangeRate";
    SlashingEvent_OrderBy["OperatorId"] = "operator__id";
    SlashingEvent_OrderBy["OperatorIsEligibleToVote"] = "operator__isEligibleToVote";
    SlashingEvent_OrderBy["OperatorLatestHeartbeatMetadata"] = "operator__latestHeartbeatMetadata";
    SlashingEvent_OrderBy["OperatorLatestHeartbeatTimestamp"] = "operator__latestHeartbeatTimestamp";
    SlashingEvent_OrderBy["OperatorMetadataJsonString"] = "operator__metadataJsonString";
    SlashingEvent_OrderBy["OperatorOperatorTokenTotalSupplyWei"] = "operator__operatorTokenTotalSupplyWei";
    SlashingEvent_OrderBy["OperatorOperatorsCutFraction"] = "operator__operatorsCutFraction";
    SlashingEvent_OrderBy["OperatorOwner"] = "operator__owner";
    SlashingEvent_OrderBy["OperatorProtectionEndTimestamp"] = "operator__protectionEndTimestamp";
    SlashingEvent_OrderBy["OperatorSlashingsCount"] = "operator__slashingsCount";
    SlashingEvent_OrderBy["OperatorTotalStakeInSponsorshipsWei"] = "operator__totalStakeInSponsorshipsWei";
    SlashingEvent_OrderBy["OperatorValueUpdateBlockNumber"] = "operator__valueUpdateBlockNumber";
    SlashingEvent_OrderBy["OperatorValueUpdateTimestamp"] = "operator__valueUpdateTimestamp";
    SlashingEvent_OrderBy["OperatorValueWithoutEarnings"] = "operator__valueWithoutEarnings";
    SlashingEvent_OrderBy["Sponsorship"] = "sponsorship";
    SlashingEvent_OrderBy["SponsorshipCreator"] = "sponsorship__creator";
    SlashingEvent_OrderBy["SponsorshipCumulativeSponsoring"] = "sponsorship__cumulativeSponsoring";
    SlashingEvent_OrderBy["SponsorshipId"] = "sponsorship__id";
    SlashingEvent_OrderBy["SponsorshipIsRunning"] = "sponsorship__isRunning";
    SlashingEvent_OrderBy["SponsorshipMaxOperators"] = "sponsorship__maxOperators";
    SlashingEvent_OrderBy["SponsorshipMetadata"] = "sponsorship__metadata";
    SlashingEvent_OrderBy["SponsorshipMinOperators"] = "sponsorship__minOperators";
    SlashingEvent_OrderBy["SponsorshipMinimumStakingPeriodSeconds"] = "sponsorship__minimumStakingPeriodSeconds";
    SlashingEvent_OrderBy["SponsorshipOperatorCount"] = "sponsorship__operatorCount";
    SlashingEvent_OrderBy["SponsorshipProjectedInsolvency"] = "sponsorship__projectedInsolvency";
    SlashingEvent_OrderBy["SponsorshipRemainingWei"] = "sponsorship__remainingWei";
    SlashingEvent_OrderBy["SponsorshipRemainingWeiUpdateTimestamp"] = "sponsorship__remainingWeiUpdateTimestamp";
    SlashingEvent_OrderBy["SponsorshipSpotApy"] = "sponsorship__spotAPY";
    SlashingEvent_OrderBy["SponsorshipTotalPayoutWeiPerSec"] = "sponsorship__totalPayoutWeiPerSec";
    SlashingEvent_OrderBy["SponsorshipTotalStakedWei"] = "sponsorship__totalStakedWei";
})(SlashingEvent_OrderBy || (SlashingEvent_OrderBy = {}));
export var SponsoringEvent_OrderBy;
(function (SponsoringEvent_OrderBy) {
    SponsoringEvent_OrderBy["Amount"] = "amount";
    SponsoringEvent_OrderBy["Date"] = "date";
    SponsoringEvent_OrderBy["Id"] = "id";
    SponsoringEvent_OrderBy["Sponsor"] = "sponsor";
    SponsoringEvent_OrderBy["Sponsorship"] = "sponsorship";
    SponsoringEvent_OrderBy["SponsorshipCreator"] = "sponsorship__creator";
    SponsoringEvent_OrderBy["SponsorshipCumulativeSponsoring"] = "sponsorship__cumulativeSponsoring";
    SponsoringEvent_OrderBy["SponsorshipId"] = "sponsorship__id";
    SponsoringEvent_OrderBy["SponsorshipIsRunning"] = "sponsorship__isRunning";
    SponsoringEvent_OrderBy["SponsorshipMaxOperators"] = "sponsorship__maxOperators";
    SponsoringEvent_OrderBy["SponsorshipMetadata"] = "sponsorship__metadata";
    SponsoringEvent_OrderBy["SponsorshipMinOperators"] = "sponsorship__minOperators";
    SponsoringEvent_OrderBy["SponsorshipMinimumStakingPeriodSeconds"] = "sponsorship__minimumStakingPeriodSeconds";
    SponsoringEvent_OrderBy["SponsorshipOperatorCount"] = "sponsorship__operatorCount";
    SponsoringEvent_OrderBy["SponsorshipProjectedInsolvency"] = "sponsorship__projectedInsolvency";
    SponsoringEvent_OrderBy["SponsorshipRemainingWei"] = "sponsorship__remainingWei";
    SponsoringEvent_OrderBy["SponsorshipRemainingWeiUpdateTimestamp"] = "sponsorship__remainingWeiUpdateTimestamp";
    SponsoringEvent_OrderBy["SponsorshipSpotApy"] = "sponsorship__spotAPY";
    SponsoringEvent_OrderBy["SponsorshipTotalPayoutWeiPerSec"] = "sponsorship__totalPayoutWeiPerSec";
    SponsoringEvent_OrderBy["SponsorshipTotalStakedWei"] = "sponsorship__totalStakedWei";
})(SponsoringEvent_OrderBy || (SponsoringEvent_OrderBy = {}));
export var SponsorshipDailyBucket_OrderBy;
(function (SponsorshipDailyBucket_OrderBy) {
    SponsorshipDailyBucket_OrderBy["Date"] = "date";
    SponsorshipDailyBucket_OrderBy["Id"] = "id";
    SponsorshipDailyBucket_OrderBy["OperatorCount"] = "operatorCount";
    SponsorshipDailyBucket_OrderBy["ProjectedInsolvency"] = "projectedInsolvency";
    SponsorshipDailyBucket_OrderBy["RemainingWei"] = "remainingWei";
    SponsorshipDailyBucket_OrderBy["Sponsorship"] = "sponsorship";
    SponsorshipDailyBucket_OrderBy["SponsorshipCreator"] = "sponsorship__creator";
    SponsorshipDailyBucket_OrderBy["SponsorshipCumulativeSponsoring"] = "sponsorship__cumulativeSponsoring";
    SponsorshipDailyBucket_OrderBy["SponsorshipId"] = "sponsorship__id";
    SponsorshipDailyBucket_OrderBy["SponsorshipIsRunning"] = "sponsorship__isRunning";
    SponsorshipDailyBucket_OrderBy["SponsorshipMaxOperators"] = "sponsorship__maxOperators";
    SponsorshipDailyBucket_OrderBy["SponsorshipMetadata"] = "sponsorship__metadata";
    SponsorshipDailyBucket_OrderBy["SponsorshipMinOperators"] = "sponsorship__minOperators";
    SponsorshipDailyBucket_OrderBy["SponsorshipMinimumStakingPeriodSeconds"] = "sponsorship__minimumStakingPeriodSeconds";
    SponsorshipDailyBucket_OrderBy["SponsorshipOperatorCount"] = "sponsorship__operatorCount";
    SponsorshipDailyBucket_OrderBy["SponsorshipProjectedInsolvency"] = "sponsorship__projectedInsolvency";
    SponsorshipDailyBucket_OrderBy["SponsorshipRemainingWei"] = "sponsorship__remainingWei";
    SponsorshipDailyBucket_OrderBy["SponsorshipRemainingWeiUpdateTimestamp"] = "sponsorship__remainingWeiUpdateTimestamp";
    SponsorshipDailyBucket_OrderBy["SponsorshipSpotApy"] = "sponsorship__spotAPY";
    SponsorshipDailyBucket_OrderBy["SponsorshipTotalPayoutWeiPerSec"] = "sponsorship__totalPayoutWeiPerSec";
    SponsorshipDailyBucket_OrderBy["SponsorshipTotalStakedWei"] = "sponsorship__totalStakedWei";
    SponsorshipDailyBucket_OrderBy["SpotApy"] = "spotAPY";
    SponsorshipDailyBucket_OrderBy["TotalStakedWei"] = "totalStakedWei";
})(SponsorshipDailyBucket_OrderBy || (SponsorshipDailyBucket_OrderBy = {}));
export var Sponsorship_OrderBy;
(function (Sponsorship_OrderBy) {
    Sponsorship_OrderBy["Creator"] = "creator";
    Sponsorship_OrderBy["CumulativeSponsoring"] = "cumulativeSponsoring";
    Sponsorship_OrderBy["Flags"] = "flags";
    Sponsorship_OrderBy["Id"] = "id";
    Sponsorship_OrderBy["IsRunning"] = "isRunning";
    Sponsorship_OrderBy["MaxOperators"] = "maxOperators";
    Sponsorship_OrderBy["Metadata"] = "metadata";
    Sponsorship_OrderBy["MinOperators"] = "minOperators";
    Sponsorship_OrderBy["MinimumStakingPeriodSeconds"] = "minimumStakingPeriodSeconds";
    Sponsorship_OrderBy["OperatorCount"] = "operatorCount";
    Sponsorship_OrderBy["ProjectedInsolvency"] = "projectedInsolvency";
    Sponsorship_OrderBy["RemainingWei"] = "remainingWei";
    Sponsorship_OrderBy["RemainingWeiUpdateTimestamp"] = "remainingWeiUpdateTimestamp";
    Sponsorship_OrderBy["SlashingEvents"] = "slashingEvents";
    Sponsorship_OrderBy["SponsoringEvents"] = "sponsoringEvents";
    Sponsorship_OrderBy["SpotApy"] = "spotAPY";
    Sponsorship_OrderBy["Stakes"] = "stakes";
    Sponsorship_OrderBy["StakingEvents"] = "stakingEvents";
    Sponsorship_OrderBy["Stream"] = "stream";
    Sponsorship_OrderBy["StreamCreatedAt"] = "stream__createdAt";
    Sponsorship_OrderBy["StreamId"] = "stream__id";
    Sponsorship_OrderBy["StreamMetadata"] = "stream__metadata";
    Sponsorship_OrderBy["StreamUpdatedAt"] = "stream__updatedAt";
    Sponsorship_OrderBy["TotalPayoutWeiPerSec"] = "totalPayoutWeiPerSec";
    Sponsorship_OrderBy["TotalStakedWei"] = "totalStakedWei";
})(Sponsorship_OrderBy || (Sponsorship_OrderBy = {}));
export var Stake_OrderBy;
(function (Stake_OrderBy) {
    Stake_OrderBy["AmountWei"] = "amountWei";
    Stake_OrderBy["EarningsWei"] = "earningsWei";
    Stake_OrderBy["Id"] = "id";
    Stake_OrderBy["JoinTimestamp"] = "joinTimestamp";
    Stake_OrderBy["LockedWei"] = "lockedWei";
    Stake_OrderBy["MinimumStakeWei"] = "minimumStakeWei";
    Stake_OrderBy["Operator"] = "operator";
    Stake_OrderBy["OperatorContractVersion"] = "operator__contractVersion";
    Stake_OrderBy["OperatorCumulativeEarningsWei"] = "operator__cumulativeEarningsWei";
    Stake_OrderBy["OperatorCumulativeOperatorsCutWei"] = "operator__cumulativeOperatorsCutWei";
    Stake_OrderBy["OperatorCumulativeProfitsWei"] = "operator__cumulativeProfitsWei";
    Stake_OrderBy["OperatorDataTokenBalanceWei"] = "operator__dataTokenBalanceWei";
    Stake_OrderBy["OperatorDelegatorCount"] = "operator__delegatorCount";
    Stake_OrderBy["OperatorExchangeRate"] = "operator__exchangeRate";
    Stake_OrderBy["OperatorId"] = "operator__id";
    Stake_OrderBy["OperatorIsEligibleToVote"] = "operator__isEligibleToVote";
    Stake_OrderBy["OperatorLatestHeartbeatMetadata"] = "operator__latestHeartbeatMetadata";
    Stake_OrderBy["OperatorLatestHeartbeatTimestamp"] = "operator__latestHeartbeatTimestamp";
    Stake_OrderBy["OperatorMetadataJsonString"] = "operator__metadataJsonString";
    Stake_OrderBy["OperatorOperatorTokenTotalSupplyWei"] = "operator__operatorTokenTotalSupplyWei";
    Stake_OrderBy["OperatorOperatorsCutFraction"] = "operator__operatorsCutFraction";
    Stake_OrderBy["OperatorOwner"] = "operator__owner";
    Stake_OrderBy["OperatorProtectionEndTimestamp"] = "operator__protectionEndTimestamp";
    Stake_OrderBy["OperatorSlashingsCount"] = "operator__slashingsCount";
    Stake_OrderBy["OperatorTotalStakeInSponsorshipsWei"] = "operator__totalStakeInSponsorshipsWei";
    Stake_OrderBy["OperatorValueUpdateBlockNumber"] = "operator__valueUpdateBlockNumber";
    Stake_OrderBy["OperatorValueUpdateTimestamp"] = "operator__valueUpdateTimestamp";
    Stake_OrderBy["OperatorValueWithoutEarnings"] = "operator__valueWithoutEarnings";
    Stake_OrderBy["Sponsorship"] = "sponsorship";
    Stake_OrderBy["SponsorshipCreator"] = "sponsorship__creator";
    Stake_OrderBy["SponsorshipCumulativeSponsoring"] = "sponsorship__cumulativeSponsoring";
    Stake_OrderBy["SponsorshipId"] = "sponsorship__id";
    Stake_OrderBy["SponsorshipIsRunning"] = "sponsorship__isRunning";
    Stake_OrderBy["SponsorshipMaxOperators"] = "sponsorship__maxOperators";
    Stake_OrderBy["SponsorshipMetadata"] = "sponsorship__metadata";
    Stake_OrderBy["SponsorshipMinOperators"] = "sponsorship__minOperators";
    Stake_OrderBy["SponsorshipMinimumStakingPeriodSeconds"] = "sponsorship__minimumStakingPeriodSeconds";
    Stake_OrderBy["SponsorshipOperatorCount"] = "sponsorship__operatorCount";
    Stake_OrderBy["SponsorshipProjectedInsolvency"] = "sponsorship__projectedInsolvency";
    Stake_OrderBy["SponsorshipRemainingWei"] = "sponsorship__remainingWei";
    Stake_OrderBy["SponsorshipRemainingWeiUpdateTimestamp"] = "sponsorship__remainingWeiUpdateTimestamp";
    Stake_OrderBy["SponsorshipSpotApy"] = "sponsorship__spotAPY";
    Stake_OrderBy["SponsorshipTotalPayoutWeiPerSec"] = "sponsorship__totalPayoutWeiPerSec";
    Stake_OrderBy["SponsorshipTotalStakedWei"] = "sponsorship__totalStakedWei";
    Stake_OrderBy["UpdateTimestamp"] = "updateTimestamp";
})(Stake_OrderBy || (Stake_OrderBy = {}));
export var StakingEvent_OrderBy;
(function (StakingEvent_OrderBy) {
    StakingEvent_OrderBy["Amount"] = "amount";
    StakingEvent_OrderBy["Date"] = "date";
    StakingEvent_OrderBy["Id"] = "id";
    StakingEvent_OrderBy["Operator"] = "operator";
    StakingEvent_OrderBy["OperatorContractVersion"] = "operator__contractVersion";
    StakingEvent_OrderBy["OperatorCumulativeEarningsWei"] = "operator__cumulativeEarningsWei";
    StakingEvent_OrderBy["OperatorCumulativeOperatorsCutWei"] = "operator__cumulativeOperatorsCutWei";
    StakingEvent_OrderBy["OperatorCumulativeProfitsWei"] = "operator__cumulativeProfitsWei";
    StakingEvent_OrderBy["OperatorDataTokenBalanceWei"] = "operator__dataTokenBalanceWei";
    StakingEvent_OrderBy["OperatorDelegatorCount"] = "operator__delegatorCount";
    StakingEvent_OrderBy["OperatorExchangeRate"] = "operator__exchangeRate";
    StakingEvent_OrderBy["OperatorId"] = "operator__id";
    StakingEvent_OrderBy["OperatorIsEligibleToVote"] = "operator__isEligibleToVote";
    StakingEvent_OrderBy["OperatorLatestHeartbeatMetadata"] = "operator__latestHeartbeatMetadata";
    StakingEvent_OrderBy["OperatorLatestHeartbeatTimestamp"] = "operator__latestHeartbeatTimestamp";
    StakingEvent_OrderBy["OperatorMetadataJsonString"] = "operator__metadataJsonString";
    StakingEvent_OrderBy["OperatorOperatorTokenTotalSupplyWei"] = "operator__operatorTokenTotalSupplyWei";
    StakingEvent_OrderBy["OperatorOperatorsCutFraction"] = "operator__operatorsCutFraction";
    StakingEvent_OrderBy["OperatorOwner"] = "operator__owner";
    StakingEvent_OrderBy["OperatorProtectionEndTimestamp"] = "operator__protectionEndTimestamp";
    StakingEvent_OrderBy["OperatorSlashingsCount"] = "operator__slashingsCount";
    StakingEvent_OrderBy["OperatorTotalStakeInSponsorshipsWei"] = "operator__totalStakeInSponsorshipsWei";
    StakingEvent_OrderBy["OperatorValueUpdateBlockNumber"] = "operator__valueUpdateBlockNumber";
    StakingEvent_OrderBy["OperatorValueUpdateTimestamp"] = "operator__valueUpdateTimestamp";
    StakingEvent_OrderBy["OperatorValueWithoutEarnings"] = "operator__valueWithoutEarnings";
    StakingEvent_OrderBy["Sponsorship"] = "sponsorship";
    StakingEvent_OrderBy["SponsorshipCreator"] = "sponsorship__creator";
    StakingEvent_OrderBy["SponsorshipCumulativeSponsoring"] = "sponsorship__cumulativeSponsoring";
    StakingEvent_OrderBy["SponsorshipId"] = "sponsorship__id";
    StakingEvent_OrderBy["SponsorshipIsRunning"] = "sponsorship__isRunning";
    StakingEvent_OrderBy["SponsorshipMaxOperators"] = "sponsorship__maxOperators";
    StakingEvent_OrderBy["SponsorshipMetadata"] = "sponsorship__metadata";
    StakingEvent_OrderBy["SponsorshipMinOperators"] = "sponsorship__minOperators";
    StakingEvent_OrderBy["SponsorshipMinimumStakingPeriodSeconds"] = "sponsorship__minimumStakingPeriodSeconds";
    StakingEvent_OrderBy["SponsorshipOperatorCount"] = "sponsorship__operatorCount";
    StakingEvent_OrderBy["SponsorshipProjectedInsolvency"] = "sponsorship__projectedInsolvency";
    StakingEvent_OrderBy["SponsorshipRemainingWei"] = "sponsorship__remainingWei";
    StakingEvent_OrderBy["SponsorshipRemainingWeiUpdateTimestamp"] = "sponsorship__remainingWeiUpdateTimestamp";
    StakingEvent_OrderBy["SponsorshipSpotApy"] = "sponsorship__spotAPY";
    StakingEvent_OrderBy["SponsorshipTotalPayoutWeiPerSec"] = "sponsorship__totalPayoutWeiPerSec";
    StakingEvent_OrderBy["SponsorshipTotalStakedWei"] = "sponsorship__totalStakedWei";
})(StakingEvent_OrderBy || (StakingEvent_OrderBy = {}));
export var StreamPermission_OrderBy;
(function (StreamPermission_OrderBy) {
    StreamPermission_OrderBy["CanDelete"] = "canDelete";
    StreamPermission_OrderBy["CanEdit"] = "canEdit";
    StreamPermission_OrderBy["CanGrant"] = "canGrant";
    StreamPermission_OrderBy["Id"] = "id";
    StreamPermission_OrderBy["PublishExpiration"] = "publishExpiration";
    StreamPermission_OrderBy["Stream"] = "stream";
    StreamPermission_OrderBy["StreamCreatedAt"] = "stream__createdAt";
    StreamPermission_OrderBy["StreamId"] = "stream__id";
    StreamPermission_OrderBy["StreamMetadata"] = "stream__metadata";
    StreamPermission_OrderBy["StreamUpdatedAt"] = "stream__updatedAt";
    StreamPermission_OrderBy["SubscribeExpiration"] = "subscribeExpiration";
    StreamPermission_OrderBy["UserAddress"] = "userAddress";
    StreamPermission_OrderBy["UserId"] = "userId";
})(StreamPermission_OrderBy || (StreamPermission_OrderBy = {}));
export var Stream_OrderBy;
(function (Stream_OrderBy) {
    Stream_OrderBy["CreatedAt"] = "createdAt";
    Stream_OrderBy["Id"] = "id";
    Stream_OrderBy["Metadata"] = "metadata";
    Stream_OrderBy["Permissions"] = "permissions";
    Stream_OrderBy["Sponsorships"] = "sponsorships";
    Stream_OrderBy["StorageNodes"] = "storageNodes";
    Stream_OrderBy["UpdatedAt"] = "updatedAt";
})(Stream_OrderBy || (Stream_OrderBy = {}));
export var Vote_OrderBy;
(function (Vote_OrderBy) {
    Vote_OrderBy["Flag"] = "flag";
    Vote_OrderBy["FlagFlagResolutionTimestamp"] = "flag__flagResolutionTimestamp";
    Vote_OrderBy["FlagFlaggingTimestamp"] = "flag__flaggingTimestamp";
    Vote_OrderBy["FlagId"] = "flag__id";
    Vote_OrderBy["FlagLastFlagIndex"] = "flag__lastFlagIndex";
    Vote_OrderBy["FlagMetadata"] = "flag__metadata";
    Vote_OrderBy["FlagProtectionEndTimestamp"] = "flag__protectionEndTimestamp";
    Vote_OrderBy["FlagResult"] = "flag__result";
    Vote_OrderBy["FlagReviewerCount"] = "flag__reviewerCount";
    Vote_OrderBy["FlagTargetStakeAtRiskWei"] = "flag__targetStakeAtRiskWei";
    Vote_OrderBy["FlagVoteEndTimestamp"] = "flag__voteEndTimestamp";
    Vote_OrderBy["FlagVoteStartTimestamp"] = "flag__voteStartTimestamp";
    Vote_OrderBy["FlagVotesAgainstKick"] = "flag__votesAgainstKick";
    Vote_OrderBy["FlagVotesForKick"] = "flag__votesForKick";
    Vote_OrderBy["Id"] = "id";
    Vote_OrderBy["Timestamp"] = "timestamp";
    Vote_OrderBy["VotedKick"] = "votedKick";
    Vote_OrderBy["Voter"] = "voter";
    Vote_OrderBy["VoterWeight"] = "voterWeight";
    Vote_OrderBy["VoterContractVersion"] = "voter__contractVersion";
    Vote_OrderBy["VoterCumulativeEarningsWei"] = "voter__cumulativeEarningsWei";
    Vote_OrderBy["VoterCumulativeOperatorsCutWei"] = "voter__cumulativeOperatorsCutWei";
    Vote_OrderBy["VoterCumulativeProfitsWei"] = "voter__cumulativeProfitsWei";
    Vote_OrderBy["VoterDataTokenBalanceWei"] = "voter__dataTokenBalanceWei";
    Vote_OrderBy["VoterDelegatorCount"] = "voter__delegatorCount";
    Vote_OrderBy["VoterExchangeRate"] = "voter__exchangeRate";
    Vote_OrderBy["VoterId"] = "voter__id";
    Vote_OrderBy["VoterIsEligibleToVote"] = "voter__isEligibleToVote";
    Vote_OrderBy["VoterLatestHeartbeatMetadata"] = "voter__latestHeartbeatMetadata";
    Vote_OrderBy["VoterLatestHeartbeatTimestamp"] = "voter__latestHeartbeatTimestamp";
    Vote_OrderBy["VoterMetadataJsonString"] = "voter__metadataJsonString";
    Vote_OrderBy["VoterOperatorTokenTotalSupplyWei"] = "voter__operatorTokenTotalSupplyWei";
    Vote_OrderBy["VoterOperatorsCutFraction"] = "voter__operatorsCutFraction";
    Vote_OrderBy["VoterOwner"] = "voter__owner";
    Vote_OrderBy["VoterProtectionEndTimestamp"] = "voter__protectionEndTimestamp";
    Vote_OrderBy["VoterSlashingsCount"] = "voter__slashingsCount";
    Vote_OrderBy["VoterTotalStakeInSponsorshipsWei"] = "voter__totalStakeInSponsorshipsWei";
    Vote_OrderBy["VoterValueUpdateBlockNumber"] = "voter__valueUpdateBlockNumber";
    Vote_OrderBy["VoterValueUpdateTimestamp"] = "voter__valueUpdateTimestamp";
    Vote_OrderBy["VoterValueWithoutEarnings"] = "voter__valueWithoutEarnings";
})(Vote_OrderBy || (Vote_OrderBy = {}));
export var _SubgraphErrorPolicy_;
(function (_SubgraphErrorPolicy_) {
    /** Data will be returned even if the subgraph has indexing errors */
    _SubgraphErrorPolicy_["Allow"] = "allow";
    /** If the subgraph has indexing errors, data will be omitted. The default. */
    _SubgraphErrorPolicy_["Deny"] = "deny";
})(_SubgraphErrorPolicy_ || (_SubgraphErrorPolicy_ = {}));
export const StakeFieldsFragmentDoc = gql `
    fragment StakeFields on Stake {
  operator {
    id
    metadataJsonString
  }
  amountWei
  earningsWei
  lockedWei
  joinTimestamp
}
    `;
export const SponsorshipFieldsFragmentDoc = gql `
    fragment SponsorshipFields on Sponsorship {
  id
  stream {
    id
    metadata
  }
  metadata
  isRunning
  totalPayoutWeiPerSec
  stakes(first: 1000, orderBy: amountWei, orderDirection: desc) {
    ...StakeFields
  }
  operatorCount
  minOperators
  maxOperators
  totalStakedWei
  remainingWei
  remainingWeiUpdateTimestamp
  projectedInsolvency
  cumulativeSponsoring
  minimumStakingPeriodSeconds
  creator
  spotAPY
}
    ${StakeFieldsFragmentDoc}`;
export const OperatorFieldsFragmentDoc = gql `
    fragment OperatorFields on Operator {
  id
  stakes(first: 1000) {
    ...StakeFields
    sponsorship {
      ...SponsorshipFields
    }
  }
  delegations(first: 1000) {
    delegator {
      id
    }
    operatorTokenBalanceWei
    latestDelegationTimestamp
    earliestUndelegationTimestamp
    id
  }
  slashingEvents(first: 1000) {
    amount
    date
    sponsorship {
      id
      stream {
        id
      }
    }
  }
  queueEntries(first: 1000) {
    amount
    date
    delegator {
      id
    }
    id
  }
  delegatorCount
  valueWithoutEarnings
  totalStakeInSponsorshipsWei
  dataTokenBalanceWei
  operatorTokenTotalSupplyWei
  metadataJsonString
  owner
  nodes
  cumulativeProfitsWei
  cumulativeOperatorsCutWei
  operatorsCutFraction
  contractVersion
  exchangeRate
  controllers
}
    ${StakeFieldsFragmentDoc}
${SponsorshipFieldsFragmentDoc}`;
export const ProjectFieldsFragmentDoc = gql `
    fragment ProjectFields on Project {
  id
  domainIds
  score
  metadata
  streams
  minimumSubscriptionSeconds
  createdAt
  updatedAt
  isDataUnion
  paymentDetails {
    domainId
    beneficiary
    pricingTokenAddress
    pricePerSecond
  }
  subscriptions {
    userAddress
    endTimestamp
  }
  permissions {
    userAddress
    canBuy
    canDelete
    canEdit
    canGrant
  }
  purchases {
    subscriber
    subscriptionSeconds
    price
    fee
    purchasedAt
  }
}
    `;
export const StreamFieldsFragmentDoc = gql `
    fragment StreamFields on Stream {
  id
  metadata
  permissions {
    id
    canGrant
    canEdit
    canDelete
    userId
    subscribeExpiration
    publishExpiration
  }
}
    `;
export const SponsorshipDailyBucketFieldsFragmentDoc = gql `
    fragment SponsorshipDailyBucketFields on SponsorshipDailyBucket {
  id
  operatorCount
  projectedInsolvency
  spotAPY
  totalStakedWei
  remainingWei
  date
  sponsorship {
    id
  }
}
    `;
export const SponsoringEventFieldsFragmentDoc = gql `
    fragment SponsoringEventFields on SponsoringEvent {
  id
  amount
  date
  sponsor
}
    `;
export const OperatorDailyBucketFieldsFragmentDoc = gql `
    fragment OperatorDailyBucketFields on OperatorDailyBucket {
  date
  id
  dataTokenBalanceWei
  delegatorCountChange
  delegatorCountAtStart
  lossesWei
  operatorsCutWei
  valueWithoutEarnings
  profitsWei
  cumulativeEarningsWei
  totalDelegatedWei
  totalUndelegatedWei
  totalStakeInSponsorshipsWei
}
    `;
export const DelegatorDailyBucketFieldsFragmentDoc = gql `
    fragment DelegatorDailyBucketFields on DelegatorDailyBucket {
  id
  totalValueDataWei
  date
  cumulativeEarningsWei
  operatorCount
}
    `;
export const GetAllOperatorsDocument = gql `
    query getAllOperators($first: Int, $skip: Int, $searchQuery: ID, $orderBy: Operator_orderBy, $orderDirection: OrderDirection) {
  operators(
    first: $first
    skip: $skip
    orderBy: $orderBy
    orderDirection: $orderDirection
  ) {
    ...OperatorFields
  }
}
    ${OperatorFieldsFragmentDoc}`;
export const SearchOperatorsByMetadataDocument = gql `
    query searchOperatorsByMetadata($first: Int, $skip: Int, $searchQuery: String, $id: ID!, $orderBy: Operator_orderBy, $orderDirection: OrderDirection) {
  operators(
    first: $first
    skip: $skip
    where: {or: [{id: $id}, {metadataJsonString_contains_nocase: $searchQuery}, {stakes_: {sponsorship_contains_nocase: $searchQuery}}]}
    orderBy: $orderBy
    orderDirection: $orderDirection
  ) {
    ...OperatorFields
  }
}
    ${OperatorFieldsFragmentDoc}`;
export const GetOperatorByIdDocument = gql `
    query getOperatorById($operatorId: ID!, $minBlockNumber: Int = 0) {
  operator(id: $operatorId, block: {number_gte: $minBlockNumber}) {
    ...OperatorFields
  }
}
    ${OperatorFieldsFragmentDoc}`;
export const GetOperatorsByDelegationDocument = gql `
    query getOperatorsByDelegation($first: Int, $skip: Int, $delegator: String!, $orderBy: Operator_orderBy, $orderDirection: OrderDirection) {
  operators(
    first: $first
    skip: $skip
    where: {delegations_: {delegator: $delegator}}
    orderBy: $orderBy
    orderDirection: $orderDirection
  ) {
    ...OperatorFields
  }
}
    ${OperatorFieldsFragmentDoc}`;
export const GetOperatorsByDelegationAndIdDocument = gql `
    query getOperatorsByDelegationAndId($first: Int, $skip: Int, $delegator: String!, $operatorId: ID!, $orderBy: Operator_orderBy, $orderDirection: OrderDirection) {
  operators(
    first: $first
    skip: $skip
    where: {delegations_: {delegator: $delegator}, id: $operatorId}
    orderBy: $orderBy
    orderDirection: $orderDirection
  ) {
    ...OperatorFields
  }
}
    ${OperatorFieldsFragmentDoc}`;
export const GetOperatorsByDelegationAndMetadataDocument = gql `
    query getOperatorsByDelegationAndMetadata($first: Int, $skip: Int, $delegator: String!, $searchQuery: String!, $orderBy: Operator_orderBy, $orderDirection: OrderDirection) {
  operators(
    first: $first
    skip: $skip
    where: {delegations_: {delegator: $delegator}, metadataJsonString_contains_nocase: $searchQuery}
    orderBy: $orderBy
    orderDirection: $orderDirection
  ) {
    ...OperatorFields
  }
}
    ${OperatorFieldsFragmentDoc}`;
export const GetOperatorByOwnerAddressDocument = gql `
    query getOperatorByOwnerAddress($owner: String!, $minBlockNumber: Int = 0) {
  operators(
    first: 1
    block: {number_gte: $minBlockNumber}
    where: {owner: $owner}
  ) {
    ...OperatorFields
  }
}
    ${OperatorFieldsFragmentDoc}`;
export const GetOperatorsByOwnerOrControllerAddressDocument = gql `
    query getOperatorsByOwnerOrControllerAddress($owner: String!, $minBlockNumber: Int = 0) {
  operators(
    block: {number_gte: $minBlockNumber}
    where: {or: [{owner: $owner}, {controllers_contains_nocase: [$owner]}]}
  ) {
    ...OperatorFields
  }
}
    ${OperatorFieldsFragmentDoc}`;
export const GetAllSponsorshipsDocument = gql `
    query getAllSponsorships($first: Int, $skip: Int, $searchQuery: String!, $id: ID!, $orderBy: Sponsorship_orderBy, $orderDirection: OrderDirection, $whereFilters: Sponsorship_filter) {
  sponsorships(
    first: $first
    skip: $skip
    where: {and: [$whereFilters, {or: [{stream_contains_nocase: $searchQuery}, {id: $id}, {stakes_: {operator_contains_nocase: $searchQuery}}]}]}
    orderBy: $orderBy
    orderDirection: $orderDirection
  ) {
    ...SponsorshipFields
  }
}
    ${SponsorshipFieldsFragmentDoc}`;
export const GetSponsorshipsByCreatorDocument = gql `
    query getSponsorshipsByCreator($first: Int, $skip: Int, $searchQuery: String!, $id: ID!, $creator: String!, $orderBy: Sponsorship_orderBy, $orderDirection: OrderDirection, $whereFilters: Sponsorship_filter) {
  sponsorships(
    first: $first
    skip: $skip
    where: {and: [$whereFilters, {creator: $creator}, {or: [{stream_contains_nocase: $searchQuery}, {id: $id}, {stakes_: {operator_contains_nocase: $searchQuery}}]}]}
    orderBy: $orderBy
    orderDirection: $orderDirection
  ) {
    ...SponsorshipFields
  }
}
    ${SponsorshipFieldsFragmentDoc}`;
export const GetSponsorshipByIdDocument = gql `
    query getSponsorshipById($sponsorshipId: ID!, $minBlockNumber: Int = 0) {
  sponsorship(id: $sponsorshipId, block: {number_gte: $minBlockNumber}) {
    ...SponsorshipFields
  }
}
    ${SponsorshipFieldsFragmentDoc}`;
export const GetSponsorshipByStreamIdDocument = gql `
    query getSponsorshipByStreamId($first: Int, $skip: Int, $orderBy: Sponsorship_orderBy, $orderDirection: OrderDirection, $streamId: String!, $whereFilters: Sponsorship_filter) {
  sponsorships(
    first: $first
    skip: $skip
    where: {and: [$whereFilters, {stream_contains_nocase: $streamId}]}
    orderBy: $orderBy
    orderDirection: $orderDirection
  ) {
    ...SponsorshipFields
  }
}
    ${SponsorshipFieldsFragmentDoc}`;
export const GetProjectDocument = gql `
    query getProject($id: ID!, $minBlockNumber: Int = 0) {
  project(id: $id, block: {number_gte: $minBlockNumber}) {
    ...ProjectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;
export const GetProjectsDocument = gql `
    query getProjects($first: Int, $skip: Int, $where: Project_filter) {
  projects(
    first: $first
    skip: $skip
    orderBy: score
    orderDirection: desc
    where: $where
  ) {
    ...ProjectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;
export const GetProjectsByTextDocument = gql `
    query getProjectsByText($first: Int, $skip: Int, $text: String!, $where: Project_filter) {
  projectSearch(first: $first, skip: $skip, text: $text, where: $where) {
    ...ProjectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;
export const GetProjectSubscriptionsDocument = gql `
    query getProjectSubscriptions($id: ID!) {
  project(id: $id) {
    subscriptions {
      userAddress
      endTimestamp
    }
  }
}
    `;
export const GetStreamByIdDocument = gql `
    query getStreamById($streamId: ID!) {
  stream(id: $streamId) {
    ...StreamFields
  }
}
    ${StreamFieldsFragmentDoc}`;
export const GetPagedStreamsDocument = gql `
    query getPagedStreams($first: Int, $orderBy: Stream_orderBy, $orderDirection: OrderDirection, $where: Stream_filter) {
  streams(
    first: $first
    orderBy: $orderBy
    orderDirection: $orderDirection
    where: $where
  ) {
    ...StreamFields
  }
}
    ${StreamFieldsFragmentDoc}`;
export const GetSponsorshipDailyBucketsDocument = gql `
    query getSponsorshipDailyBuckets($where: SponsorshipDailyBucket_filter!, $first: Int, $skip: Int) {
  sponsorshipDailyBuckets(first: $first, skip: $skip, where: $where) {
    ...SponsorshipDailyBucketFields
  }
}
    ${SponsorshipDailyBucketFieldsFragmentDoc}`;
export const GetSponsoringEventsDocument = gql `
    query getSponsoringEvents($sponsorshipId: ID!, $first: Int, $skip: Int) {
  sponsoringEvents(
    where: {sponsorship_: {id: $sponsorshipId}}
    first: $first
    skip: $skip
    orderBy: date
    orderDirection: desc
  ) {
    ...SponsoringEventFields
  }
}
    ${SponsoringEventFieldsFragmentDoc}`;
export const GetOperatorDailyBucketsDocument = gql `
    query getOperatorDailyBuckets($where: OperatorDailyBucket_filter!, $first: Int, $skip: Int) {
  operatorDailyBuckets(first: $first, skip: $skip, where: $where) {
    ...OperatorDailyBucketFields
  }
}
    ${OperatorDailyBucketFieldsFragmentDoc}`;
export const GetDelegatorDailyBucketsDocument = gql `
    query getDelegatorDailyBuckets($where: DelegatorDailyBucket_filter!, $first: Int, $skip: Int) {
  delegatorDailyBuckets(first: $first, skip: $skip, where: $where) {
    ...DelegatorDailyBucketFields
  }
}
    ${DelegatorDailyBucketFieldsFragmentDoc}`;
export const GetMetadataDocument = gql `
    query getMetadata {
  _meta {
    block {
      hash
      number
      timestamp
    }
  }
}
    `;
export const GetNetworkStatsDocument = gql `
    query getNetworkStats {
  networks(first: 1) {
    totalStake
    operatorsCount
    sponsorshipsCount
  }
}
    `;
