import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '~/components/Button';
import FormattedPaymentRate from '~/components/FormattedPaymentRate';
import { getProjectTypeName } from '~/getters';
import { isAbandonment } from '~/modals/ProjectModal';
import { useIsProjectBeingPurchased, usePurchaseCallback, } from '~/shared/stores/purchases';
import { ProjectType } from '~/shared/types';
import { REGULAR, TABLET } from '~/shared/utils/styled';
import { timeUnits } from '~/shared/utils/timeUnit';
import { useIsAccessibleByCurrentWallet } from '~/stores/projectDraft';
import { formatChainName } from '~/utils';
import { getChainConfig, useCurrentChainId, useCurrentChainSymbolicName, } from '~/utils/chains';
import { Route as R, routeOptions } from '~/utils/routes';
import { errorToast } from '~/utils/toast';
export function AccessManifest({ projectId, projectType, firstSalePoint, otherSalePoints, }) {
    const prefix = `The streams in this ${getProjectTypeName(projectType)}`;
    const count = otherSalePoints.length;
    const purchase = usePurchaseCallback();
    const projectChainId = useCurrentChainId();
    const hasAccess = useIsAccessibleByCurrentWallet();
    const isBeingPurchased = useIsProjectBeingPurchased(projectId);
    const { pricePerSecond, chainId, pricingTokenAddress } = firstSalePoint;
    const chainName = useCurrentChainSymbolicName();
    return (React.createElement(Root, null,
        projectType === ProjectType.OpenData ? (React.createElement("p", null,
            prefix,
            " are public and can be accessed for ",
            React.createElement("strong", null, "free"),
            ".")) : (React.createElement("p", null,
            prefix,
            " can be accessed for",
            ' ',
            React.createElement("strong", null,
                ' ',
                React.createElement(FormattedPaymentRate, { amount: pricePerSecond, chainId: chainId, pricingTokenAddress: pricingTokenAddress, timeUnit: timeUnits.hour })),
            ' ',
            "on ",
            React.createElement("strong", null, formatChainName(getChainConfig(chainId).name)),
            count > 0 && (React.createElement(React.Fragment, null,
                ' ',
                "and on ",
                count,
                " other chain",
                count > 1 && 's')),
            ".")),
        hasAccess === true && (React.createElement(Button, { as: Link, to: R.projectConnect(projectId, routeOptions(chainName)) }, "Connect")),
        hasAccess === false && (React.createElement(Button, { type: "button", waiting: isBeingPurchased, onClick: async () => {
                try {
                    await purchase(projectChainId, projectId);
                }
                catch (e) {
                    if (isAbandonment(e)) {
                        return;
                    }
                    console.warn('Purchase failed', e);
                    errorToast({
                        title: 'Purchase failed',
                    });
                }
            } }, "Get access")),
        typeof hasAccess === 'undefined' && (React.createElement(Button, { type: "button", waiting: true }, "Loading\u2026"))));
}
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-1y2ll9e" }) `
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: ${REGULAR};
    flex-direction: column;

    p {
        margin: 0;
    }

    a {
        width: 100%;
        margin-top: 20px;
    }

    @media ${TABLET} {
        flex-direction: row;

        a {
            width: auto;
            margin-top: 0;
            margin-left: 20px;
        }
    }
`;
