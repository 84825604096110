import React, { useEffect } from 'react';
import styled from 'styled-components';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import InfoIcon from '@atlaskit/icon/glyph/info';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import Spinner from '~/components/Spinner';
import { MEDIUM } from '../utils/styled';
import AbstractToast from './AbstractToast';
export var ToastType;
(function (ToastType) {
    ToastType["Info"] = "info";
    ToastType["Processing"] = "processing";
    ToastType["Success"] = "success";
    ToastType["Warning"] = "warn";
})(ToastType || (ToastType = {}));
const defaultAutoCloseAfter = 3;
export default function Toast({ type = ToastType.Info, title = 'внимание!', desc, okLabel, cancelLabel, onResolve, onReject, canSubmit = true, autoCloseAfter: autoCloseAfterProp = true, }) {
    const autoCloseAfter = type !== ToastType.Processing && okLabel == null && cancelLabel == null
        ? autoCloseAfterProp === true
            ? defaultAutoCloseAfter
            : autoCloseAfterProp
        : false;
    useEffect(() => {
        if (autoCloseAfter === false) {
            return () => void 0;
        }
        const timeoutId = setTimeout(() => void onReject?.(), autoCloseAfter * 1000);
        return () => void clearTimeout(timeoutId);
    }, [autoCloseAfter, onReject]);
    return (React.createElement(AbstractToast, null,
        React.createElement(Form, { onSubmit: (e) => {
                e.preventDefault();
                if (canSubmit) {
                    onResolve?.();
                }
            } },
            React.createElement(React.Fragment, null,
                type === ToastType.Success && (React.createElement(IconWrap, { "$color": "#0EAC1B" },
                    React.createElement(CheckCircleIcon, { label: "Success" }))),
                type === ToastType.Info && (React.createElement(IconWrap, { "$color": "#6240AF" },
                    React.createElement(InfoIcon, { label: "Info" }))),
                type === ToastType.Warning && (React.createElement(IconWrap, { "$color": "#FF5C00" },
                    React.createElement(ErrorIcon, { label: "Error" }))),
                type === ToastType.Processing && (React.createElement(IconWrap, null,
                    React.createElement(Spinner, { color: "blue" })))),
            React.createElement(Body, null,
                React.createElement("h4", null, title),
                typeof desc === 'string' ? React.createElement("p", null, desc) : desc,
                (okLabel || cancelLabel) != null && (React.createElement(Buttons, null,
                    okLabel != null && (React.createElement(Button, { type: "submit", disabled: !canSubmit }, okLabel)),
                    okLabel != null && cancelLabel != null && React.createElement(Dot, null),
                    cancelLabel != null && (React.createElement(Button, { type: "button", onClick: () => void onReject?.() }, cancelLabel))))),
            type !== ToastType.Processing && (React.createElement(CloseButton, { onClick: () => void onReject?.(), type: "button" },
                React.createElement(CrossIcon, { label: "Dismiss", size: "small" }))))));
}
const CloseButton = styled.button.withConfig({ displayName: "CloseButton", componentId: "sc-1lvr7ii" }) `
    align-items: center;
    appearance: none;
    color: #525252;
    display: flex;
    flex-shrink: 0;
    height: 24px;
    justify-content: center;
    margin-left: 16px;
    width: 24px;
    padding: 0;
    outline: 0;
    border: 0;
    background: none;
`;
const Dot = styled.div.withConfig({ displayName: "Dot", componentId: "sc-qj1d6o" }) `
    background: #525252;
    height: 3px;
    margin-left: 8px;
    margin-right: 8px;
    width: 3px;
`;
const Form = styled.form.withConfig({ displayName: "Form", componentId: "sc-1cm9afn" }) `
    align-items: start;
    display: flex;
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 400px;
`;
const IconWrap = styled.div.withConfig({ displayName: "IconWrap", componentId: "sc-1vb6m9z" }) `
    height: 24px;
    width: 24px;
    position: relative;
    display: flex;
    items-center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 16px;
    color: ${({ $color = 'inherit' }) => $color};
`;
const Body = styled.div.withConfig({ displayName: "Body", componentId: "sc-jfn3zx" }) `
    flex-grow: 1;
    min-width: 0;
    padding: 3px 0;
    font-size: 14px;

    p,
    h4,
    ul,
    ol {
        font-size: inherit;
        overflow-wrap: break-word;
    }

    h4 {
        margin: 0;
        line-height: normal;
    }

    p,
    ul,
    ol {
        margin: 8px 0 0;
        line-height: 20px;
        padding: 0;
    }

    h4 {
        font-weight: ${MEDIUM};
    }

    ol {
        list-style-position: inside;
    }
`;
const Buttons = styled.div.withConfig({ displayName: "Buttons", componentId: "sc-753gay" }) `
    align-items: center;
    display: flex;
    line-height: normal;
    margin-top: 8px;
`;
const Button = styled.button.withConfig({ displayName: "Button", componentId: "sc-1dee5n8" }) `
    background: none;
    border: 0;
    color: #0324ff;
    display: block;
    font-size: 14px;
    font-weight: ${MEDIUM};
    outline: 0;
    padding: 0;

    :hover {
        color: #000d67;
    }

    :disabled {
        color: inherit;
        opacity: 0.5;
    }
`;
