import React from 'react';
import styled from 'styled-components';
import SvgIcon from '~/shared/components/SvgIcon';
import { Tooltip } from '~/components/Tooltip';
/**
 * Question mark icon with a tooltip.
 */
export function Hint({ children }) {
    return (React.createElement(Tooltip, { content: children },
        React.createElement(IconWrap, null,
            React.createElement(QuestionMarkIcon, null))));
}
export const IconWrap = styled.div.withConfig({ displayName: "IconWrap", componentId: "sc-1jfme6l" }) `
    align-items: center;
    color: ${({ $color = 'inherit' }) => $color};
    display: flex;
    height: 24px;
    justify-content: center;
    position: relative;
    width: 24px;
`;
function getQuestionMarkIconAttrs() {
    return { name: 'outlineQuestionMark' };
}
const QuestionMarkIcon = styled(SvgIcon).attrs(getQuestionMarkIconAttrs).withConfig({ displayName: "QuestionMarkIcon", componentId: "sc-1yzmog0" }) `
    display: block;
    height: 16px;
    width: 16px;
`;
