import React from 'react';
import styled from 'styled-components';
import { COLORS, SANS } from '~/shared/utils/styled';
import SvgIcon from '~/shared/components/SvgIcon';
import { RejectionReason } from '~/utils/exceptions';
import BaseModal from './BaseModal';
/**
 * `BaseModal` with left-aligned title bar and a close button.
 */
export default function LightModal({ children, title = 'Untitled modal', ...props }) {
    return (React.createElement(BaseModal, { ...props }, (close) => (React.createElement(Root, null,
        React.createElement(Head, null,
            React.createElement(Title, null, title),
            React.createElement(CloseButton, { type: "button", onClick: () => void close(RejectionReason.CloseButton) },
                React.createElement(SvgIcon, { name: "crossMedium" }))),
        React.createElement(Content, null, typeof children === 'function' ? children(close) : children)))));
}
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-1xqz3vu" }) `
    max-width: 560px;
    width: 90vw;
    padding: 40px;
    display: grid;
    grid-template-rows: auto auto;
    gap: 24px;
`;
const Head = styled.div.withConfig({ displayName: "Head", componentId: "sc-16xfdfh" }) `
    display: flex;
    font-family: ${SANS};
    font-size: 20px;
    font-weight: normal;
    line-height: 32px;
    margin: 0;
    position: relative;
    text-align: left;
    width: 100%;
    align-items: center;
`;
const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-1f9439v" }) `
    flex-grow: 1;
    color: ${COLORS.primary};
`;
const Content = styled.div.withConfig({ displayName: "Content", componentId: "sc-1cojb88" }) `
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${COLORS.primaryLight};
`;
const CloseButton = styled.button.withConfig({ displayName: "CloseButton", componentId: "sc-6okpin" }) `
    color: ${COLORS.close};
    line-height: 14px;
    cursor: pointer;
    padding: 0.5rem;
    margin: 0;
    background: none;
    outline: none;
    border: none;

    &:disabled {
        opacity: 0.2;
        cursor: not-allowed;
    }

    & > svg {
        width: 14px;
        height: 14px;
    }
`;
