import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { toaster } from 'toasterhea';
import { Alert } from '~/components/Alert';
import { SponsorshipDecimals } from '~/components/Decimals';
import { SponsorshipPaymentTokenName } from '~/components/SponsorshipPaymentTokenName';
import { confirm } from '~/getters/confirm';
import { useConfigValueFromChain, useMediaQuery } from '~/hooks';
import { useSponsorshipTokenInfo } from '~/hooks/sponsorships';
import FormModal, { ErrorLabel, FieldWrap, MaxButton, Prop, PropList, PropValue, Section, SectionHeadline, TextAppendix, TextInput, WingedLabelWrap, } from '~/modals/FormModal';
import { forceUnstakeFromSponsorship, reduceStakeOnSponsorship, stakeOnSponsorship, } from '~/services/sponsorships';
import Label from '~/shared/components/Ui/Label';
import { waitForIndexedBlock } from '~/utils';
import { Layer } from '~/utils/Layer';
import { toBigInt, toFloat } from '~/utils/bn';
import { RejectionReason, isRejectionReason, isTransactionRejection, } from '~/utils/exceptions';
import { getSponsorshipStakeForOperator } from '~/utils/sponsorships';
const EmptyStake = {
    joinedAt: new Date(0),
    amountWei: 0n,
};
function EditStakeModal({ chainId, leavePenalty, onResolve, onReject, operator: { dataTokenBalanceWei: availableBalance, id: operatorId, queueEntries }, sponsorship, ...props }) {
    const { id: sponsorshipId, minimumStakingPeriodSeconds } = sponsorship;
    const [busy, setBusy] = useState(false);
    const { decimals = 18n } = useSponsorshipTokenInfo() || {};
    const stake = useMemo(() => getSponsorshipStakeForOperator(sponsorship, operatorId), [sponsorship, operatorId]);
    const lockedStake = stake?.lockedWei || 0n;
    useEffect(() => {
        if (!stake) {
            onReject?.(new Error('Cannot edit. Operator has no stake in sponsorship.'));
        }
    }, [stake, onReject]);
    const globalMinimumStakeWei = useConfigValueFromChain('minimumStakeWei') || 0n;
    const minimumStakeWei = ((a, b) => (a > b ? a : b))(globalMinimumStakeWei, lockedStake);
    const { joinedAt, amountWei: currentAmount } = stake || EmptyStake;
    const [rawAmount, setRawAmount] = useState(toFloat(currentAmount, decimals).toString());
    useEffect(() => {
        setRawAmount(toFloat(currentAmount, decimals).toString());
    }, [currentAmount, decimals]);
    const minLeaveDate = moment(joinedAt.getTime() + minimumStakingPeriodSeconds * 1000).format('YYYY-MM-DD HH:mm');
    const hasUndelegationQueue = queueEntries.length > 0;
    const amount = ((a) => (a > 0n ? a : 0n))(toBigInt(rawAmount || 0, decimals));
    const difference = amount - currentAmount;
    const insufficientFunds = difference > 0n && difference > availableBalance;
    const isAmountWithinAcceptedRange = amount === 0n || amount >= minimumStakeWei;
    const canSubmit = isAmountWithinAcceptedRange &&
        !insufficientFunds &&
        difference !== 0n &&
        (difference > 0n ? !hasUndelegationQueue : true);
    let submitLabel = 'Save';
    if (amount === 0n) {
        submitLabel = 'Unstake';
    }
    if (difference > 0n) {
        submitLabel = 'Increase stake';
    }
    if (difference < 0n && amount !== 0n) {
        submitLabel = 'Reduce stake';
    }
    const slashingAmount = amount > 0n ? 0n : leavePenalty + lockedStake;
    const clean = amount === currentAmount;
    const limitedSpace = useMediaQuery('screen and (max-width: 460px)');
    return (React.createElement(FormModal, { ...props, title: "Edit stake", canSubmit: canSubmit && !busy, submitLabel: submitLabel, submitting: busy, onBeforeAbort: (reason) => !busy && (reason !== RejectionReason.Backdrop || clean), onReject: onReject, onSubmit: async () => {
            if (!canSubmit) {
                return;
            }
            setBusy(true);
            try {
                if (difference >= 0n) {
                    await stakeOnSponsorship(chainId, sponsorshipId, difference, operatorId, {
                        toastLabel: 'Increase stake on sponsorship',
                        onReceipt: ({ blockNumber }) => waitForIndexedBlock(chainId, blockNumber),
                    });
                    return void onResolve?.();
                }
                if (slashingAmount === 0n) {
                    await reduceStakeOnSponsorship(chainId, sponsorshipId, amount, operatorId, {
                        onReceipt: ({ blockNumber }) => waitForIndexedBlock(chainId, blockNumber),
                        toastLabel: amount === 0n
                            ? 'Unstake from sponsorship'
                            : 'Reduce stake on sponsorship',
                    });
                    return void onResolve?.();
                }
                const slashingReason = leavePenalty > 0n && lockedStake > 0n ? (React.createElement(React.Fragment, null,
                    "Your minimum staking period is still ongoing and ends on",
                    ' ',
                    minLeaveDate,
                    ", and additionally some of your stake is locked in the Sponsorship due to open flags.")) : leavePenalty > 0n ? (React.createElement(React.Fragment, null,
                    "Your minimum staking period is still ongoing and ends on",
                    ' ',
                    minLeaveDate,
                    ".")) : (React.createElement(React.Fragment, null, "Some of your stake is locked in the Sponsorship due to open flags."));
                if (await confirm({
                    title: 'Your stake will be slashed',
                    description: (React.createElement(React.Fragment, null,
                        slashingReason,
                        " If you unstake now, you will lose",
                        ' ',
                        React.createElement(SponsorshipDecimals, { amount: slashingAmount }))),
                    proceedLabel: 'Proceed anyway',
                    cancelLabel: 'Cancel',
                    isDangerous: true,
                })) {
                    await forceUnstakeFromSponsorship(chainId, sponsorshipId, operatorId, {
                        onReceipt: ({ blockNumber }) => waitForIndexedBlock(chainId, blockNumber),
                    });
                    onResolve?.();
                }
            }
            catch (e) {
                if (isRejectionReason(e)) {
                    return;
                }
                if (isTransactionRejection(e)) {
                    return;
                }
                throw e;
            }
            finally {
                setBusy(false);
            }
        } },
        React.createElement(SectionHeadline, null,
            "Please set the amount of ",
            React.createElement(SponsorshipPaymentTokenName, null),
            " to stake on the selected Sponsorship"),
        React.createElement(Section, null,
            React.createElement(WingedLabelWrap, null,
                React.createElement(Label, { "$wrap": true }, "Amount to stake"),
                rawAmount !== '' && !isAmountWithinAcceptedRange && (React.createElement(ErrorLabel, null,
                    "Minimum value is",
                    ' ',
                    React.createElement(SponsorshipDecimals, { amount: minimumStakeWei })))),
            React.createElement(FieldWrap, { "$invalid": rawAmount !== '' && !isAmountWithinAcceptedRange },
                React.createElement(TextInput, { autoFocus: true, name: "amount", onChange: (e) => {
                        setRawAmount(e.target.value);
                    }, placeholder: "0", readOnly: busy, type: "number", min: 0, step: "any", value: rawAmount }),
                React.createElement(MaxButton, { onClick: () => {
                        setRawAmount(toFloat(availableBalance + currentAmount, decimals).toString());
                    } }),
                React.createElement(TextAppendix, null,
                    React.createElement(SponsorshipPaymentTokenName, null))),
            React.createElement(PropList, null,
                React.createElement("li", null,
                    React.createElement(Prop, null, "Current stake"),
                    React.createElement(PropValue, null,
                        React.createElement(SponsorshipDecimals, { abbr: limitedSpace, amount: currentAmount, tooltip: limitedSpace }))),
                React.createElement("li", null,
                    React.createElement(Prop, null, "Stake change"),
                    React.createElement(PropValue, null,
                        React.createElement(SponsorshipDecimals, { abbr: limitedSpace, amount: difference, tooltip: limitedSpace }))),
                React.createElement("li", null,
                    React.createElement(Prop, { "$invalid": insufficientFunds }, insufficientFunds ? (React.createElement(React.Fragment, null, "Not enough balance in Operator")) : (React.createElement(React.Fragment, null, "Available balance in Operator"))),
                    React.createElement(PropValue, null,
                        React.createElement(SponsorshipDecimals, { abbr: limitedSpace, amount: availableBalance, tooltip: limitedSpace }))))),
        amount === 0n && leavePenalty > 0n && (React.createElement(StyledAlert, { type: "error", title: "Your stake will be slashed" },
            "Your minimum staking period is still ongoing and ends on",
            ' ',
            minLeaveDate,
            ". If you unstake now, you will lose",
            ' ',
            React.createElement(SponsorshipDecimals, { amount: leavePenalty }),
            ".")),
        difference > 0n && hasUndelegationQueue && (React.createElement(StyledAlert, { type: "error", title: "Warning!" }, "Cannot stake on sponsorship while delegators are awaiting undelegation"))));
}
export const editStakeModal = toaster(EditStakeModal, Layer.Modal);
const StyledAlert = styled(Alert).withConfig({ displayName: "StyledAlert", componentId: "sc-1hd6slq" }) `
    margin-top: 16px;
`;
