import uniqueId from 'lodash/uniqueId';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import SharedCheckbox from '~/shared/components/Checkbox';
import { COLORS } from '~/shared/utils/styled';
const Label = styled.label.withConfig({ displayName: "Label", componentId: "sc-eodaqx" }) `
    border: 1px solid ${COLORS.Border};
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-items: left;
    padding: 0px 12px 0px 18px;
    height: 40px;
    margin: 0;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
const Checkbox = ({ operationName, value, address, onChange, disabled, }) => {
    const uniqueKey = useMemo(() => uniqueId(`${operationName}-${address}-`), [operationName, address]);
    return (React.createElement(Label, { htmlFor: uniqueKey, disabled: disabled },
        operationName,
        React.createElement(SharedCheckbox, { id: uniqueKey, onChange: (ev) => onChange(ev.target.checked), value: value, disabled: disabled })));
};
export default Checkbox;
