import React from 'react';
import styled from 'styled-components';
import { z } from 'zod';
import { getEmptyParsedProject } from '~/parsers/ProjectParser';
import { Tick } from '~/shared/components/Checkbox';
import Label from '~/shared/components/Ui/Label';
import Input from '~/shared/components/Ui/Text/StyledInput';
import { ProjectType } from '~/shared/types';
import { ProjectDraft, usePersistProjectCallback } from '~/stores/projectDraft';
import { OpenDataPayload } from '~/types/projects';
export default function TermsOfUse() {
    const update = ProjectDraft.useUpdateEntity();
    const { termsOfUse: { redistribution = false, commercialUse = false, reselling = false, storage = false, termsUrl, termsName, }, } = ProjectDraft.useEntity({ hot: true }) ||
        getEmptyParsedProject({ type: ProjectType.OpenData });
    const { 'termsOfUse.termsUrl': termsUrlError } = ProjectDraft.useDraft()?.errors || {};
    const setErrors = ProjectDraft.useSetDraftErrors();
    const persist = usePersistProjectCallback();
    const busy = ProjectDraft.useIsDraftBusy();
    return (React.createElement(React.Fragment, null,
        React.createElement(Checkboxes, null,
            React.createElement(TickButton, { disabled: busy, type: "button", onClick: () => {
                    update(({ termsOfUse }) => {
                        termsOfUse.redistribution = !termsOfUse.redistribution;
                    });
                } },
                React.createElement(Tick, { "$checked": redistribution }),
                React.createElement("span", null, "Redistribution")),
            React.createElement(TickButton, { disabled: busy, type: "button", onClick: () => {
                    update(({ termsOfUse }) => {
                        termsOfUse.commercialUse = !termsOfUse.commercialUse;
                    });
                } },
                React.createElement(Tick, { "$checked": commercialUse }),
                React.createElement("span", null, "Commercial use")),
            React.createElement(TickButton, { disabled: busy, type: "button", onClick: () => {
                    update(({ termsOfUse }) => {
                        termsOfUse.reselling = !termsOfUse.reselling;
                    });
                } },
                React.createElement(Tick, { "$checked": reselling }),
                React.createElement("span", null, "Reselling")),
            React.createElement(TickButton, { disabled: busy, type: "button", onClick: () => {
                    update(({ termsOfUse }) => {
                        termsOfUse.storage = !termsOfUse.storage;
                    });
                } },
                React.createElement(Tick, { "$checked": storage }),
                React.createElement("span", null, "Storage"))),
        React.createElement(Links, null,
            React.createElement("div", null,
                React.createElement(Label, null, "Link to detailed terms"),
                React.createElement(Input, { disabled: busy, invalid: !!termsUrlError, type: "text", placeholder: "Add a URL", value: termsUrl, onChange: (e) => {
                        update(({ termsOfUse }) => {
                            termsOfUse.termsUrl = e.target.value;
                        });
                        if (!termsUrlError) {
                            /**
                             * Only revalidate the URL if we're already in the red. This will prevent
                             * on-the-fly validation before hitting submit assuming the user
                             * knows what a URL is.
                             */
                            return;
                        }
                        try {
                            OpenDataPayload.pick({
                                termsOfUse: true,
                            }).parse({
                                termsOfUse: {
                                    termsUrl: e.target.value,
                                },
                            });
                            setErrors((errors) => {
                                delete errors['termsOfUse.termsUrl'];
                            });
                        }
                        catch (e) {
                            if (e instanceof z.ZodError) {
                                return;
                            }
                            console.warn('Failed to validate terms of use', e);
                        }
                    }, onKeyDown: ({ key }) => {
                        if (key === 'Enter') {
                            persist();
                        }
                    } })),
            React.createElement("div", null,
                React.createElement(Label, null, "Display name for link"),
                React.createElement(Input, { disabled: busy, type: "text", placeholder: "Add a display name", value: termsName, onChange: (e) => {
                        update(({ termsOfUse }) => {
                            termsOfUse.termsName = e.target.value;
                        });
                    }, onKeyDown: ({ key }) => {
                        if (key === 'Enter') {
                            persist();
                        }
                    } })))));
}
const Checkboxes = styled.div.withConfig({ displayName: "Checkboxes", componentId: "sc-178w5da" }) `
    background: #f1f1f1;
    border-radius: 4px;
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(auto-fit, 146px);
    padding: 24px;
`;
const TickButton = styled.button.withConfig({ displayName: "TickButton", componentId: "sc-gijhji" }) `
    align-items: center;
    appearance: none;
    background: transparent;
    border: 0;
    display: flex;
    line-height: normal;
    padding: 0;
    white-space: nowrap;

    ${Tick} {
        flex-shrink: 0;
        height: 24px;
        margin-right: 12px;
        width: 24px;
    }
`;
const Links = styled.div.withConfig({ displayName: "Links", componentId: "sc-1mp7o93" }) `
    display: grid;
    gap: 32px;
    grid-template-columns: 1fr 1fr;
    margin-top: 40px;
`;
