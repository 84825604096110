import React from 'react';
import styled from 'styled-components';
import ColoredBox from '~/components/ColoredBox';
import { COLORS, MEDIUM, TABLET } from '~/shared/utils/styled';
export default function NetworkPageSegment({ bodyComponent: BodyComponent = Body, children, foot = false, headerComponent: HeaderComponent = Header, rootComponent: RootComponent = Root, title = 'Title', }) {
    return (React.createElement(RootComponent, null,
        React.createElement(ColoredBox, null,
            React.createElement(HeaderComponent, null,
                React.createElement(Pad, null, typeof title === 'string' ? React.createElement("h2", null, title) : title)),
            React.createElement(BodyComponent, null, children),
            foot ? React.createElement(Foot, null) : React.createElement(React.Fragment, null))));
}
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-1uhcjnl" }) ``;
const Body = styled.div.withConfig({ displayName: "Body", componentId: "sc-1qfhmyf" }) `
    border-top: 1px solid #efefef;
`;
const Foot = styled.div.withConfig({ displayName: "Foot", componentId: "sc-hj9h9h" }) `
    border-top: 1px solid #efefef;
    height: 32px;
    margin-top: -1px;
`;
const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-1p88m79" }) `
    h2 {
        color: ${COLORS.primary};
        font-size: 20px;
        font-weight: ${MEDIUM};
        line-height: 30px;
        margin: 0;
        white-space: nowrap;
    }
`;
export const Pad = styled.div.withConfig({ displayName: "Pad", componentId: "sc-13c5qyf" }) `
    padding: 20px 24px;

    @media ${TABLET} {
        padding: 32px 40px;
    }
`;
export const SegmentGrid = styled.div.withConfig({ displayName: "SegmentGrid", componentId: "sc-okv1gv" }) `
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 24px;
    margin-top: 64px;

    * + &,
    & & {
        margin-top: 0;
    }
`;
export function TitleBar({ children, label, aux = React.createElement(React.Fragment, null) }) {
    return (React.createElement(TitleBarRoot, null,
        React.createElement(Lhs, null,
            React.createElement("h2", null, children),
            label != null && React.createElement(TitleBarLabel, null, label)),
        React.createElement(Rhs, null, aux)));
}
const TitleBarRoot = styled.div.withConfig({ displayName: "TitleBarRoot", componentId: "sc-e8le53" }) `
    align-items: center;
    display: flex;
    gap: 8px;
    height: 30px;
`;
const Lhs = styled.div.withConfig({ displayName: "Lhs", componentId: "sc-s4io2g" }) `
    align-items: center;
    display: flex;
    flex-grow: 1;
    gap: 8px;
`;
const TitleBarLabel = styled.div.withConfig({ displayName: "TitleBarLabel", componentId: "sc-1pb5y56" }) `
    color: #a3a3a3;
    display: flex;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 30px;
`;
const Rhs = styled.div.withConfig({ displayName: "Rhs", componentId: "sc-1xjt1af" }) `
    align-items: center;
    display: flex;
    gap: 8px;
    min-width: 0;

    &:empty {
        display: none;
    }
`;
