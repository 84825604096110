import React, { useState } from 'react';
import styled from 'styled-components';
import { toaster } from 'toasterhea';
import { RadioCircle } from '~/components/SalePointSelector/SalePointOption';
import FormModal from '~/modals/FormModal';
import { TheGraph } from '~/shared/types';
import { Layer } from '~/utils/Layer';
function ProjectTypeFilterModal({ onResolve, value, ...props }) {
    const [localValue, setLocalValue] = useState(value);
    return (React.createElement(FormModal, { title: "Filter", submitLabel: "Save", ...props, onSubmit: () => {
            onResolve?.(localValue);
        } },
        React.createElement(Body, null,
            React.createElement("h4", null, "By project type"),
            React.createElement("ul", null,
                React.createElement("li", null,
                    React.createElement("button", { type: "button", onClick: () => {
                            setLocalValue(undefined);
                        } },
                        React.createElement(RadioCircle, { "$checked": !localValue }),
                        "Any")),
                productTypeOptions.map(({ value, label }) => (React.createElement("li", { key: value },
                    React.createElement("button", { type: "button", onClick: () => {
                            setLocalValue(value);
                        } },
                        React.createElement(RadioCircle, { "$checked": localValue === value }),
                        label))))))));
}
export const projectTypeFilterModal = toaster(ProjectTypeFilterModal, Layer.Modal);
const productTypeOptions = [
    {
        value: TheGraph.ProjectType.Open,
        label: 'Open data',
    },
    {
        value: TheGraph.ProjectType.Paid,
        label: 'Paid data',
    },
    {
        value: TheGraph.ProjectType.DataUnion,
        label: 'Data union',
    },
];
const Body = styled.div.withConfig({ displayName: "Body", componentId: "sc-zuqmj1" }) `
    font-size: 16px;

    button {
        appearance: none;
        align-items: center;
        display: flex;
        border: 0;
        background: none;
        padding: 0;
        gap: 8px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li + li {
        margin-top: 8px;
    }

    h4 {
        font-size: 22px;
        font-weight: 400;
        margin: 0 0 20px;
    }
`;
