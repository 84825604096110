import React from 'react';
import { StreamPermission } from '@streamr/sdk';
import styled from 'styled-components';
import { Bits, matchBits } from '~/parsers/StreamParser';
import { MEDIUM, TABLET } from '~/shared/utils/styled';
import Checkbox from './Checkbox';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-w3wa5g" }) `
    display: grid;
    grid-template-rows: auto auto auto;
    gap: 21px;
    align-items: start;
    user-select: none;

    @media ${TABLET} {
        grid-template-rows: unset;
        grid-template-columns: 1fr 1fr 1fr;
    }
`;
const Column = styled.div.withConfig({ displayName: "Column", componentId: "sc-ly5e7q" }) `
    display: grid;
    grid-template-rows: auto auto auto;
    gap: 12px;

    & > span {
        color: #000000;
        font-weight: ${MEDIUM};
        font-size: 12px;
        line-height: 16px;
        justify-self: left;
    }
`;
function UnstyledPermissionEditor({ address, permissionBits, disabled, onChange, ...props }) {
    return (React.createElement(Container, { ...props },
        React.createElement(Column, null,
            React.createElement("span", null, "Read"),
            React.createElement(Checkbox, { operationName: "Subscribe", address: address, value: matchBits(Bits[StreamPermission.SUBSCRIBE], permissionBits), onChange: (value) => onChange(StreamPermission.SUBSCRIBE, value), disabled: disabled })),
        React.createElement(Column, null,
            React.createElement("span", null, "Write"),
            React.createElement(Checkbox, { operationName: "Publish", address: address, value: matchBits(Bits[StreamPermission.PUBLISH], permissionBits), onChange: (value) => onChange(StreamPermission.PUBLISH, value), disabled: disabled })),
        React.createElement(Column, null,
            React.createElement("span", null, "Manage"),
            React.createElement(Checkbox, { operationName: "Grant", address: address, value: matchBits(Bits[StreamPermission.GRANT], permissionBits), onChange: (value) => onChange(StreamPermission.GRANT, value), disabled: disabled }),
            React.createElement(Checkbox, { operationName: "Edit", address: address, value: matchBits(Bits[StreamPermission.EDIT], permissionBits), onChange: (value) => onChange(StreamPermission.EDIT, value), disabled: disabled }),
            React.createElement(Checkbox, { operationName: "Delete", address: address, value: matchBits(Bits[StreamPermission.DELETE], permissionBits), onChange: (value) => onChange(StreamPermission.DELETE, value), disabled: disabled }))));
}
const PermissionEditor = styled(UnstyledPermissionEditor).withConfig({ displayName: "PermissionEditor", componentId: "sc-6r0adt" }) ``;
export default PermissionEditor;
