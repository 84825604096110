import JiraFailedBuildStatusIcon from '@atlaskit/icon/glyph/jira/failed-build-status';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Button } from '~/components/Button';
import { SponsorshipDecimals } from '~/components/Decimals';
import { Tooltip, TooltipIconWrap } from '~/components/Tooltip';
import { calculateUndelegationQueueSize, getDelegatedAmountForWallet } from '~/getters';
import { useConfigValueFromChain } from '~/hooks';
import { useForceUndelegate, useOperatorByIdQuery, useProcessUndelegationQueue, } from '~/hooks/operators';
import { ScrollTable } from '~/shared/components/ScrollTable/ScrollTable';
import { useWalletAccount } from '~/shared/stores/wallet';
import { COLORS, MEDIUM } from '~/shared/utils/styled';
import { truncate } from '~/shared/utils/text';
import { useCurrentChainId } from '~/utils/chains';
function getUndelegationExpirationDate(queuedAt, maxUndelegationQueueSeconds = 0) {
    return moment(queuedAt.getTime() + maxUndelegationQueueSeconds * 1000);
}
export function UndelegationQueue({ operatorId }) {
    const currentChainId = useCurrentChainId();
    const operatorQuery = useOperatorByIdQuery(operatorId);
    const operator = operatorQuery.data || null;
    const walletAddress = useWalletAccount();
    const maxUndelegationQueueSeconds = useConfigValueFromChain('maxQueueSeconds', Number);
    const forceUndelegate = useForceUndelegate();
    const processUndelegationQueue = useProcessUndelegationQueue();
    const freeFunds = operator?.dataTokenBalanceWei;
    const undelegationQueueSize = operator != null ? calculateUndelegationQueueSize(operator) : undefined;
    const isProcessButtonDisabled = freeFunds != null &&
        undelegationQueueSize != null &&
        freeFunds < undelegationQueueSize;
    const ProcessQueueButton = () => (React.createElement(Button, { kind: "secondary", disabled: isProcessButtonDisabled, onClick: () => {
            if (operatorId == null) {
                return;
            }
            processUndelegationQueue(currentChainId, operatorId);
        } }, "Process queue"));
    if (operator == null) {
        return null;
    }
    return (React.createElement(ScrollTable, { elements: operator.queueEntries, columns: [
            {
                displayName: 'Delegator address',
                valueMapper: (element) => (React.createElement(React.Fragment, null,
                    truncate(element.delegator),
                    element.delegator === walletAddress?.toLowerCase() && (React.createElement(Badge, null, "You")))),
                align: 'start',
                isSticky: true,
                key: 'id',
            },
            {
                displayName: 'Amount',
                valueMapper: (element) => (React.createElement(SponsorshipDecimals, { abbr: true, amount: ((a, b) => (a < b ? a : b))(getDelegatedAmountForWallet(element.delegator, operator), element.amount) })),
                align: 'end',
                isSticky: false,
                key: 'amount',
            },
            {
                displayName: 'Expiration date',
                valueMapper: (element) => {
                    const expirationDate = getUndelegationExpirationDate(element.queuedAt, maxUndelegationQueueSeconds);
                    return (React.createElement(WarningCell, null,
                        expirationDate.format('YYYY-MM-DD'),
                        expirationDate.isBefore(Date.now()) && (React.createElement(Tooltip, { content: React.createElement("p", null, "Payout time exceeded. You can force unstake now.") },
                            React.createElement(TooltipIconWrap, { "$color": "#ff5c00" },
                                React.createElement(JiraFailedBuildStatusIcon, { label: "Error" }))))));
                },
                align: 'start',
                isSticky: false,
                key: 'date',
            },
            {
                displayName: 'Request date',
                valueMapper: (element) => (React.createElement(React.Fragment, null, moment(element.queuedAt).format('YYYY-MM-DD'))),
                align: 'end',
                isSticky: false,
                key: 'requestDate',
            },
            {
                displayName: 'Request time',
                valueMapper: (element) => (React.createElement(React.Fragment, null, moment(element.queuedAt).format('HH:mm'))),
                align: 'end',
                isSticky: false,
                key: 'requestDate',
            },
            {
                displayName: '',
                valueMapper: (element) => (React.createElement(React.Fragment, null, getUndelegationExpirationDate(element.queuedAt, maxUndelegationQueueSeconds).isBefore(Date.now()) && (React.createElement(Button, { type: "button", kind: "secondary", onClick: () => {
                        forceUndelegate(currentChainId, operator, element.amount);
                    } }, "Force unstake")))),
                align: 'end',
                isSticky: false,
                key: 'actions',
            },
        ], footerComponent: operator.queueEntries.length > 0 ? (React.createElement(Footer, null, isProcessButtonDisabled ? (React.createElement(Tooltip, { content: "The Operator needs to unstake to free up funds to pay out the queue" },
            React.createElement(ProcessQueueButton, null))) : (React.createElement(ProcessQueueButton, null)))) : null }));
}
const Badge = styled.div.withConfig({ displayName: "Badge", componentId: "sc-1hze28r" }) `
    border-radius: 8px;
    background: ${COLORS.secondary};
    color: ${COLORS.primaryLight};
    font-size: 14px;
    font-weight: ${MEDIUM};
    line-height: 30px;
    letter-spacing: 0.14px;
    padding: 0px 10px;
    margin-left: 12px;
`;
const WarningCell = styled.div.withConfig({ displayName: "WarningCell", componentId: "sc-d942ha" }) `
    align-items: center;
    display: grid;
    gap: 8px;
    grid-template-columns: auto auto;

    ${TooltipIconWrap} svg {
        width: 18px;
        height: 18px;
    }
`;
const Footer = styled.div.withConfig({ displayName: "Footer", componentId: "sc-e7rhuc" }) `
    display: flex;
    justify-content: right;
    padding: 32px;
    gap: 10px;
`;
