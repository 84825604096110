import React from 'react';
import styled, { css } from 'styled-components';
import { Buttonesque } from '~/components/Button';
import ColoredBox from '~/components/ColoredBox';
import { CopyButton } from '~/components/CopyButton';
import { FallbackImage } from '~/components/FallbackImage';
import { LayoutColumn } from '~/components/Layout';
import { PropertyDisplay, PropertyDropdownList, PropertyIcon, } from '~/components/PropertyDropdown';
import { Separator } from '~/components/Separator';
import { SponsorshipPaymentTokenName } from '~/components/SponsorshipPaymentTokenName';
import { Tooltip } from '~/components/Tooltip';
import Identicon from '~/shared/components/AvatarImage/Identicon';
import { useWalletAccount } from '~/shared/stores/wallet';
import { COLORS, DESKTOP, MEDIUM, TABLET } from '~/shared/utils/styled';
import { truncate } from '~/shared/utils/text';
import { saveOperator } from '~/utils';
import { useCurrentChainId } from '~/utils/chains';
export function OperatorDetails({ operator }) {
    const { metadata } = operator;
    const chainId = useCurrentChainId();
    const walletAddress = useWalletAccount();
    const canEdit = !!walletAddress && walletAddress == operator.owner;
    return (React.createElement(OperatorDetailsRoot, null,
        React.createElement(LayoutColumn, null,
            React.createElement(ColoredBox, { "$backgroundColor": COLORS.Text },
                React.createElement(OuterWrap, null,
                    React.createElement(OperatorAvatarWrap, null,
                        React.createElement("div", null,
                            React.createElement(FallbackImage, { alt: operator.id, src: metadata.imageUrl || '', placeholder: React.createElement(Identicon, { id: operator.id, size: 400 }) }))),
                    React.createElement(OperatorMetadataWrap, null,
                        React.createElement(InnerWrap, { "$noDesc": !metadata.description },
                            React.createElement("h2", null, metadata.name || operator.id),
                            React.createElement("div", null,
                                React.createElement("p", null, metadata.description)),
                            React.createElement(PropertyDropdownList, null,
                                metadata.url && (React.createElement(PropertyDisplay, { dark: true, icon: React.createElement(PropertyIcon, { name: "web" }), displayValue: metadata.url
                                        .replace(/^https?:\/\//, '')
                                        .replace(/\/+$/, ''), href: metadata.url })),
                                metadata.email && (React.createElement(PropertyDisplay, { dark: true, icon: React.createElement(PropertyIcon, { name: "email" }), displayValue: metadata.email, href: `mailto:${metadata.email}` })),
                                metadata.twitter && (React.createElement(PropertyDisplay, { dark: true, icon: React.createElement(PropertyIcon, { name: "twitter", "$color": "#1da1f2" }), href: metadata.twitter })),
                                metadata.x && (React.createElement(PropertyDisplay, { dark: true, icon: React.createElement(PropertyIcon, { name: "xCom", "$color": "#ffffff" }), href: metadata.x })),
                                metadata.telegram && (React.createElement(PropertyDisplay, { dark: true, icon: React.createElement(PropertyIcon, { name: "telegram", "$color": "#2aabee" }), href: metadata.telegram })),
                                metadata.reddit && (React.createElement(PropertyDisplay, { dark: true, icon: React.createElement(PropertyIcon, { name: "reddit", "$color": "#ff5700" }), href: metadata.reddit })),
                                metadata.linkedIn && (React.createElement(PropertyDisplay, { dark: true, icon: React.createElement(PropertyIcon, { name: "linkedin", "$color": "#0077b5" }), href: metadata.linkedIn })))),
                        React.createElement(Addresses, null,
                            React.createElement(AddressDisplay, { label: "Owner wallet", value: operator.owner }),
                            React.createElement(Separator, null),
                            React.createElement(AddressDisplay, { label: React.createElement(React.Fragment, null,
                                    React.createElement("div", null, "Operator contract"),
                                    React.createElement(OperatorVersionBadge, null,
                                        "v",
                                        operator.contractVersion)), value: operator.id })))),
                React.createElement(Actions, null,
                    React.createElement(CtaWrap, null, canEdit && (React.createElement(Buttonesque, { onClick: () => {
                            saveOperator(chainId, operator);
                        }, "$css": css `
                                        --border: 1px solid currentColor;
                                        --borderRadius: 4px;
                                        --hoverBorder: 1px solid #ffffff;
                                        --color: #a3a3a3;
                                        --hoverColor: #ffffff;

                                        display: flex;
                                        padding: 0 10px;
                                    ` }, "Edit operator"))),
                    React.createElement("div", null,
                        React.createElement(Tooltip, { content: React.createElement("p", null,
                                "Operators run Streamr nodes that contribute bandwidth to\u00A0the Streamr Network. Operators earn ",
                                React.createElement(SponsorshipPaymentTokenName, null),
                                " tokens by\u00A0staking on Sponsorships.") },
                            React.createElement(InfoWrap, null,
                                React.createElement("svg", { width: "20", height: "21", viewBox: "0 0 20 21", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                                    React.createElement("path", { 
                                        // eslint-disable-next-line max-len
                                        d: "M10 0.958009C4.47715 0.958009 -1.35705e-06 5.43516 -8.74228e-07 10.958C-3.91405e-07 16.4809 4.47715 20.958 10 20.958C15.5228 20.958 20 16.4809 20 10.958C20 5.43516 15.5228 0.958008 10 0.958009Z", fill: "#525252" }),
                                    React.createElement("path", { d: "M10 9.39648L10 15.958", stroke: "white", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }),
                                    React.createElement("path", { d: "M10 6.39648L10 6.44763", stroke: "white", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }))))))))));
}
const InfoWrap = styled.div.withConfig({ displayName: "InfoWrap", componentId: "sc-15c53x4" }) `
    padding: 4px;
    pointer-events: auto;
`;
const Actions = styled.div.withConfig({ displayName: "Actions", componentId: "sc-el76ha" }) `
    align-items: center;
    border-top: 1px solid #525252;
    box-sizing: content-box;
    display: flex;
    height: 32px;
    padding: 16px;
    pointer-events: none;

    @media ${DESKTOP} {
        border: 0;
        padding: 24px;
        position: absolute;
        right: 0;
        flex-direction: column;
        top: 0;
        align-items: end;
        height: 100%;
        box-sizing: border-box;
    }
`;
const CtaWrap = styled.div.withConfig({ displayName: "CtaWrap", componentId: "sc-mj8f4b" }) `
    flex-grow: 1;

    ${Buttonesque} {
        pointer-events: auto;
    }
`;
const OperatorDetailsRoot = styled.div.withConfig({ displayName: "OperatorDetailsRoot", componentId: "sc-1hqn9ht" }) `
    background: ${COLORS.Background};
    padding-top: 32px;

    ${ColoredBox} {
        color: ${COLORS.Background};
        position: relative;
    }
`;
const SideImageMq = '(min-width: 660px)';
const OuterWrap = styled.div.withConfig({ displayName: "OuterWrap", componentId: "sc-tdbgo" }) `
    padding: 20px;
    margin: 0 auto;

    @media ${SideImageMq} {
        display: flex;
        gap: 20px;
        max-width: none;
    }

    @media ${TABLET} {
        padding: 24px;
        gap: 24px;
    }

    @media ${DESKTOP} {
        padding: 40px;
        gap: 40px;
    }
`;
const OperatorAvatarWrap = styled.div.withConfig({ displayName: "OperatorAvatarWrap", componentId: "sc-14vxxmi" }) `
    flex-shrink: 0;
    margin: 0 auto;

    @media ${SideImageMq} {
        margin: 0;
        max-width: 348px;
        width: 30%;
    }

    > div {
        align-items: center;
        display: flex;
        justify-content: center;
        aspect-ratio: 1 / 1;
        background: #000000;
        border-radius: 10px;
        overflow: hidden;
    }

    img {
        max-width: 100%;
        display: block;
    }
`;
const OperatorMetadataWrap = styled.div.withConfig({ displayName: "OperatorMetadataWrap", componentId: "sc-ukvcpq" }) `
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 24px;
    min-width: 0;

    @media ${SideImageMq} {
        margin: 0;
    }
`;
const InnerWrap = styled.div.withConfig({ displayName: "InnerWrap", componentId: "sc-1omiqyu" }) `
    flex-grow: 1;

    h2 {
        font-size: 24px;
        font-weight: ${MEDIUM};
        letter-spacing: 0.01em;
        line-height: 30px;
        margin-bottom: 16px;
    }

    ${PropertyDropdownList} {
        margin-top: 16px;
    }

    @media ${SideImageMq} {
        h2 + div {
            padding-right: 112px;
        }
    }

    h2 {
        display: none;
    }

    p {
        margin: 0;
        line-height: 1.5em;
        word-wrap: break-word;
    }

    ${({ $noDesc = false }) => $noDesc &&
    css `
            display: none;
        `}

    @media ${TABLET} {
        display: block;

        h2 {
            display: block;
        }
    }
`;
const InlineAddressesMq = '(min-width: 832px)';
const Addresses = styled.div.withConfig({ displayName: "Addresses", componentId: "sc-bl6nym" }) `
    display: flex;
    flex-direction: column;
    gap: 24px;

    ${Separator} {
        background: #525252;
        display: none;
        height: 48px;
        width: 1px;
    }

    @media ${InlineAddressesMq} {
        align-items: center;
        flex-direction: row;
        gap: 48px;

        ${Separator} {
            display: block;
        }
    }
`;
const AddressDisplayRoot = styled.div.withConfig({ displayName: "AddressDisplayRoot", componentId: "sc-19o3537" }) ``;
const AddressDisplayLabel = styled.div.withConfig({ displayName: "AddressDisplayLabel", componentId: "sc-1xnzbaz" }) `
    align-items: center;
    color: #a3a3a3;
    display: flex;
    font-size: 14px;
    gap: 6px;
    letter-spacing: 0.01em;
    line-height: normal;
`;
const AddressDisplayValue = styled.div.withConfig({ displayName: "AddressDisplayValue", componentId: "sc-dkw7lt" }) `
    align-items: center;
    display: flex;
    font-size: 20px;
    gap: 12px;
    letter-spacing: 0.01em;
    line-height: normal;
    margin-top: 6px;
`;
function AddressDisplay({ label, value }) {
    return (React.createElement(AddressDisplayRoot, null,
        React.createElement(AddressDisplayLabel, null, label),
        React.createElement(AddressDisplayValue, null,
            React.createElement("div", null, truncate(value)),
            React.createElement(CopyButton, { value: value }))));
}
const OperatorVersionBadge = styled.div.withConfig({ displayName: "OperatorVersionBadge", componentId: "sc-xc683f" }) `
    background: #525252;
    color: ${COLORS.Background};
    font-size: 11px;
    font-weight: ${MEDIUM};
    padding: 1px 5px;
    border-radius: 6px;
`;
