import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MarketplaceProductTile } from '~/shared/components/Tile';
import { TABLET, PHONE } from '~/shared/utils/styled';
import { getProjects } from '~/services/projects';
import { useCurrentChainId } from '~/utils/chains';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1k2csbv" }) `
    margin-top: 80px;
    margin-bottom: 120px;
`;
const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-1bb2vzg" }) `
    font-size: 34px;
    line-height: 34px;
    color: #000000;
    padding-bottom: 40px;
`;
const ProjectGrid = styled.div.withConfig({ displayName: "ProjectGrid", componentId: "sc-1349zu6" }) `
    display: grid;
    grid-template-columns: 1fr;
    gap: 36px;

    @media (${PHONE}) {
        grid-template-columns: 1fr 1fr;
    }

    @media (${TABLET}) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
`;
export default function RelatedProjects({ streamId }) {
    const [projects, setProjects] = useState([]);
    const chainId = useCurrentChainId();
    useEffect(() => {
        /**
         * @todo Refactor using useQuery. #refactor
         */
        let mounted = true;
        const loadProjects = async () => {
            const result = await getProjects({
                chainId,
                first: 4,
                streamId,
            });
            if (mounted) {
                setProjects(result.projects);
            }
        };
        loadProjects();
        return () => {
            mounted = false;
        };
    }, [streamId, chainId]);
    if (projects.length === 0) {
        return null;
    }
    return (React.createElement(Container, null,
        React.createElement(Header, null, "This stream belongs to following projects"),
        React.createElement(ProjectGrid, null, projects.map((project) => (React.createElement(MarketplaceProductTile, { key: project.id, product: project, showDataUnionBadge: false, showEditButton: false }))))));
}
