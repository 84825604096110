import React from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import ReactModal2 from 'react-modal2';
import './accessibility';
const Fullpage = styled.div.withConfig({ displayName: "Fullpage", componentId: "sc-cv5ic5" }) `
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(239, 239, 239, 0.98);
    z-index: 10;

    ${({ noScroll }) => !noScroll &&
    css `
            overflow-y: scroll;
        `}
`;
const NoScrollStyles = createGlobalStyle `
    body {
        overflow: hidden !important;
        overflow-y: hidden !important;
        overflow-x: hidden !important;
    }
`;
const ModalDialog = ({ onClose, children, className, backdropClassName, fullpage, noScroll = false, ...otherProps }) => (React.createElement(ReactModal2, { onClose: onClose, backdropClassName: backdropClassName, modalClassName: className, ...otherProps },
    React.createElement(NoScrollStyles, null),
    fullpage ? React.createElement(Fullpage, { noScroll: noScroll }, children) : children));
export default ModalDialog;
