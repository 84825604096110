import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { z } from 'zod';
import { Button } from '~/components/Button';
import ColoredBox from '~/components/ColoredBox';
import Layout, { LayoutColumn, LayoutInner as PrestyledLayoutInner, } from '~/components/Layout';
import { SegmentGrid } from '~/components/NetworkPageSegment';
import BeneficiaryAddressEditor from '~/components/SalePointSelector/BeneficiaryAddressEditor';
import SalePointOption, { DataUnionOption, } from '~/components/SalePointSelector/SalePointOption';
import SalePointTokenSelector from '~/components/SalePointSelector/SalePointTokenSelector';
import { getProjectTypeTitle } from '~/getters';
import ProjectLinkTabs from '~/pages/ProjectPage/ProjectLinkTabs';
import TermsOfUse from '~/pages/ProjectPage/TermsOfUse';
import { deleteProject } from '~/services/projects';
import { DetailsPageHeader } from '~/shared/components/DetailsPageHeader';
import LoadingIndicator from '~/shared/components/LoadingIndicator';
import useIsMounted from '~/shared/hooks/useIsMounted';
import { getChainConfig, getChainConfigExtension, useCurrentChainId, } from '~/utils/chains';
import { ProjectType } from '~/shared/types';
import { ProjectDraft } from '~/stores/projectDraft';
import { SalePointsPayload } from '~/types/projects';
import { formatChainName } from '~/utils';
import { toastedOperation } from '~/utils/toastedOperation';
import { Route as R, routeOptions } from '~/utils/routes';
import DataUnionFee from './DataUnionFee';
import DataUnionPayment from './DataUnionPayment';
import EditorHero from './EditorHero';
import EditorNav from './EditorNav';
import EditorStreams from './EditorStreams';
const EmptySalePoints = {};
const EmptyErrors = {};
export default function ProjectEditorPage() {
    const { id: projectId, type = ProjectType.OpenData, creator = '', salePoints: existingSalePoints = EmptySalePoints, } = ProjectDraft.useEntity({ hot: true }) || {};
    const busy = ProjectDraft.useIsDraftBusy();
    const { fetching = false, errors = EmptyErrors } = ProjectDraft.useDraft() || {};
    const update = ProjectDraft.useUpdateEntity();
    const chainId = useCurrentChainId();
    const availableChains = useMemo(() => getChainConfigExtension(chainId).marketplaceChains.map(getChainConfig), [chainId]);
    const salePoints = availableChains
        .map(({ name: chainName }) => existingSalePoints[chainName])
        .filter(Boolean);
    function onSalePointChange(value) {
        if (busy) {
            return;
        }
        update((draft) => {
            const { name: chainName } = getChainConfig(value.chainId);
            if (draft.salePoints[chainName]?.readOnly) {
                /**
                 * Read-only sale point must not be updated.
                 */
                return;
            }
            draft.salePoints[chainName] = value;
            if (draft.type !== ProjectType.DataUnion) {
                return;
            }
            if (value.enabled) {
                /**
                 * Disable all other sale points making the current one the sole
                 * selection. We're mimicing radio's behaviour here.
                 */
                Object.values(draft.salePoints).forEach((salePoint) => {
                    if (!salePoint?.enabled || salePoint.chainId === value.chainId) {
                        return;
                    }
                    salePoint.enabled = false;
                });
            }
        });
    }
    const setErrors = ProjectDraft.useSetDraftErrors();
    const isMounted = useIsMounted();
    const navigate = useNavigate();
    return (React.createElement(Layout, { innerComponent: LayoutInner, nav: React.createElement(EditorNav, null), pageTitle: projectId ? 'Edit project' : 'Create a new project' },
        React.createElement(DetailsPageHeader, { pageTitle: !!creator && (React.createElement(React.Fragment, null,
                getProjectTypeTitle(type),
                " by",
                React.createElement("strong", null,
                    "\u00A0",
                    creator))), rightComponent: React.createElement(ProjectLinkTabs, null) }),
        React.createElement(LoadingIndicator, { loading: busy }),
        React.createElement(LayoutColumn, null, !fetching && (React.createElement(SegmentGrid, null,
            React.createElement(Segment, null,
                React.createElement(EditorHero, null)),
            type === ProjectType.PaidData && (React.createElement(Segment, null,
                React.createElement(ColoredBox, { "$pad": true },
                    React.createElement(Content, null,
                        React.createElement("h2", null, "Select chains"),
                        React.createElement("p", null, "Access to the project data can be purchased on the selected chains. You can set the payment token, price, and beneficiary address on each chain separately.")),
                    React.createElement(Content, { "$desktopMaxWidth": 728 }, salePoints.map((salePoint) => {
                        const chainName = getChainConfig(salePoint.chainId).name;
                        const formattedChainName = formatChainName(chainName);
                        const beneficiaryErrorKey = `salePoints.${chainName}.beneficiaryAddress`;
                        const beneficiaryInvalid = !!errors[beneficiaryErrorKey];
                        return (React.createElement(SalePointOption, { key: salePoint.chainId, multiSelect: true, onSalePointChange: onSalePointChange, salePoint: salePoint },
                            React.createElement("h4", null,
                                "Set the payment token and price on the ",
                                formattedChainName,
                                " chain"),
                            React.createElement("p", null, "You can set a price for others to access the streams in your project. The price can be set in DATA or any other ERC-20 token."),
                            React.createElement(SalePointTokenSelector, { disabled: salePoint.readOnly, onSalePointChange: onSalePointChange, salePoint: salePoint }),
                            React.createElement("h4", null, "Set beneficiary"),
                            React.createElement("p", null,
                                "This wallet address receives the payments for this product on",
                                ' ',
                                formattedChainName,
                                " chain."),
                            React.createElement(BeneficiaryAddressEditor, { invalid: beneficiaryInvalid, disabled: busy || salePoint.readOnly, value: salePoint.beneficiaryAddress, onChange: (beneficiaryAddress) => {
                                    const newSalePoint = {
                                        ...salePoint,
                                        beneficiaryAddress,
                                    };
                                    onSalePointChange?.(newSalePoint);
                                    /**
                                     * If the field is valid we skip on-the-fly validation assuming
                                     * correct user input at first.
                                     */
                                    if (!beneficiaryInvalid) {
                                        return;
                                    }
                                    try {
                                        try {
                                            SalePointsPayload.parse({
                                                [chainName]: newSalePoint,
                                            });
                                        }
                                        catch (e) {
                                            if (!(e instanceof
                                                z.ZodError)) {
                                                throw e;
                                            }
                                            const issues = e.issues.filter(({ path }) => path.slice(-1)[0] ===
                                                'beneficiaryAddress');
                                            if (issues.length) {
                                                throw new z.ZodError(issues);
                                            }
                                        }
                                        setErrors((errors0) => {
                                            delete errors0[beneficiaryErrorKey];
                                        });
                                    }
                                    catch (e) {
                                        if (e instanceof
                                            z.ZodError) {
                                            return;
                                        }
                                        console.warn('Failed to validate beneficiary address', e);
                                    }
                                } })));
                    }))))),
            type === ProjectType.DataUnion && (React.createElement(DataUnionPayment, null, (salePoint) => (React.createElement(React.Fragment, null,
                !projectId && (React.createElement(Segment, null,
                    React.createElement(ColoredBox, { "$pad": true },
                        React.createElement(Content, null,
                            React.createElement("h2", null, "Select chain"),
                            React.createElement("p", null, "Select the chain for your Data Union.")),
                        React.createElement(Content, { "$desktopMaxWidth": 728 }, salePoints.map((salePoint) => (React.createElement(SalePointOption, { key: salePoint.chainId, onSalePointChange: onSalePointChange, salePoint: salePoint },
                            React.createElement(DataUnionOption, { salePoint: salePoint, onSalePointChange: onSalePointChange })))))))),
                React.createElement(Segment, null,
                    React.createElement(ColoredBox, { "$pad": true },
                        React.createElement(Content, null,
                            React.createElement("h2", null, salePoint ? (React.createElement(React.Fragment, null,
                                "Set the payment token and price on\u00A0the\u00A0",
                                formatChainName(getChainConfig(salePoint.chainId).name),
                                ' ',
                                "chain")) : (React.createElement(React.Fragment, null, "Set the payment token and price"))),
                            React.createElement("p", null, "You can set a price for others to access the streams in your project. The price can be set in DATA or any other ERC-20 token.")),
                        salePoint ? (React.createElement(Content, { "$desktopMaxWidth": 728 },
                            React.createElement(SalePointTokenSelector, { disabled: salePoint.readOnly, salePoint: salePoint, onSalePointChange: onSalePointChange }))) : (React.createElement(React.Fragment, null, "Select a chain first!")))),
                !projectId && salePoint?.beneficiaryAddress ? (React.createElement(React.Fragment, null)) : (React.createElement(Segment, null,
                    React.createElement(ColoredBox, { "$pad": true },
                        React.createElement(Content, null,
                            React.createElement("h2", null, "Data Union admin fee")),
                        React.createElement(Content, { "$desktopMaxWidth": 728 },
                            React.createElement(DataUnionFee, null))))))))),
            React.createElement(Segment, null,
                React.createElement(ColoredBox, { "$pad": true },
                    React.createElement(Content, null,
                        React.createElement("h2", null, "Add streams")),
                    React.createElement(EditorStreams, null))),
            React.createElement(Segment, null,
                React.createElement(ColoredBox, { "$pad": true },
                    React.createElement(Content, null,
                        React.createElement("h2", null, "Set terms of use"),
                        React.createElement("p", null, "Indicate the terms of use you prefer, either simply, by checking the appropriate boxes below to\u00A0show usage types are permitted, or optionally, give more detail by providing a link to your own terms of use document.")),
                    React.createElement(Content, { "$desktopMaxWidth": 728 },
                        React.createElement(TermsOfUse, null)))),
            projectId && (React.createElement(Segment, null,
                React.createElement(ColoredBox, { "$borderColor": "#cdcdcd", "$backgroundColor": "transparent", "$pad": true },
                    React.createElement(Content, null,
                        React.createElement("h2", null, "Delete project"),
                        React.createElement("p", null, "Delete this project forever. You can't undo this.")),
                    React.createElement(Button, { kind: "destructive", onClick: async () => {
                            try {
                                await toastedOperation('Delete project', () => deleteProject(chainId, projectId));
                                if (!isMounted()) {
                                    return;
                                }
                                navigate(R.projects(routeOptions(chainId)));
                            }
                            catch (e) {
                                console.warn('Failed to delete a project', e);
                            }
                        } }, "Delete")))))))));
}
const Segment = styled.div.withConfig({ displayName: "Segment", componentId: "sc-13rxivr" }) `
    h2 {
        font-size: 34px;
        font-weight: 400;
        line-height: 1.5em;
        margin: 0 0 28px;
    }

    h2 + p {
        font-size: 16px;
        margin-bottom: 40px;
    }
`;
const Content = styled.div.withConfig({ displayName: "Content", componentId: "sc-185oicp" }) `
    ${({ $desktopMaxWidth = 678 }) => css `
        @media (min-width: ${$desktopMaxWidth + 64}px) {
            max-width: ${$desktopMaxWidth}px;
        }
    `}
`;
const LayoutInner = styled(PrestyledLayoutInner).withConfig({ displayName: "LayoutInner", componentId: "sc-1vp5ld7" }) `
    width: 100%;
    overflow: hidden;
`;
