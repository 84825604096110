import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import LoadMore from '~/marketplace/components/LoadMore';
import Checkbox from '~/shared/components/Checkbox';
import { COLORS, DESKTOP, MEDIUM, REGULAR, TABLET } from '~/shared/utils/styled';
import { truncateStreamName } from '~/shared/utils/text';
import { useCurrentChainSymbolicName } from '~/utils/chains';
import { Route as R, routeOptions } from '~/utils/routes';
const ROW_HEIGHT = 88;
const Row = styled.div.withConfig({ displayName: "Row", componentId: "sc-3gj1eq" }) `
    align-items: center;
    padding-left: 24px;
`;
const TableGrid = styled(Row).withConfig({ displayName: "TableGrid", componentId: "sc-mr4qn2" }) `
    display: grid;
    gap: 8px;
    grid-template-columns: minmax(0, 1fr) 18px;

    @media ${TABLET} {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) 18px;
    }

    @media ${DESKTOP} {
        grid-template-columns: minmax(0, 3fr) repeat(5, minmax(0, 1fr)) 18px;
    }
`;
const Table = styled.div.withConfig({ displayName: "Table", componentId: "sc-1ag8w8i" }) `
    overflow: auto;
`;
const TableHeader = styled(TableGrid).withConfig({ displayName: "TableHeader", componentId: "sc-1azielj" }) `
    font-weight: ${MEDIUM};
    height: ${ROW_HEIGHT}px;
    font-size: 15px;
    line-height: 26px;
    color: ${COLORS.primaryLight};
    border-bottom: 1px solid #f8f8f8;
    position: sticky;
    top: 0;
    z-index: 1;
`;
const TableRows = styled.div.withConfig({ displayName: "TableRows", componentId: "sc-1ubwdc7" }) `
    height: ${({ rowCount }) => Math.max(rowCount, 1) * (ROW_HEIGHT + 1)}px;
`;
const TableRow = styled(TableGrid).withConfig({ displayName: "TableRow", componentId: "sc-bdllnd" }) `
    font-size: 16px;
    line-height: 26px;
    height: ${ROW_HEIGHT}px;
    max-height: ${ROW_HEIGHT}px;
    box-sizing: content-box;
    color: ${COLORS.primaryLight};

    &:not(:last-child) {
        border-bottom: 1px solid #f8f8f8;
    }
`;
const GridCell = styled.span.withConfig({ displayName: "GridCell", componentId: "sc-qlr3kn" }) `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${({ onlyDesktop }) => onlyDesktop &&
    css `
            display: none;

            @media ${DESKTOP} {
                display: block;
            }
        `}

    ${({ onlyTablet }) => onlyTablet &&
    css `
            display: none;

            @media ${TABLET} {
                display: block;
            }

            @media ${DESKTOP} {
                display: none;
            }
        `}

  ${({ notOnTablet }) => notOnTablet &&
    css `
            display: block;

            @media ${TABLET} {
                display: none;
            }

            @media ${DESKTOP} {
                display: block;
            }
        `}

  ${({ flex }) => flex
    ? css `
                  display: flex;
              `
    : ''}
`;
const NoStreams = styled.div.withConfig({ displayName: "NoStreams", componentId: "sc-ieoway" }) `
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 14px;
    line-height: 18px;
    color: ${COLORS.primaryLight};
`;
const StreamDetails = styled(Link).withConfig({ displayName: "StreamDetails", componentId: "sc-tgyfu6" }) `
    font-size: 16px;
    line-height: 26px;
    overflow: hidden;
    text-overflow: ellipsis;

    &:active,
    &:link,
    &:visited,
    &:hover {
        color: ${COLORS.primaryLight};
    }
`;
const StreamId = styled(GridCell).withConfig({ displayName: "StreamId", componentId: "sc-n9fbro" }) `
    font-weight: ${MEDIUM};
`;
const StreamDescription = styled(GridCell).withConfig({ displayName: "StreamDescription", componentId: "sc-7m5ho1" }) `
    font-weight: ${REGULAR};
`;
export const StreamSelectTable = ({ streams, streamStats, loadMore, hasMoreResults, onSelectionChange, selected, disabled = false, }) => {
    const [selectedStreams, setSelectedStreams] = useState({});
    const [allSelected, setAllSelected] = useState(false);
    const emitSelectedStreamsChange = useCallback((streams) => {
        if (onSelectionChange) {
            const selectedStreamsArray = Object.entries(streams)
                .filter(([, isSelected]) => isSelected)
                .map(([streamId]) => streamId);
            onSelectionChange(selectedStreamsArray);
        }
    }, [onSelectionChange]);
    const handleSelectChange = useCallback((streamId) => {
        const newSelectedStreams = {
            ...selectedStreams,
            [streamId]: !selectedStreams[streamId],
        };
        setSelectedStreams(newSelectedStreams);
        emitSelectedStreamsChange(newSelectedStreams);
    }, [selectedStreams, emitSelectedStreamsChange]);
    const handleSelectAllChange = useCallback(() => {
        const shouldAllBeChecked = !allSelected;
        const newSelectedStreams = {};
        streams.forEach((stream) => {
            newSelectedStreams[stream.id] = shouldAllBeChecked;
        });
        setSelectedStreams(newSelectedStreams);
        setAllSelected(shouldAllBeChecked);
        emitSelectedStreamsChange(newSelectedStreams);
    }, [allSelected, streams, emitSelectedStreamsChange]);
    useEffect(() => {
        const selectedStreamsArray = Object.entries(selectedStreams)
            .filter(([, isSelected]) => isSelected)
            .map(([streamId]) => streamId);
        if (streams.length > 0 &&
            selectedStreamsArray.length === streams.length &&
            !allSelected) {
            setAllSelected(true);
        }
        if (selectedStreamsArray.length !== streams.length && allSelected) {
            setAllSelected(false);
        }
    }, [selectedStreams, streams, allSelected]);
    useEffect(() => {
        if (selected && selected.length) {
            const newSelectedStreams = {};
            selected.forEach((streamId) => {
                newSelectedStreams[streamId] = true;
            });
            setSelectedStreams(newSelectedStreams);
        }
    }, [selected]);
    const chainName = useCurrentChainSymbolicName();
    return (React.createElement("div", null,
        React.createElement(Table, null,
            React.createElement(TableHeader, null,
                React.createElement(GridCell, null, "Stream ID"),
                React.createElement(GridCell, { onlyTablet: true }, "Description"),
                React.createElement(GridCell, { onlyDesktop: true }, "Live peers"),
                React.createElement(GridCell, { onlyDesktop: true }, "Msg/s"),
                React.createElement(GridCell, { onlyDesktop: true }, "Access"),
                React.createElement(GridCell, { onlyDesktop: true }, "Publishers"),
                React.createElement(GridCell, { onlyDesktop: true }, "Subscribers"),
                React.createElement(GridCell, { flex: true },
                    React.createElement(Checkbox, { value: allSelected, onChange: handleSelectAllChange, disabled: disabled }))),
            React.createElement(TableRows, { rowCount: streams.length },
                streams.map((s) => {
                    const stats = streamStats ? streamStats[s.id] : null;
                    return (React.createElement(TableRow, { key: s.id },
                        React.createElement(StreamDetails, { to: R.stream(s.id, routeOptions(chainName)) },
                            React.createElement(StreamId, { title: s.id }, truncateStreamName(s.id, 40)),
                            '\n',
                            React.createElement(StreamDescription, { notOnTablet: true }, s.metadata?.description ?? '')),
                        React.createElement(GridCell, { onlyTablet: true }, s.metadata?.description ?? ''),
                        React.createElement(GridCell, { onlyDesktop: true }, stats?.peerCount ?? '-'),
                        React.createElement(GridCell, { onlyDesktop: true }, stats?.messagesPerSecond ?? '-'),
                        React.createElement(GridCell, { onlyDesktop: true }, stats == null
                            ? '-'
                            : stats.subscriberCount == null
                                ? 'Public'
                                : 'Private'),
                        React.createElement(GridCell, { onlyDesktop: true }, stats == null
                            ? '-'
                            : stats.publisherCount == null
                                ? '∞'
                                : stats.publisherCount),
                        React.createElement(GridCell, { onlyDesktop: true }, stats == null
                            ? '-'
                            : stats.subscriberCount == null
                                ? '∞'
                                : stats.subscriberCount),
                        React.createElement(GridCell, { flex: true },
                            React.createElement(Checkbox, { value: selectedStreams[s.id], onChange: () => {
                                    handleSelectChange(s.id);
                                }, disabled: disabled }))));
                }),
                streams.length === 0 && (React.createElement(NoStreams, null, "No streams that match your query")))),
        loadMore != null && (React.createElement(LoadMore, { hasMoreSearchResults: !!hasMoreResults, onClick: loadMore, preserveSpace: false }))));
};
