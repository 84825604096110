import React from 'react';
import styled from 'styled-components';
import { truncate } from '~/shared/utils/text';
import { MEDIUM } from '~/shared/utils/styled';
export const Name = styled.div.withConfig({ displayName: "Name", componentId: "sc-1l9f9v6" }) `
    font-weight: ${MEDIUM};
    line-height: 1em;
`;
export const Username = styled.div.withConfig({ displayName: "Username", componentId: "sc-g88mn5" }) `
    color: #a3a3a3;
    line-height: 1em;
`;
const UnstyledAvatarless = ({ source, ...props }) => (React.createElement("div", { ...props },
    React.createElement(Username, { title: source },
        truncate(source),
        "\u200C")));
export const Avatarless = styled(UnstyledAvatarless).withConfig({ displayName: "Avatarless", componentId: "sc-amyqc0" }) ``;
