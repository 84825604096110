import React from 'react';
import styled, { css } from 'styled-components';
import * as Colors from '~/shared/components/Ui/StateColors';
import { MEDIUM } from '~/shared/utils/styled';
function UnstyledLabel({ children, ...props }) {
    return React.createElement("label", { ...props }, children);
}
const Label = styled(UnstyledLabel).withConfig({ displayName: "Label", componentId: "sc-e2ulv2" }) `
    color: ${({ state }) => (state ? Colors[state] : undefined) ||
    Colors.DEFAULT};
    display: block;
    font-size: 12px;
    font-weight: ${MEDIUM};
    line-height: 1em;
    margin: 0 0 8px;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transition-property: color, transform;

    ${({ $wrap = false }) => !$wrap &&
    css `
            white-space: nowrap;
        `}
`;
export default Label;
