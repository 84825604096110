import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { COLORS } from '~/shared/utils/styled';
const rotate = keyframes `
    100% {
        transform: rotate(360deg);
    }
`;
function UnstyledSpinner({ coverage = 0.6, fixed = false, color = 'green', size: sizeProp = 16, strokeWidth = 2, innerRef, ...props }) {
    const size = sizeProp === 'medium' ? 22 : sizeProp === 'large' ? 40 : sizeProp;
    const r = (size - strokeWidth) / 2;
    const d = 2 * Math.PI * r;
    const [ringColor = COLORS.spinnerBorder, strokeColor] = (() => {
        switch (color) {
            case 'green':
                return [, COLORS.greenSpinner];
            case 'blue':
                return [, COLORS.link];
            case 'white':
                return [, COLORS.primaryContrast];
            case 'gray':
                return [COLORS.primaryContrast, COLORS.spinnerBorder];
        }
        const { ring, stroke } = color;
        return [ring, stroke];
    })();
    return (React.createElement(SpinnerRoot, { ...props, "$animate": !fixed, ref: innerRef, style: {
            width: `${size}px`,
            height: `${size}px`,
        } },
        React.createElement("svg", { viewBox: `0 0 ${size} ${size}` },
            React.createElement("circle", { cx: size / 2, cy: size / 2, r: r, fill: "none", strokeWidth: strokeWidth, stroke: ringColor, strokeLinecap: "round", strokeDasharray: `${d}, 0`, strokeDashoffset: "0" }),
            React.createElement("circle", { cx: size / 2, cy: size / 2, r: r, fill: "none", strokeWidth: strokeWidth, stroke: strokeColor, strokeLinecap: "round", strokeDasharray: `${coverage * d}, ${(1 - coverage) * d}`, strokeDashoffset: "0" }))));
}
const SpinnerRoot = styled.div.withConfig({ displayName: "SpinnerRoot", componentId: "sc-1ev439o" }) `
    svg {
        display: block;
        transform: rotate(-90deg);
    }

    ${({ $animate = false }) => $animate &&
    css `
            svg {
                animation: ${rotate} 1.1s linear infinite;
                transform: rotate(0);
            }
        `}
`;
const Spinner = styled(UnstyledSpinner).withConfig({ displayName: "Spinner", componentId: "sc-1szkycn" }) ``;
export default Spinner;
