import React from 'react';
import styled from 'styled-components';
import { Buttons } from '~/components/Buttons';
import Modal from './Modal';
import { Footer } from './BaseModal';
const Content = styled.p.withConfig({ displayName: "Content", componentId: "sc-1wl1qwq" }) `
    margin: 56px 0;
    padding: 0 108px;
    text-align: center;
`;
export default function ConfirmPurchaseModal() {
    return (React.createElement(Modal, { title: "Payment confirmation", onBeforeAbort: () => false, darkBackdrop: true },
        React.createElement(Content, null, "You need to confirm the transaction in your wallet to access this project"),
        React.createElement(Footer, null,
            React.createElement(Buttons, { actions: {
                    ok: {
                        title: 'Waiting',
                        kind: 'primary',
                        spinner: true,
                    },
                } }))));
}
