import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '~/components/Button';
const DefaultActions = {};
export function Buttons({ actions = DefaultActions, className }) {
    return (React.createElement(Root, { className: className }, actions &&
        Object.keys(actions)
            .filter((key) => actions && actions[key].visible !== false)
            .map((key) => {
            const { title, onClick, linkTo, href, kind, disabled, outline, spinner, className: cn, type, } = (actions && actions[key]) || {};
            const Tag = kind === 'link' ? Button : ButtonWithMinWidth;
            return (React.createElement(Tag, { key: key, as: linkTo != null ? Link : href != null ? 'a' : 'button', to: linkTo, href: href, onClick: onClick, disabled: disabled, kind: kind, outline: outline, waiting: spinner, type: type, className: cn }, title));
        })));
}
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-3j85iz" }) `
    display: flex;
    justify-content: flex-end;
    width: 100%;

    /* prevents border rendering glitches in chrome */
    transform: translate3d(0, 0, 0);

    :empty {
        display: none;
    }

    > * {
        padding-right: 15px;
        padding-left: 15px;
    }

    > * + * {
        margin-left: 1rem;
    }
`;
const ButtonWithMinWidth = styled(Button).withConfig({ displayName: "ButtonWithMinWidth", componentId: "sc-q9kyk6" }) `
    min-width: 7rem;
`;
