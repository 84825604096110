import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { COLORS, MAX_CONTENT_WIDTH } from '~/shared/utils/styled';
import Text from '~/shared/components/Ui/Text';
import useCopy from '~/shared/hooks/useCopy';
import { truncate } from '~/shared/utils/text';
import { useWalletAccount } from '~/shared/stores/wallet';
import { usePersistProjectCallback } from '~/stores/projectDraft';
import { Meatball } from '../Meatball';
import { SimpleDropdown, SimpleListDropdownMenu } from '../SimpleDropdown';
import { DropdownMenuItem } from '../DropdownMenuItem';
export default function BeneficiaryAddressEditor({ disabled = false, invalid = false, onChange: onChangeProp, value: valueProp = '', }) {
    const [value, setValue] = useState(valueProp);
    useEffect(() => {
        setValue(valueProp);
    }, [valueProp]);
    const valueRef = useRef(value);
    if (valueRef.current !== value) {
        valueRef.current = value;
    }
    const accountAddress = useWalletAccount();
    const { copy } = useCopy();
    function onChange(newValue) {
        setValue(newValue);
        onChangeProp?.(newValue);
    }
    const persist = usePersistProjectCallback();
    return (React.createElement(Container, null,
        React.createElement(Text, { value: value, onCommit: onChange, placeholder: accountAddress
                ? accountAddress
                : 'i.e. 0xa3d1F77ACfF0060F7213D7BF3c7fEC78df847De1', disabled: disabled, invalid: invalid, selectAllOnFocus: true, onKeyDown: ({ key }) => {
                if (key === 'Enter') {
                    persist();
                }
            } }),
        React.createElement(Actions, null,
            React.createElement(SimpleDropdown, { detached: true, menu: (toggle) => (React.createElement(SimpleListDropdownMenu, null,
                    React.createElement("ul", null,
                        React.createElement(DropdownMenuItem, { tabIndex: -1, type: "button", disabled: !accountAddress, onClick: () => {
                                toggle(false);
                                if (!accountAddress) {
                                    return;
                                }
                                onChange(accountAddress);
                            } },
                            React.createElement(AddressItem, null,
                                React.createElement("div", null, "Fill wallet address"),
                                React.createElement("div", null, accountAddress
                                    ? truncate(accountAddress)
                                    : 'Wallet locked'))),
                        React.createElement(DropdownMenuItem, { tabIndex: -1, type: "button", disabled: !value, onClick: () => {
                                copy(value);
                                toggle(false);
                            } }, "Copy")))) }, (toggle) => (React.createElement(ActionsToggle, { type: "button", onClick: () => void toggle((x) => !x), disabled: disabled },
                React.createElement(Meatball, { alt: "", color: "gray", disabled: disabled })))))));
}
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1ioxbai" }) `
    background-color: ${COLORS.inputBackground};
    max-width: ${MAX_CONTENT_WIDTH};
    padding: 12px 24px;
    position: relative;
    line-height: normal;
`;
const AddressItem = styled.div.withConfig({ displayName: "AddressItem", componentId: "sc-1wt9kj9" }) `
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    div + div {
        color: #adadad;
        font-size: 10px;
    }
`;
const Actions = styled.div.withConfig({ displayName: "Actions", componentId: "sc-3uqe2h" }) `
    position: absolute;
    padding: 0 32px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
`;
export const ActionsToggle = styled.button.withConfig({ displayName: "ActionsToggle", componentId: "sc-1ofe3uk" }) `
    appearance: none;
    background: none;
    border: 0;
    display: block;
    outline: 0;
    padding: 0;
`;
