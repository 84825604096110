import { useQuery } from '@tanstack/react-query';
import React from 'react';
import styled from 'styled-components';
import { NetworkActionBarBackButtonIcon, NetworkActionBarBackLink, NetworkActionBarTitle, } from '~/components/ActionBars/NetworkActionBar.styles';
import { OperatorAvatar } from '~/components/avatars';
import { Button } from '~/components/Button';
import { LayoutColumn } from '~/components/Layout';
import { useDelegateFunds, useIsDelegatingFundsToOperator, useIsUndelegatingFundsToOperator, useUndelegateFunds, } from '~/hooks/operators';
import { getOperatorDelegationAmount } from '~/services/operators';
import { useWalletAccount } from '~/shared/stores/wallet';
import { COLORS, DESKTOP, TABLET } from '~/shared/utils/styled';
import { goBack } from '~/utils';
import { useCurrentChainId, useCurrentChainSymbolicName } from '~/utils/chains';
import { Route as R, routeOptions } from '~/utils/routes';
export function OperatorActionBar({ operator }) {
    const walletAddress = useWalletAccount();
    const isDelegatingFunds = useIsDelegatingFundsToOperator(operator.id, walletAddress);
    const delegateFunds = useDelegateFunds();
    const isUndelegatingFunds = useIsUndelegatingFundsToOperator(operator.id, walletAddress);
    const undelegateFunds = useUndelegateFunds();
    const currentChainId = useCurrentChainId();
    const chainName = useCurrentChainSymbolicName();
    const canUndelegateQuery = useQuery({
        queryKey: [
            'operatorActionBar',
            currentChainId,
            operator.id,
            walletAddress?.toLowerCase(),
        ],
        async queryFn() {
            try {
                if (!operator.id || !walletAddress) {
                    return false;
                }
                return ((await getOperatorDelegationAmount(currentChainId, operator.id, walletAddress)) > 0n);
            }
            catch (e) {
                console.warn('Failed to load delegation amount', operator.id, walletAddress, e);
            }
            return null;
        },
    });
    const canUndelegate = !!canUndelegateQuery.data;
    const { metadata } = operator;
    const [delegateLabel, undelegateLabel] = walletAddress?.toLowerCase() === operator.owner
        ? ['Fund', 'Withdraw']
        : ['Delegate', 'Undelegate'];
    return (React.createElement(OperatorActionBarRoot, null,
        React.createElement(LayoutColumn, null,
            React.createElement(OuterWrap, null,
                React.createElement(Wrap0, null,
                    React.createElement(NetworkActionBarBackLink, { to: R.operators(routeOptions(chainName)), onClick: (e) => {
                            goBack({
                                onBeforeNavigate() {
                                    e.preventDefault();
                                },
                            });
                        } },
                        React.createElement(NetworkActionBarBackButtonIcon, { name: "backArrow" })),
                    React.createElement(NetworkActionBarTitle, null,
                        React.createElement(OperatorAvatar, { imageUrl: metadata.imageUrl, operatorId: operator.id }),
                        React.createElement("span", null, metadata.name || operator.id))),
                React.createElement(Wrap1, null,
                    React.createElement(Button, { onClick: () => {
                            delegateFunds({
                                chainId: currentChainId,
                                operator,
                                wallet: walletAddress,
                                onDone: () => {
                                    canUndelegateQuery.refetch();
                                },
                            });
                        }, 
                        // Operator contract v1 has a bug so we need to disable delegation
                        disabled: !walletAddress || operator.contractVersion === 1, waiting: isDelegatingFunds }, delegateLabel),
                    React.createElement(Button, { onClick: () => {
                            undelegateFunds({
                                chainId: currentChainId,
                                operator,
                                wallet: walletAddress,
                            });
                        }, disabled: !canUndelegate, waiting: isUndelegatingFunds }, undelegateLabel))))));
}
const OperatorActionBarRoot = styled.div.withConfig({ displayName: "OperatorActionBarRoot", componentId: "sc-fbifvz" }) `
    background: ${COLORS.Background};
    padding-top: 32px;

    @media ${TABLET} {
        padding-top: 40px;
    }

    @media ${DESKTOP} {
        padding-top: 72px;
    }
`;
const Wrap0 = styled.div.withConfig({ displayName: "Wrap0", componentId: "sc-6n4tu8" }) `
    align-items: center;
    display: flex;
    flex-grow: 1;
`;
const Wrap1 = styled.div.withConfig({ displayName: "Wrap1", componentId: "sc-k7a54r" }) `
    gap: 8px;
`;
const OuterWrap = styled.div.withConfig({ displayName: "OuterWrap", componentId: "sc-61bkfi" }) `
    ${Wrap1} {
        align-items: center;
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 20px;
    }

    @media ${TABLET} {
        align-items: center;
        display: flex;

        ${Wrap1} {
            display: flex;
            margin-top: 0;
        }
    }
`;
