import React from 'react';
import styled from 'styled-components';
import ethereumIcon from './assets/ethereum.svg';
import xDaiIcon from './assets/xdai.svg';
import polygonIcon from './assets/polygon.svg';
import bscIcon from './assets/bsc.svg';
import unknownIcon from './assets/unknown.svg';
const icons = {
    '1': ethereumIcon,
    '100': xDaiIcon,
    '137': polygonIcon,
    '56': bscIcon,
    '8995': ethereumIcon,
    '8997': xDaiIcon,
    '80002': polygonIcon,
};
const UnstyledNetworkIcon = ({ chainId, className, emptyIcon, alt = '', }) => (React.createElement("div", { className: className },
    React.createElement("img", { src: icons[String(chainId)] || emptyIcon || unknownIcon, alt: alt })));
const NetworkIcon = styled(UnstyledNetworkIcon).withConfig({ displayName: "NetworkIcon", componentId: "sc-n5z9vr" }) `
    img {
        width: 100%;
        display: flex;
        align-items: center;
    }
`;
export default NetworkIcon;
