import JiraFailedBuildStatusIcon from '@atlaskit/icon/glyph/jira/failed-build-status';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { SponsorshipDecimals } from '~/components/Decimals';
import { Hint } from '~/components/Hint';
import { LayoutColumn } from '~/components/Layout';
import Spinner from '~/components/Spinner';
import { SponsorshipPaymentTokenName } from '~/components/SponsorshipPaymentTokenName';
import { StatCell, StatGrid } from '~/components/StatGrid';
import { Tooltip, TooltipIconWrap } from '~/components/Tooltip';
import { getSelfDelegationFraction, getSpotApy } from '~/getters';
import { useInterceptHeartbeats } from '~/hooks/useInterceptHeartbeats';
import useOperatorLiveNodes from '~/hooks/useOperatorLiveNodes';
import { COLORS } from '~/shared/utils/styled';
export function OperatorSummary({ operator }) {
    const ownerDelegationPercentage = useMemo(() => getSelfDelegationFraction(operator).multipliedBy(100), [operator]);
    const heartbeats = useInterceptHeartbeats(operator.id);
    const { count: liveNodeCount, isLoading: liveNodeCountIsLoading } = useOperatorLiveNodes(heartbeats);
    return (React.createElement(OperatorSummaryRoot, null,
        React.createElement(LayoutColumn, null,
            React.createElement(StatGrid, null,
                React.createElement(StatCell, { label: "Total stake", tip: React.createElement(React.Fragment, null, operator.valueWithoutEarnings === 0n ? (React.createElement(Tooltip, { content: React.createElement("p", null,
                            "The owner must fund the Operator with",
                            ' ',
                            React.createElement(SponsorshipPaymentTokenName, null),
                            " tokens before it can be used for staking on sponsorships or receiving delegations.") },
                        React.createElement(TooltipIconWrap, { "$color": "#ff5c00" },
                            React.createElement(JiraFailedBuildStatusIcon, { label: "Error" })))) : (React.createElement(Hint, null,
                        React.createElement("p", null,
                            "The total amount of",
                            ' ',
                            React.createElement(SponsorshipPaymentTokenName, null),
                            " tokens that are staked on the Operator, including deployed and undeployed tokens.")))) },
                    React.createElement("div", null,
                        React.createElement(SponsorshipDecimals, { abbr: true, amount: operator.valueWithoutEarnings }))),
                React.createElement(StatCell, { label: "Deployed stake", tip: React.createElement(Hint, null,
                        React.createElement("p", null,
                            "The amount of ",
                            React.createElement(SponsorshipPaymentTokenName, null),
                            " tokens that the Operator has staked on Sponsorships.")) },
                    React.createElement(SponsorshipDecimals, { abbr: true, amount: operator.totalStakeInSponsorshipsWei })),
                React.createElement(StatCell, { label: "Owner's stake", tip: React.createElement(Hint, null,
                        React.createElement("p", null, "The percentage of stake supplied from the owner of the Operator.")) },
                    ownerDelegationPercentage.toFixed(0),
                    "%"),
                React.createElement(StatCell, { label: "Node redundancy", tip: React.createElement(Hint, null,
                        React.createElement("p", null, "The amount of duplicated work when running a fleet of multiple nodes."),
                        React.createElement("p", null, "Doing redundant work protects against slashing in case some of your nodes experience failures. For example,"),
                        React.createElement("ul", null,
                            React.createElement("li", null,
                                React.createElement("strong", null, "A Redundancy Factor of 1"),
                                " means that no duplication of work occurs (the feature is off),"),
                            React.createElement("li", null,
                                React.createElement("strong", null, "A Redundancy Factor of 2"),
                                " means that each stream assignment will be worked on by 2 nodes in the fleet."))) }, operator.metadata.redundancyFactor || 1),
                React.createElement(StatCell, { label: "Owner's cut", tip: React.createElement(Hint, null,
                        React.createElement("p", null, "The fee that the owner of the Operator takes from all earnings."),
                        React.createElement("p", null, "The remaining earnings are distributed among all stakeholders in the Operator, which includes delegators and the owner, in proportion to the size of their respective stakes.")) },
                    operator.operatorsCut,
                    "%"),
                React.createElement(StatCell, { label: "Spot APY", tip: React.createElement(Hint, null,
                        React.createElement("p", null, "The annualized yield that this Operator is earning right now, calculated from the\u00A0present payout rates of the Sponsorships the Operator is currently\u00A0staked in.")) },
                    (getSpotApy(operator) * 100).toFixed(0),
                    "%"),
                React.createElement(StatCell, { label: "Cumulative earnings", tip: React.createElement(Hint, null,
                        React.createElement("p", null, "The total earnings that this Operator has accumulated over its whole\u00A0lifetime.")) },
                    React.createElement(SponsorshipDecimals, { abbr: true, amount: operator.cumulativeProfitsWei +
                            operator.cumulativeOperatorsCutWei })),
                React.createElement(StatCell, { label: "Live nodes", tip: React.createElement(Hint, null,
                        React.createElement("p", null, "The number of online nodes detected that are doing work for this Operator.")) }, liveNodeCountIsLoading ? (React.createElement(Spinner, { color: "blue" })) : (liveNodeCount.toString()))))));
}
const OperatorSummaryRoot = styled.div.withConfig({ displayName: "OperatorSummaryRoot", componentId: "sc-8804st" }) `
    background: ${COLORS.Background};
    padding: 24px 0;
`;
