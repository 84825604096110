import React from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { SimpleDropdown, SimpleListDropdownMenu } from '~/components/SimpleDropdown';
import { getEnvironmentConfig } from '~/getters/getEnvironmentConfig';
import UnstyledNetworkIcon from '~/shared/components/NetworkIcon';
import SvgIcon from '~/shared/components/SvgIcon';
import { getSymbolicChainName, useCurrentChain } from '~/utils/chains';
import { COLORS, LAPTOP } from '~/shared/utils/styled';
import { StreamDraft } from '~/stores/streamDraft';
const MenuItem = ({ chain, isSelected, onClick }) => (React.createElement(MenuItemContainer, { onClick: onClick },
    React.createElement(NetworkIcon, { chainId: chain.id }),
    React.createElement("div", null, chain.name),
    isSelected ? React.createElement(SvgIcon, { name: "tick" }) : React.createElement("div", null)));
const Menu = ({ chains, selectedChain, toggle }) => {
    const [, setSearchParams] = useSearchParams();
    return (React.createElement(MenuContainer, null,
        React.createElement(ChainGrid, null, chains.map((c) => (React.createElement(MenuItem, { key: c.id, chain: c, isSelected: c.id === selectedChain.id, onClick: () => {
                toggle(false);
                const chainName = getSymbolicChainName(c.id);
                setSearchParams((prev) => {
                    const { chain: _, ...rest } = Object.fromEntries(prev);
                    return chainName === 'polygon'
                        ? rest
                        : { ...rest, chain: chainName };
                });
            } }))))));
};
export const ChainSelector = ({ menuAlignment = 'left', ...props }) => {
    const availableChains = getEnvironmentConfig().availableChains;
    const selectedChain = useCurrentChain();
    const isAnyDraftBeingPersisted = StreamDraft.useIsAnyDraftBeingPersisted();
    return (React.createElement(SimpleDropdown, { ...props, disabled: isAnyDraftBeingPersisted, menu: (toggle) => (React.createElement(Menu, { chains: availableChains, selectedChain: selectedChain, toggle: toggle })), align: menuAlignment }, (toggle, isOpen) => (React.createElement(Toggle, { "$isOpen": isOpen, onClick: () => toggle((v) => !v) },
        React.createElement(NetworkIcon, { chainId: selectedChain.id }),
        React.createElement("div", null, selectedChain.name),
        React.createElement(Caret, { name: "caretUp", "$isOpen": isOpen })))));
};
const MenuContainer = styled(SimpleListDropdownMenu).withConfig({ displayName: "MenuContainer", componentId: "sc-uchumb" }) `
    color: ${COLORS.primaryLight};
    max-width: 100%;
`;
const ChainGrid = styled.div.withConfig({ displayName: "ChainGrid", componentId: "sc-ampuev" }) `
    display: grid;
    grid-template-columns: auto auto auto;
    grid-auto-rows: 52px;
    gap: 0px 8px;
`;
const MenuItemContainer = styled.div.withConfig({ displayName: "MenuItemContainer", componentId: "sc-1u65gxv" }) `
    display: grid;
    grid-template-columns: subgrid;
    grid-column: span 3;
    appearance: none;
    background: none;
    border: 0;
    border-bottom: 1px solid ${COLORS.secondary};

    :last-child {
        border-bottom: none;
    }

    transition: 250ms background-color;
    align-items: center;
    padding: 8px 24px;
    cursor: pointer;

    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    color: ${COLORS.primaryLight};

    :focus,
    :hover {
        background: ${COLORS.secondary};
        transition-duration: 50ms;
    }

    &[disabled] {
        background: none;
    }

    svg {
        color: ${COLORS.primaryLight};
        height: 13px;
        width: 13px;
    }
`;
const NetworkIcon = styled(UnstyledNetworkIcon).withConfig({ displayName: "NetworkIcon", componentId: "sc-1djytsj" }) `
    display: flex;
    width: 24px;
    height: 24px;
`;
const Toggle = styled.div.withConfig({ displayName: "Toggle", componentId: "sc-op8rsk" }) `
    display: grid;
    grid-template-columns: 24px auto auto;
    line-height: 24px;
    align-items: center;
    border: 1px solid #f3f3f3;
    border-radius: 8px;
    background: ${({ $isOpen }) => ($isOpen ? COLORS.dialogBorder : '#fff')};
    cursor: pointer;
    gap: 0px;
    height: 32px;
    padding: 4px 8px;
    align-items: center;
    width: fit-content;
    color: ${COLORS.primary};
    font-size: 14px;
    font-weight: 500;

    img {
        width: 20px;
        height: 20px;
    }

    div:nth-child(2) {
        display: none;
    }

    :focus,
    :hover {
        background: ${COLORS.dialogBorder};
        transition-duration: 50ms;
    }

    @media ${LAPTOP} {
        height: 40px;
        padding: 8px 12px;
        gap: 8px;

        img {
            width: 24px;
            height: 24px;
        }

        div:nth-child(2) {
            display: initial;
        }
    }
`;
const Caret = styled(SvgIcon).withConfig({ displayName: "Caret", componentId: "sc-sguc5a" }) `
    width: 10px !important;
    transition: transform 200ms ease-in-out;
    transform: ${({ $isOpen }) => ($isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
`;
