import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { COLORS, MEDIUM } from '~/shared/utils/styled';
import SvgIcon from '~/shared/components/SvgIcon';
export const NetworkActionBarBackLink = styled(Link).withConfig({ displayName: "NetworkActionBarBackLink", componentId: "sc-1cjzv6o" }) `
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 7px 0 -9px;
    width: 42px;
`;
export const NetworkActionBarBackButtonIcon = styled(SvgIcon).withConfig({ displayName: "NetworkActionBarBackButtonIcon", componentId: "sc-1sqye6z" }) `
    color: ${COLORS.primaryLight};
    display: block;
`;
export const NetworkActionBarTitle = styled.div.withConfig({ displayName: "NetworkActionBarTitle", componentId: "sc-1m8fdcp" }) `
    line-height: 30px;
    font-size: 24px;
    font-weight: ${MEDIUM};
    margin: 0;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    word-break: break-word;
`;
export const NetworkActionBarStatsTitle = styled.p.withConfig({ displayName: "NetworkActionBarStatsTitle", componentId: "sc-94n0rj" }) `
    font-weight: ${MEDIUM};
    letter-spacing: 0.6px;
    line-height: 30px;
    font-size: 12px;
    color: ${COLORS.primaryLight};
    text-transform: uppercase;
    margin-top: 44px;
`;
