import React from 'react';
import styled from 'styled-components';
import SvgIcon from '~/shared/components/SvgIcon';
import { COLORS, MEDIUM } from '~/shared/utils/styled';
import IconButton from './IconButton';
const Button = styled(IconButton).withConfig({ displayName: "Button", componentId: "sc-1bfec7h" }) `
    :not(:last-child) {
        margin-left: 16px;
    }

    + div {
        margin: 0 12px;
        white-space: nowrap;
    }

    svg {
        width: 8px;
        height: 14px;
    }
`;
const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-b73oup" }) `
    font-weight: ${MEDIUM};
`;
function UnstyledSelector({ active, onChange, options = [], title, ...props }) {
    const current = options.indexOf(active);
    const prevOption = options[Math.max(0, current - 1)];
    const nextOption = options[Math.min(current + 1, options.length)];
    if (options.length <= 1) {
        return null;
    }
    return (React.createElement("div", { ...props },
        React.createElement(Title, null, title),
        React.createElement(Button, { disabled: current <= 0, onClick: () => {
                if (prevOption) {
                    onChange?.(prevOption);
                }
            }, type: "button" },
            React.createElement(SvgIcon, { name: "back" })),
        React.createElement("div", null,
            React.createElement("strong", null, current + 1),
            " of ",
            React.createElement("strong", null, options.length)),
        React.createElement(Button, { disabled: current >= options.length - 1, onClick: () => {
                if (nextOption) {
                    onChange?.(nextOption);
                }
            }, type: "button" },
            React.createElement(SvgIcon, { name: "forward" }))));
}
const Selector = styled(UnstyledSelector).withConfig({ displayName: "Selector", componentId: "sc-uw3yor" }) `
    align-items: center;
    color: ${COLORS.primary};
    display: flex;
    button {
        color: ${COLORS.primary};
    }
`;
export default Selector;
