import React from 'react';
import styled from 'styled-components';
import PngIcon from '~/shared/components/PngIcon';
import { Buttons } from '~/components/Buttons';
import { RejectionReason } from '~/utils/exceptions';
import Modal from './Modal';
import { Footer } from './BaseModal';
const Content = styled.div.withConfig({ displayName: "Content", componentId: "sc-48o4i9" }) `
    padding: 64px 0;
    text-align: center;

    p {
        margin: 32px auto 0;
        padding: 0 96px;
        position: relative;
    }
`;
const IconWrap = styled.div.withConfig({ displayName: "IconWrap", componentId: "sc-zo1o6g" }) `
    height: 66px;
    margin: 0 auto;
    width: 94px;

    img {
        display: block;
        transform: translate(-11%, -23%);
    }
`;
export default function FailedPurchaseModal({ onReject, onResolve }) {
    return (React.createElement(Modal, { title: "Access failed", onReject: onReject, darkBackdrop: true },
        React.createElement(Content, null,
            React.createElement(IconWrap, null,
                React.createElement(PngIcon, { name: "walletError", width: "110", height: "110" })),
            React.createElement("p", null, "Failed to access the project. Please check your wallet or other settings and try again.")),
        React.createElement(Footer, null,
            React.createElement(Buttons, { actions: {
                    cancel: {
                        title: 'Go back',
                        kind: 'link',
                        onClick() {
                            onReject?.(RejectionReason.CancelButton);
                        },
                    },
                    ok: {
                        title: 'Try again',
                        kind: 'primary',
                        onClick() {
                            onResolve?.();
                        },
                    },
                } }))));
}
