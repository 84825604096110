import React from 'react';
import styled, { css } from 'styled-components';
import SvgIcon from '~/shared/components/SvgIcon';
import { COLORS } from '~/shared/utils/styled';
import { ExternalLinkIcon } from '~/icons';
import { Tooltip } from '~/components/Tooltip';
import { truncate } from '~/shared/utils/text';
import { CopyButton } from '~/components/CopyButton';
import { getChainConfig, useCurrentChainId } from '~/utils/chains';
export const ActionBarButtonBody = styled.div.withConfig({ displayName: "ActionBarButtonBody", componentId: "sc-8l5xq2" }) `
    align-items: center;
    border-radius: 8px;
    display: grid;
    font-size: 14px;
    gap: 12px;
    grid-template-columns: repeat(
        ${({ children }) => React.Children.toArray(children).filter(Boolean).length},
        max-content
    );
    height: 40px;
    padding: 0 12px;
    transition: 250ms background;

    ${({ $background = COLORS.secondary, $color = COLORS.primary }) => css `
        background: ${$background};
        color: ${$color};
    `}
`;
const ActionBarButtonRoot = styled.button.withConfig({ displayName: "ActionBarButtonRoot", componentId: "sc-e5t7kd" }) `
    appearance: none;
    background: none;
    border: 0;
    display: block;
    padding: 0;

    :hover ${ActionBarButtonBody} {
        background: ${COLORS.secondaryLight};
    }

    :active ${ActionBarButtonBody} {
        background: ${COLORS.secondaryHover};
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.03);
    }

    ${({ $active = false }) => $active &&
    css `
            :hover ${ActionBarButtonBody}, ${ActionBarButtonBody} {
                background: ${COLORS.secondaryHover};
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.01);
            }
        `}
`;
export function ActionBarButton({ active = false, children, ...props }) {
    return (React.createElement(ActionBarButtonRoot, { type: "button", ...props, "$active": active },
        React.createElement(ActionBarButtonBody, null, children)));
}
export const ActionBarButtonInnerBody = styled.div.withConfig({ displayName: "ActionBarButtonInnerBody", componentId: "sc-9ckhfi" }) `
    ${({ children }) => {
    const count = React.Children.count(children);
    return (count > 1 &&
        css `
                align-items: center;
                display: grid;
                gap: 6px;
                grid-template-columns: repeat(${count}, max-content);
            `);
}}
`;
function getActionBarButtonCaretAttrs() {
    return {
        name: 'caretDown',
    };
}
export const ActionBarButtonCaret = styled(SvgIcon).attrs(getActionBarButtonCaretAttrs).withConfig({ displayName: "ActionBarButtonCaret", componentId: "sc-4laj10" }) `
    color: ${COLORS.primaryDisabled};
    width: 12px;
    transition: 150ms transform;

    ${({ $invert = false }) => $invert &&
    css `
            transform: rotate(180deg);
        `}
`;
export function ActionBarWalletDisplay({ address, label = 'Wallet', }) {
    const chainId = useCurrentChainId();
    const { blockExplorerUrl } = getChainConfig(chainId);
    return (React.createElement(ActionBarButtonBody, null,
        React.createElement("div", null,
            label,
            ": ",
            React.createElement("strong", null, truncate(address))),
        React.createElement(Tooltip, { content: "Copy address" },
            React.createElement(CopyButton, { value: address })),
        blockExplorerUrl && (React.createElement(Tooltip, { content: "View on explorer" },
            React.createElement("a", { href: `${blockExplorerUrl}/address/${address}`, target: "_blank", rel: "noreferrer" },
                React.createElement(ExternalLinkIcon, null))))));
}
