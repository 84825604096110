import React from 'react';
import styled from 'styled-components';
import Color from 'color';
const Circle = styled.div.withConfig({ displayName: "Circle", componentId: "sc-1wgwdd9" }) `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    line-height: 16px;
    font-size: 16px;
    border-radius: 50%;
    color: ${({ tokenColor }) => (Color(tokenColor).isDark() ? '#ffffff' : '#000000')};
    background-color: ${({ tokenColor }) => tokenColor};
`;
const hashCode = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i += 1) {
        // eslint-disable-next-line no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
};
const intToRGB = (i) => {
    // eslint-disable-next-line no-bitwise
    const c = (i & 0x00ffffff).toString(16).toUpperCase();
    return '00000'.substring(0, 6 - c.length) + c;
};
const UnstyledGenericTokenLogo = ({ contractAddress, symbol, ...props }) => {
    const cssColor = `#${intToRGB(hashCode(contractAddress || ''))}`;
    return (React.createElement(Circle, { ...props, tokenColor: cssColor }, (symbol || '?').charAt(0).toUpperCase()));
};
const GenericTokenLogo = styled(UnstyledGenericTokenLogo).withConfig({ displayName: "GenericTokenLogo", componentId: "sc-1t6tcv5" }) ``;
export default GenericTokenLogo;
