import React from 'react';
import styled from 'styled-components';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Button, HamburgerButton, Logo, NavOverlay } from '@streamr/streamr-layout';
import { DESKTOP, TABLET } from '~/shared/utils/styled';
import SvgIcon from '~/shared/components/SvgIcon';
import { truncate } from '~/shared/utils/text';
import { connectModal } from '~/modals/ConnectModal';
import { useEns, useWalletAccount } from '~/shared/stores/wallet';
import toast from '~/utils/toast';
import { useOperatorForWalletQuery } from '~/hooks/operators';
import { saveOperator } from '~/utils';
import { useMediaQuery } from '~/hooks';
import { ChainSelector as UnstyledChainSelector } from '~/components/ChainSelector';
import { useCurrentChainId } from '~/utils/chains';
import { Route as R, routeOptions } from '~/utils/routes';
import { useCurrentChainSymbolicName } from '~/utils/chains';
import { Avatarless, Name, Username } from './User';
import { Avatar, LogoLink, Menu, MenuDivider, MenuGrid, MenuItemAvatarContainer, MOBILE_LG, Navbar, NavbarItem, NavbarItemAccount, NavbarLinkDesktop, NavbarLinkMobile, NavLink, SignedInUserMenu, TextMenuItem, UserInfoMenuItem, UserInfoMobile, WalletAddress, } from './Nav.styles';
import { Dropdown } from './NetworkDropdown';
import { NetworkAccordion } from './NetworkAccordion';
const UnstyledDesktopNav = (props) => {
    const { pathname } = useLocation();
    const account = useWalletAccount();
    const ensName = useEns(account);
    const navigate = useNavigate();
    const operatorQuery = useOperatorForWalletQuery(account);
    const { data: operator = null, isFetching: isOperatorFetching } = operatorQuery;
    const isMobile = !useMediaQuery(`only screen and ${TABLET}`);
    const chainId = useCurrentChainId();
    const chainName = useCurrentChainSymbolicName();
    return (React.createElement("div", { ...props, "data-testid": 'desktop-nav' },
        React.createElement(Navbar, null,
            React.createElement(NavbarItem, null,
                React.createElement(LogoLink, { href: R.root() },
                    React.createElement(Logo, { "data-testid": 'logo' }))),
            React.createElement(ChainSelector, { "data-mobile-only": true, menuAlignment: "left" }),
            React.createElement(MenuGrid, { "data-desktop-only": true },
                React.createElement("div", null),
                React.createElement(NavbarItem, null,
                    React.createElement(NavbarLinkDesktop, { highlight: pathname.startsWith(R.projects()) },
                        React.createElement(NavLink, { as: Link, to: R.projects(routeOptions(chainName)) }, "Projects"))),
                React.createElement(NavbarItem, null,
                    React.createElement(NavbarLinkDesktop, { highlight: pathname.startsWith(R.streams()) },
                        React.createElement(NavLink, { as: Link, to: R.streams(routeOptions(chainName)) }, "Streams"))),
                React.createElement(NavbarItem, null,
                    React.createElement(Dropdown, null)),
                React.createElement(ChainSelector, { menuAlignment: "right" })),
            !account && (React.createElement(NavbarItemAccount, null,
                React.createElement(Button, { kind: "primary", size: "mini", outline: true, type: "button", onClick: async () => {
                        try {
                            await connectModal.pop();
                        }
                        catch (e) {
                            console.warn('Wallet connecting failed', e);
                        }
                    } }, "Connect"))),
            !!account && (React.createElement(NavbarItemAccount, null,
                React.createElement(SignedInUserMenu, { edge: true, alignMenu: "right", nodeco: true, toggle: React.createElement(Avatar, { username: account }), menu: React.createElement(Menu, null,
                        React.createElement(UserInfoMenuItem, null,
                            React.createElement(MenuItemAvatarContainer, null,
                                React.createElement(Avatar, { username: account }),
                                React.createElement(WalletAddress, null,
                                    !!ensName && (React.createElement("span", { className: 'ens-name' }, truncate(ensName))),
                                    React.createElement("span", null, truncate(account))))),
                        isMobile && (React.createElement(React.Fragment, null,
                            React.createElement(MenuDivider, null),
                            operator ? (React.createElement(TextMenuItem, { onClick: () => {
                                    navigate(R.operator(operator.id, routeOptions(chainName)));
                                } },
                                React.createElement("span", null, "My Operator"))) : (React.createElement(TextMenuItem, { disabled: isOperatorFetching, onClick: () => {
                                    if (isOperatorFetching) {
                                        return;
                                    }
                                    saveOperator(chainId, undefined, {
                                        onDone(id, blockNumber) {
                                            navigate(R.operator(id, routeOptions(chainId, {
                                                b: blockNumber,
                                            })));
                                        },
                                    });
                                } },
                                React.createElement("span", null, "Become an Operator"))))),
                        React.createElement(MenuDivider, null),
                        React.createElement(TextMenuItem, { onClick: () => {
                                toast({
                                    title: 'Use the "Lock" button in your wallet.',
                                });
                            } },
                            React.createElement("span", null, "Disconnect"),
                            React.createElement(SvgIcon, { name: "disconnect" }))) }))),
            React.createElement(HamburgerButton, { idle: true }))));
};
const UnstyledMobileNav = ({ className }) => {
    const account = useWalletAccount();
    const { pathname } = useLocation();
    const chainName = useCurrentChainSymbolicName();
    return (React.createElement(NavOverlay, { className: className },
        React.createElement(NavOverlay.Head, null,
            React.createElement(Navbar, null,
                React.createElement(NavbarItem, null,
                    React.createElement(LogoLink, { href: R.root() },
                        React.createElement(Logo, null))),
                React.createElement(NavbarItem, null,
                    React.createElement(HamburgerButton, null)))),
        React.createElement(NavOverlay.Body, null,
            !!account && (React.createElement(UserInfoMobile, null,
                React.createElement(Avatar, { username: account }),
                React.createElement(Avatarless, { "data-testid": 'avatarless', source: account }))),
            React.createElement(NavbarLinkMobile, { highlight: pathname.startsWith(R.projects()) },
                React.createElement(NavLink, { as: Link, to: R.projects(routeOptions(chainName)) }, "Projects")),
            React.createElement(NavbarLinkMobile, { highlight: pathname.startsWith(R.streams()) },
                React.createElement(NavLink, { as: Link, to: R.streams(routeOptions(chainName)) }, "Streams")),
            React.createElement(NetworkAccordion, null)),
        React.createElement(NavOverlay.Footer, null, !!account ? (React.createElement(Button, { kind: "secondary", size: "normal", type: "button", onClick: () => {
                toast({
                    title: 'Use the "Lock" button in your wallet.',
                });
            } }, "Disconnect")) : (React.createElement(Button, { type: "button", onClick: async () => {
                try {
                    await connectModal.pop();
                }
                catch (e) {
                    console.warn('Wallet connecting failed', e);
                }
            }, kind: "primary", size: "normal" }, "Connect Wallet")))));
};
const DesktopNav = styled(UnstyledDesktopNav).withConfig({ displayName: "DesktopNav", componentId: "sc-e26ir0" }) `
    position: relative;

    ${Navbar} {
        > ${NavbarItem}:first-child {
            flex-grow: initial;
        }

        > ${NavbarItem}:nth-child(2) {
            flex-grow: 1;
        }
    }

    @media (${DESKTOP}) {
        ${Navbar} > ${NavbarItem}:first-child {
            flex-grow: 1;
        }
    }

    &[data-shadow='true'] {
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.02);
    }

    ${Avatarless} {
        line-height: 20px;
        padding: 4px 0 8px;
    }

    ${Name} {
        font-size: 14px;
        margin-bottom: 4px;
    }

    ${Username} {
        font-size: 12px;
    }
`;
const MobileNav = styled(UnstyledMobileNav).withConfig({ displayName: "MobileNav", componentId: "sc-tkrg3i" }) `
    ${HamburgerButton} {
        margin-left: auto;
    }

    ${NavOverlay.Body} {
        padding: 36px 24px 0 24px;

        ${UserInfoMobile} {
            margin-bottom: 24px;
        }

        ${NavbarLinkMobile} {
            border-top: 1px solid #efefef;

            + ${NavbarLinkMobile} {
                border-top: none;
            }
        }

        > :first-child {
            border-top: none;
        }
    }

    ${NavOverlay.Footer} {
        background-color: #ffffff;
        padding: 24px;

        ${Button} {
            width: 100%;
        }
    }

    @media (min-width: ${MOBILE_LG}px) {
        ${NavOverlay.Body} {
            padding: 36px 64px 0 64px;

            ${UserInfoMobile} {
                margin-bottom: 64px;
            }
        }

        ${NavOverlay.Footer} {
            padding: 64px;

            ${Button} {
                width: auto;
            }
        }
    }
`;
export const NavContainer = styled.div.withConfig({ displayName: "NavContainer", componentId: "sc-id2ix2" }) `
    background-color: #ffffff;
    color: #323232;

    ${HamburgerButton} {
        background-color: #f8f8f8;
    }

    ${Navbar} {
        padding: 20px 24px;

        @media (${TABLET}) {
            padding: 20px 24px;
        }

        @media (${DESKTOP}) {
            padding: 20px 40px;
        }

        > ${HamburgerButton} {
            display: flex;
        }

        ${NavbarItem}:empty {
            display: none;
        }

        > [data-desktop-only='true'] {
            display: none;
        }
    }

    ${Button} {
        padding: 0 16px;
    }

    > [data-mobile-only='true'] {
        display: block;
    }

    @media (${TABLET}) {
        ${Navbar} > [data-desktop-only='true'] {
            display: grid;
        }

        ${Navbar} > ${HamburgerButton} {
            display: none;
        }

        > [data-mobile-only='true'] {
            display: none;
        }
    }
`;
const ChainSelector = styled(UnstyledChainSelector).withConfig({ displayName: "ChainSelector", componentId: "sc-wg4p6u" }) `
    justify-self: right;

    &[data-mobile-only='true'] {
        display: block;
        justify-self: left;
    }

    @media (${TABLET}) {
        &[data-mobile-only='true'] {
            display: none;
        }
    }
`;
function N({ shadow = false, ...props }) {
    return (React.createElement(NavContainer, { ...props },
        React.createElement(DesktopNav, { "data-shadow": !!shadow }),
        React.createElement(MobileNav, null)));
}
Object.assign(N, {
    Container: NavContainer,
});
export default N;
