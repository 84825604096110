import styled from 'styled-components';
import { COLORS, DESKTOP, MEDIUM, TABLET, MAX_BODY_WIDTH } from '~/shared/utils/styled';
import { Button } from '~/components/Button';
export const FiltersBar = styled.div.withConfig({ displayName: "FiltersBar", componentId: "sc-12qmyua" }) `
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: ${MAX_BODY_WIDTH}px;
    padding: 0 30px 30px;

    @media ${DESKTOP} {
        padding: 0 0 30px;
    }
`;
export const FiltersWrap = styled.div.withConfig({ displayName: "FiltersWrap", componentId: "sc-tbepbb" }) `
    align-items: center;
    display: flex;
    width: 100%;

    @media ${TABLET} {
        align-items: unset;
        width: auto;
    }
`;
export const DropdownFilters = styled.div.withConfig({ displayName: "DropdownFilters", componentId: "sc-17tltbs" }) `
    display: none;
    align-items: center;
    margin-left: 40px;
    span {
        color: ${COLORS.primary};
        font-weight: ${MEDIUM};
        margin-right: 15px;
        font-size: 14px;
    }

    @media (${DESKTOP}) {
        display: flex;
    }
`;
export const SelectFieldWrap = styled.div.withConfig({ displayName: "SelectFieldWrap", componentId: "sc-14wczrv" }) `
    margin-right: 10px;
`;
export const MobileFilterWrap = styled.div.withConfig({ displayName: "MobileFilterWrap", componentId: "sc-11b0azj" }) `
    display: block;
    color: ${COLORS.primary};
    margin-left: 20px;

    @media (${DESKTOP}) {
        display: none;
    }
`;
export const MobileFilterText = styled.span.withConfig({ displayName: "MobileFilterText", componentId: "sc-1csnotb" }) `
    margin-right: 15px;
    display: none;
    @media (${TABLET}) {
        display: inline;
    }
`;
export const CreateProjectButton = styled(Button).withConfig({ displayName: "CreateProjectButton", componentId: "sc-16puz4w" }) `
    display: none !important;

    @media ${TABLET} {
        display: inherit !important;
    }
`;
export const ActionBarContainer = styled.div.withConfig({ displayName: "ActionBarContainer", componentId: "sc-6z7scv" }) `
    background-color: ${COLORS.primaryContrast};
`;
