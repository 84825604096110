import React, { useCallback, useEffect, useRef, useState, useSyncExternalStore, } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toaster } from 'toasterhea';
import { z } from 'zod';
import { BehindIndexError } from '~/errors/BehindIndexError';
import { getConfigValueFromChain } from '~/getters/getConfigValueFromChain';
import Toast, { ToastType } from '~/shared/toasts/Toast';
import { Layer } from '~/utils/Layer';
import { useCurrentChainId } from '~/utils/chains';
import { errorToast } from '~/utils/toast';
const infoToast = toaster(Toast, Layer.Toast);
export function useInfoToastEffect() {
    useEffect(() => {
        let timeoutId;
        let recentKey;
        function onKeyUp() {
            recentKey = undefined;
            if (timeoutId) {
                clearTimeout(timeoutId);
                timeoutId = undefined;
            }
        }
        function onKeyDown(e) {
            if (e.key === recentKey) {
                return;
            }
            onKeyUp();
            recentKey = e.key;
            if (e.key !== 'i') {
                return;
            }
            timeoutId = window.setTimeout(async () => {
                try {
                    await infoToast.pop({
                        type: ToastType.Info,
                        title: 'Version info',
                        desc: (React.createElement("ul", null,
                            React.createElement("li", null,
                                "Hub v",
                                process.env.HUB_VERSION),
                            React.createElement("li", null,
                                "Commit hash ",
                                process.env.COMMIT_HASH),
                            React.createElement("li", null,
                                "@streamr/sdk v",
                                process.env.STREAMR_SDK_VERSION),
                            React.createElement("li", null,
                                "@streamr/config v",
                                process.env.STREAMR_CONFIG_VERSION))),
                        okLabel: 'Dismiss',
                    });
                }
                catch (_) {
                    // Ignore.
                }
            }, 1500);
        }
        document.addEventListener('keydown', onKeyDown);
        document.addEventListener('keyup', onKeyUp);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
            document.removeEventListener('keyup', onKeyUp);
            onKeyUp();
        };
    }, []);
}
export function useConfigValueFromChain(key, transform) {
    const [value, setValue] = useState();
    const chainId = useCurrentChainId();
    const transformRef = useRef(transform);
    if (transformRef.current !== transform) {
        transformRef.current = transform;
    }
    useEffect(() => {
        let mounted = true;
        void (async () => {
            try {
                const newValue = await getConfigValueFromChain(chainId, key);
                if (!mounted) {
                    return;
                }
                setValue((transformRef.current
                    ? transformRef.current(newValue)
                    : newValue));
            }
            catch (e) {
                console.warn(`Could not load ${key} config from chain`, e);
                errorToast({ title: 'Could not load config from chain' });
            }
        })();
        return () => {
            mounted = false;
        };
    }, [key, chainId]);
    return value;
}
export function useMaxUndelegationQueueDays() {
    return (useConfigValueFromChain('maxQueueSeconds', (value) => Number(value) / 86400) || 0);
}
export function useMediaQuery(query) {
    const subscribe = useCallback((cb) => {
        if (typeof window.matchMedia !== 'function') {
            return () => { };
        }
        const matchMedia = window.matchMedia(query);
        matchMedia.addEventListener('change', cb);
        return () => {
            matchMedia.removeEventListener('change', cb);
        };
    }, [query]);
    return useSyncExternalStore(subscribe, () => typeof window.matchMedia === 'function' && window.matchMedia(query).matches);
}
/**
 * Extracts block number (`?b=…`) from GET params; makes sure it's
 * a number. Defaults to 0.
 */
export function useRequestedBlockNumber() {
    const blockNumber = useSearchParams()[0].get('b');
    try {
        return z.coerce.number().min(0).parse(blockNumber);
    }
    catch (_) {
        return 0;
    }
}
/**
 * Tracks and returns a referenace to the latest `BehindIndexError` exception.
 */
export function useLatestBehindBlockError(query) {
    const { error, isSuccess } = query;
    const behindBlockErrorRef = useRef(null);
    if (error instanceof BehindIndexError) {
        behindBlockErrorRef.current = error;
    }
    if (isSuccess) {
        behindBlockErrorRef.current = null;
    }
    return behindBlockErrorRef.current;
}
/**
 * Keeps a reference of the first encountered `BehindIndexError` within
 * the context of `resetDeps`.
 */
export function useInitialBehindIndexError(query, resetDeps) {
    const { error } = query;
    const errorRef = useRef(null);
    const resetKey = JSON.stringify(resetDeps);
    const resetKeyRef = useRef(resetKey);
    if (!errorRef.current && error instanceof BehindIndexError) {
        errorRef.current = error;
    }
    if (resetKeyRef.current !== resetKey) {
        resetKeyRef.current = resetKey;
        errorRef.current = null;
    }
    return errorRef.current;
}
/**
 * Refreshes given erroring query after 5s if the reason for its failure
 * is a `BehindIndexError`.
 */
export function useRefetchQueryBehindIndexEffect(query) {
    const isBehindError = query.error instanceof BehindIndexError;
    useEffect(function refetchQueryOnBehindBlockError() {
        if (!isBehindError) {
            return;
        }
        const timeoutId = setTimeout(() => {
            query.refetch();
        }, 15000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [query, isBehindError]);
}
